<template>
    <admin-layout>
        <div class="space-y-8 divide-y divide-gray-200">
            <div class="space-y-8 divide-y divide-gray-200 sm:space-y-5">
                <div>
                    <div>
                        <h3 class="text-lg leading-6 font-medium text-gray-900">
                            Video Upload
                        </h3>
                    </div>

                    <div class="mt-6 sm:mt-5 space-y-6 sm:space-y-5">
                        <!-- Image title -->
                        <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                            <label for="title" class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                                Title
                            </label>
                            <div class="mt-1 sm:mt-0 sm:col-span-2">
                                <div class="max-w-lg flex rounded-md shadow-sm">
                                    <input v-model="title" type="text" 
                                        name="title" id="title" autocomplete="title"
                                        class="flex-1 block w-full focus:ring-indigo-500 focus:border-indigo-500 min-w-0 
                                                rounded-none rounded-r-md sm:text-sm border-gray-300" />
                                </div>
                            </div>
                        </div>
  
                        <!-- Description -->
                        <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                            <label for="description" class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                                Description
                            </label>
                            <div class="mt-1 sm:mt-0 sm:col-span-2">
                                <div class="max-w-lg flex rounded-md shadow-sm">
                                    <div class="flex-1 block w-full p-3 bg-white
                                                min-w-0
                                                rounded-none rounded-r-md
                                                sm:text-sm
                                                border-gray-300
                                                focus:ring-indigo-500 focus:border-indigo-500
                                                h-64 overflow-scroll overflow-x-hidden	">
                                        <editor v-model="description" />
                                    </div>
                                </div>
                            </div>
                        </div>
    
                        <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                            <SwitchGroup as="div" class="flex items-center">
                                <Switch v-model="membership"
                                        :class="[membership ? 'bg-indigo-600' : 'bg-gray-200', 'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500']">
                                    <span aria-hidden="true"
                                        :class="[membership ? 'translate-x-5' : 'translate-x-0', 'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200']"/>
                                </Switch>
                                <SwitchLabel as="span" class="ml-3">
                                    <span class="text-sm font-medium text-gray-900">Membership Only</span>
                                    <span class="text-sm text-gray-500"> - Will only be available to members section</span>
                                </SwitchLabel>
                            </SwitchGroup>
                        </div>
            
                        <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                            <SwitchGroup as="div" class="flex items-center">
                                <Switch v-model="preview"
                                        :class="[preview ? 'bg-indigo-600' : 'bg-gray-200', 'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500']">
                                    <span aria-hidden="true"
                                        :class="[preview ? 'translate-x-5' : 'translate-x-0', 'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200']"/>
                                </Switch>
                                <SwitchLabel as="span" class="ml-3">
                                    <span class="text-sm font-medium text-gray-900">Preview available</span>
                                    <span class="text-sm text-gray-500"> - Available to show to non members</span>
                                </SwitchLabel>
                            </SwitchGroup>
                        </div>
        
                        <div v-if="membership === false">
                            <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                                <SwitchGroup as="div" class="flex items-center">
                                    <Switch v-model="store"
                                            :class="[store ? 'bg-indigo-600' : 'bg-gray-200', 'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500']">
                                        <span aria-hidden="true"
                                            :class="[store ? 'translate-x-5' : 'translate-x-0', 'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200']"/>
                                    </Switch>
                                    <SwitchLabel as="span" class="ml-3">
                                        <span class="text-sm font-medium text-gray-900">Store available</span>
                                        <span class="text-sm text-gray-500"> - Available to sell in ecommerce store</span>
                                    </SwitchLabel>
                                </SwitchGroup>
                            </div>
        
                            <!-- Price -->
                            <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5" v-show="store">
                                <label for="price" class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                                    Price
                                </label>
                                <div class="mt-1 sm:mt-0 sm:col-span-2">
                                    <div class="max-w-lg flex rounded-md shadow-sm">
                                        <input v-model="price" type="text"
                                            name="price" id="price" autocomplete="title"
                                            class="flex-1 block w-full focus:ring-indigo-500 focus:border-indigo-500 min-w-0 
                                                    rounded-none rounded-r-md sm:text-sm border-gray-300" />
                                    </div>
                                </div>
                            </div>
                        </div>
  
  
                        <!-- Upload box -->
                        <div
                            class="
                            sm:grid
                            sm:grid-cols-3
                            sm:gap-4
                            sm:items-start
                            sm:border-t
                            sm:border-gray-200
                            sm:pt-5
                            "
                        >
                            <label
                                for="images"
                                class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                            >
                                <div class="mt-2">
                                    <div class="mt-1 text-gray-600" v-if="got_cover == false">Add "cover" image</div>
                                    <div class="mt-1 text-gray-600" v-if="got_video == false">Add "video"</div>

                                    <!-- Files list -->
                                    <ul v-if="upload_files_list != null" role="list" 
                                        class="mt-1 divide-y divide-gray-200">
                                        <li v-for="file in upload_files_list" :key="file.name" class="py-4 flex">
                                            <document-text-icon class="h-10 w-10 text-blue-700"/>
                                            <div class="ml-3">
                                                <p class="text-sm font-medium text-gray-900">{{ file.name }}</p>
                                                <p class="text-sm text-gray-500">{{ (file.size / 1024 / 1024).toFixed(1) }} mb</p>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </label>
                            <div class="mt-1 sm:mt-0 sm:col-span-2">
                                <div
                                    class="
                                    max-w-lg
                                    flex
                                    justify-center
                                    px-6
                                    pt-5
                                    pb-6
                                    border-2 border-gray-300 border-dashed
                                    rounded-md
                                    hover:border-blue-500
                                    "
                                    :class="{'border-blue-500': dropZoneActive}"
                                    @dragover.prevent="dropZoneActive = true"
                                    @dragenter.prevent="dropZoneActive = true"
                                    @dragleave.prevent="dropZoneActive = false"
                                    @drop.prevent="handleFileDrop"
                                >
                                    <div class="space-y-1 text-center">
                                        <svg
                                            class="mx-auto h-12 w-12 text-gray-400"
                                            stroke="currentColor"
                                            fill="none"
                                            viewBox="0 0 48 48"
                                            aria-hidden="true"
                                        >
                                            <path
                                                d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                                                stroke-width="2"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                            />
                                        </svg>
                                        <div class="flex text-sm text-gray-600">
                                            <label
                                                for="file-upload"
                                                class="
                                                relative
                                                cursor-pointer
                                                bg-white
                                                rounded-md
                                                font-medium
                                                text-indigo-600
                                                hover:text-indigo-500
                                                focus-within:outline-none
                                                focus-within:ring-2
                                                focus-within:ring-offset-2
                                                focus-within:ring-indigo-500
                                            "
                                            >
                                                <span @click="chooseFile">Choose a file</span>
                                                <input
                                                    id="files"
                                                    name="files"
                                                    type="file"
                                                    ref="file"
                                                    class="sr-only"
                                                    @change="chooseFileSelected"
                                                />
                                            </label>
                                            <p class="pl-1">or drag and drop</p>
                                        </div>
                                        <p class="text-xs text-gray-500">
                                            PNG, JPG, GIF up to 20MB
                                        </p>
                                        <p class="text-xs text-gray-500">
                                            MP4 up to 3GB
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
  
        <div class="pt-5">
            <div class="flex justify-end">
                <button v-if="is_uploading == false" 
                        type="submit" ref="submit" @click="startUpload()"
                        class="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm
                               font-medium rounded-md text-white bg-indigo-600
                               hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500
                               disabled:opacity-50"
                        :disabled="is_ok_to_send == false  ||  is_uploading == true"
                >
                    Upload
                </button>
                <button v-else
                        class="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm
                               font-medium rounded-md text-white bg-green-600
                               disabled:opacity-50"
                        :disabled=true
                >
                    Uploaded ... {{ progress }}%
                </button>
            </div>
        </div>
        <message-modal  :show_message="show_message"
                        :title="'Upload completed'"
                        :message="'The upload has completed successfully'"
                        :next_route="'adminVideos'"
        ></message-modal>
    </admin-layout>
</template>
<script>
    import axios from "axios";
    import {getStorage} from "@/saveStorage";
    import MessageModal from '@/modals/message.vue'
    import AdminLayout from "@/layouts/adminLayout.vue";
    import {Switch, SwitchGroup, SwitchLabel} from '@headlessui/vue'
    import Editor from "../reuseable/editor"
    import {
        DocumentTextIcon
    } from "@heroicons/vue/solid";
  
    export default {
        components: {
            MessageModal,
            AdminLayout,
            DocumentTextIcon,
            Switch,
            SwitchGroup,
            SwitchLabel,
            Editor
        },
  
        data() {
            return {
                api_url: process.env.VUE_APP_API_URL,
    
                title: '',
                description: 'Paste your description here',
                membership: false,
                preview: false,
                store: false,
                price: '',
    
                dropZoneActive: false,
                cover_file: null,
                video_file: null,
                upload_files_list: [],
    
                chunked_data:     [],
                chunked_meta:     [],

                chunks_ready:     false,
                is_uploading:     false,
                has_uploaded:     false,
                bytes_to_upload:  0,
                bytes_uploaded:   0,

                max_size_gif_allowed:  (20 * 1000 * 1000),
                max_size_allowed:  (3 * 1024 * 1024 * 1024),
                allowed_types:     [
                    'video/mp4',
                ],

                show_message:     false,
            };
        },
  
        watch: {
            chunked_data: {
                deep: true,
                handler(n) {
                    if (this.is_uploading == true) {
                        if (n.length > 0) {
                            //console.log('uploading chunk ... ', n.length);
                            this.sendChunkToServer();
                        }
                        else {
                            this.has_uploaded = true;
                            this.is_uploading = false;

                            this.chunks_ready = false;
                            this.chunked_data = [];
                            this.chunked_meta = [];
                            this.cover_file   = null;
                            this.video_file   = null;
                            this.upload_files_list = [];
                            this.bytes_to_upload = 0;
                            this.bytes_uploaded = 0;

                            this.show_message = true;
                        }
                    }
                }
            }
        },

        computed: {
            got_option() {
                return (this.membership == true  ||  this.store == true  ||  this.preview == true)
            },

            got_cover() {
                return (this.cover_file != null)
            },

            got_video() {
                return (this.video_file != null)
            },

            is_ok_to_send() {
                let is_ok = false;

                if (    this.title != ''  &&  this.description != ''  
                    &&  this.got_option  &&  this.got_video  &&  this.got_cover  &&  this.chunks_ready) {
                    is_ok = true;
                }

                return is_ok
            },

            progress() {
                if (this.bytes_to_upload > 0) {
                    return ((this.bytes_uploaded * 100) / this.bytes_to_upload).toFixed(1);
                }
                else {
                    return 0;
                }
            },
        },
      
        created() {
        },
    
        mounted() {
        },
  
    methods: {
        chooseFile() {
            const fileInput = document.getElementById('files');
            fileInput.click();
        },
    
        chooseFileSelected(e) {
            this.handleFileDrop({ dataTransfer: document.getElementById('files') });
        },
    
        handleFileDrop(event) {
            const dropped_filelist = event.dataTransfer.files;
    
    
            // Go through dropped files and handle pre-upload-checks
            for (let i = 0; i < dropped_filelist.length; i++) {
                let dropped_file = dropped_filelist.item(i);
                let max_size     = 0;
                // Check selected file-types
                if (dropped_file.type.substr(0, 5) == 'video') {
                    this.upload_files_list = this.upload_files_list.filter(e => e !== this.video_file); // Remove current video file
                    this.video_file        = dropped_file;
                    max_size               = this.max_size_allowed;
                }
                else if (dropped_file.type.substr(0, 5) == 'image') {
                    this.upload_files_list = this.upload_files_list.filter(e => e !== this.cover_file); // Remove current cover file
                    this.cover_file        = dropped_file
                    max_size               = this.max_size_gif_allowed;
                }
                else {
                    console.log('File "' + dropped_file.name + '" not added - wrong type: ' + dropped_file.type);
                }

                // Add to list
                if (dropped_file.size < max_size ) {
                    this.upload_files_list.push(dropped_file);
                }
                else {
                    console.log('File "' + dropped_file.name + '" not added - too big: ' + dropped_file.size);
                }

                // Create file chunks
                this.createChunks();        
            }
        },
  
        sendChunkToServer() {
            this.is_uploading = true;

            const token = getStorage('token')
  
            const headers = {
                "Content-Type": "multipart/form-data",
                "Authorization": "Bearer " + token
            }

            let formData = new FormData()
            formData.append("title", this.title)
            formData.append("description", this.description)
            formData.append("is_preview", this.preview)
            formData.append("is_members_only", this.membership)
            formData.append("price", this.price || 0)

            // Add file chunk to upload-form data
            formData.append('is_last', this.chunked_meta[0].is_last);
            formData.append('chunk', this.chunked_data[0], `${this.chunked_meta[0].name}.part`);
            formData.append("type", this.chunked_meta[0].type);

            console.log('Uploading chunk part ' + this.chunked_data.length + ' of  ' + this.chunked_meta[0].name + ' (' + ((this.chunked_meta[0].is_last == true) ? 'last part' : 'part') + ')');

            // Send file chunk to API
            let axios_config = {
                method:  "POST",
                url:     this.api_url + '/api/admin/videos/uploadchunk',
                data:    formData,
                headers: headers
            }
            //console.log('axios = ', axios_config)

            axios(axios_config)
            .then(response => {
                //console.log('upload response: ', JSON.stringify(response));
                this.bytes_uploaded += this.chunked_data[0].size;
                this.chunked_data.shift();
                this.chunked_meta.shift();
                //console.log('progress= '+this.bytes_uploaded+' of '+this.bytes_to_upload);
            })
            //.catch(error => {
            //    console.log('upload error: ', JSON.stringify(error));
            //    if (error.response) {
            //        console.log(error.response.data);
            //        console.log(error.response.status);
            //        console.log(error.response.headers);
            //    }
            //});
        },


        // Create array of file chunks to upload
        createChunks() {
            this.chunks_ready = false;

            let chunk_size       = (20 * 1024 * 1024);     // 5MB chunks
            let num_of_chunks    = 0;

            this.chunked_data = [];
            this.chunked_meta = [];

            // Go through dropped files and handle pre-upload-checks
            for (let i = 0; i < this.upload_files_list.length; i++) {
                let upload_file = this.upload_files_list[i]
                this.bytes_to_upload += upload_file.size;
    
                num_of_chunks = Math.ceil(upload_file.size / chunk_size);
                for (let j = 0; j < num_of_chunks; j++) {
                    this.chunked_data.push(upload_file.slice((j * chunk_size), Math.min((j * chunk_size) + chunk_size, upload_file.size), upload_file.type));
                    this.chunked_meta.push({
                        name:    upload_file.name,
                        type:    upload_file.type,
                        is_last: (j == (num_of_chunks-1))
                    });
                }
            }

            this.chunks_ready = true;
        },


        // Start the upload
        startUpload() {
            // Update button text/state
            this.$refs.submit.textContent = 'Uploading ... 0.0%';
            //this.$refs.submit.disabled    = true;

            // Start upload
            this.sendChunkToServer();
        },
    },
};
</script>
  
<style>
</style>