<template>
  <members-layout>
    <div>
      <div>
        <img class="h-32 w-full object-cover lg:h-48" src="@/assets/profilebackground.jpg" alt=""/>
      </div>
      <div class="max-w-5xl mx-auto px-4 sm:px-6 lg:px-8">
        <div class="-mt-12 sm:-mt-16 sm:flex sm:items-end sm:space-x-5">
          <div class="flex">
            <img class="h-24 w-24 rounded-full ring-4 ring-white sm:h-32 sm:w-32" src="@/assets/avatar.jpg" alt=""/>
          </div>
          <div class="mt-6 sm:flex-1 sm:min-w-0 sm:flex sm:items-center sm:justify-end sm:space-x-6 sm:pb-1">
            <div class="sm:hidden md:block mt-6 min-w-0 flex-1">
              <h1 class="text-2xl font-bold text-gray-900 truncate">
                {{ auth.name }}
              </h1>
              <p class="text-sm text-gray-400">{{ auth.email }}</p>
            </div>
            <div class="mt-6 flex flex-col justify-stretch space-y-3 sm:flex-row sm:space-y-0 sm:space-x-4">
              <span class="relative z-0 inline-flex shadow-sm rounded-md">
                  <button type="button" class="relative inline-flex items-center px-4 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500">Videos</button>
                  <button type="button" class="-ml-px relative inline-flex items-center px-4 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500">Photo Sets</button>
                </span>
              <button type="button"
                      class="inline-flex justify-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pink-500">
                <UserIcon class="-ml-1 mr-2 h-5 w-5 text-gray-400" aria-hidden="true"/>

                <span>User Information</span>
              </button>
            </div>
          </div>
        </div>
        <div class="hidden sm:block md:hidden mt-6 min-w-0 flex-1">
          <h1 class="text-2xl font-bold text-gray-900 truncate">
            {{ auth.name }}
          </h1>
        </div>
      </div>


    </div>
  </members-layout>
</template>
<script>
import MembersLayout from "@/layouts/BrochureLayout";
import {MailIcon, PhoneIcon, SearchIcon, VideoCameraIcon, UserIcon} from '@heroicons/vue/solid'

import {getStorage} from "@/saveStorage";
import axios from "axios";

export default {
  components: {
    MembersLayout,
    MailIcon, PhoneIcon, SearchIcon, VideoCameraIcon, UserIcon
  },
  data() {
    return {
      api_url: process.env.VUE_APP_API_URL,
      auth: {},
      video: {},
      videoLink: '/videos/20'
    }
  },
  created() {
    this.auth = getStorage('auth')
    this.getAllVideo()
  },
  methods: {
    async getAllVideo() {
      const config = {
         headers: { Authorization: `Bearer ` + this.auth.token.replace(/^"(.+(?="$))"$/, '$1') },
         maxContentLength: Infinity,
         maxBodyLength: Infinity
      };

      const bodyParameters = {
         key: "value"
      };
      await axios.get(this.api_url + '/api' + this.videoLink,config).then(response => {
        this.video = response.data.records
      })
    }
  }
}
</script>