<template>
    <admin-form-layout>
        <form class="space-y-8 divide-y divide-gray-200">
            <div class="space-y-8 divide-y divide-gray-200 sm:space-y-5">
                <div>
                    <div>
                        <h3 class="text-lg leading-6 font-medium text-gray-900">
                            Image set upload
                        </h3>
                    </div>
  
                    <div class="mt-6 sm:mt-5 space-y-6 sm:space-y-5">
                        <!-- Image title -->
                        <div
                            class="
                            sm:grid
                            sm:grid-cols-3
                            sm:gap-4
                            sm:items-start
                            sm:border-t
                            sm:border-gray-200
                            sm:pt-5
                            "
                        >
                            <label
                            for="title"
                            class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                            >
                                Title
                            </label>
                            <div class="mt-1 sm:mt-0 sm:col-span-2">
                                <div class="max-w-lg flex rounded-md shadow-sm">
                                    <input
                                        v-model="title"
                                        type="text"
                                        name="title"
                                        id="title"
                                        autocomplete="title"
                                        class="
                                            flex-1
                                            block
                                            w-full
                                            focus:ring-indigo-500 focus:border-indigo-500
                                            min-w-0
                                            rounded-none rounded-r-md
                                            sm:text-sm
                                            border-gray-300
                                        "
                                    />
                                </div>
                            </div>
                        </div>
                        <!-- Image title end -->
  
  
                        <div
                            class="
                            sm:grid
                            sm:grid-cols-3
                            sm:gap-4
                            sm:items-start
                            sm:border-t
                            sm:border-gray-200
                            sm:pt-5
                            "
                        >
                            <SwitchGroup as="div" class="flex items-center">
                                <Switch v-model="membership"
                                        :class="[membership ? 'bg-indigo-600' : 'bg-gray-200', 'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500']">
                                    <span aria-hidden="true"
                                        :class="[membership ? 'translate-x-5' : 'translate-x-0', 'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200']"/>
                                </Switch>
                                <SwitchLabel as="span" class="ml-3">
                                    <span class="text-sm font-medium text-gray-900">Membership Only</span>
                                    <span class="text-sm text-gray-500"> - Will only be available to members section</span>
                                </SwitchLabel>
                            </SwitchGroup>
                        </div>
            
                        <div
                            class="
                            sm:grid
                            sm:grid-cols-3
                            sm:gap-4
                            sm:items-start
                            sm:border-t
                            sm:border-gray-200
                            sm:pt-5
                            "
                        >
                            <SwitchGroup as="div" class="flex items-center">
                                <Switch v-model="preview"
                                        :class="[preview ? 'bg-indigo-600' : 'bg-gray-200', 'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500']">
                                    <span aria-hidden="true"
                                        :class="[preview ? 'translate-x-5' : 'translate-x-0', 'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200']"/>
                                </Switch>
                                <SwitchLabel as="span" class="ml-3">
                                    <span class="text-sm font-medium text-gray-900">Preview available</span>
                                    <span class="text-sm text-gray-500"> - Available to show to non members</span>
                                </SwitchLabel>
                            </SwitchGroup>
                        </div>
  
          <!--
              <div v-if="membership === false">
              <div
                  class="
                  sm:grid
                  sm:grid-cols-3
                  sm:gap-4
                  sm:items-start
                  sm:border-t
                  sm:border-gray-200
                  sm:pt-5
                "
              >
                <SwitchGroup as="div" class="flex items-center">
                  <Switch v-model="store"
                          :class="[store ? 'bg-indigo-600' : 'bg-gray-200', 'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500']">
                    <span aria-hidden="true"
                          :class="[store ? 'translate-x-5' : 'translate-x-0', 'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200']"/>
                  </Switch>
                  <SwitchLabel as="span" class="ml-3">
                    <span class="text-sm font-medium text-gray-900">Store available</span>
                    <span class="text-sm text-gray-500"> - Available to sell in ecommerce store</span>
                  </SwitchLabel>
                </SwitchGroup>
              </div>
              </div>
          -->
  
                        <!-- Images -->
                        <div
                            class="
                            sm:grid
                            sm:grid-cols-3
                            sm:gap-4
                            sm:items-start
                            sm:border-t
                            sm:border-gray-200
                            sm:pt-5
                            "
                        >
                            <label
                                for="images"
                                class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                            >
                                <!-- Files list -->
                                <ul v-if="upload_files_list != null" role="list" class="divide-y divide-gray-200">
                                    <li v-for="file in upload_files_list" :key="file.name" class="py-1 flex text-xs">
                                        <document-text-icon class="h-5 w-5 text-blue-700"
                                        />&nbsp;<span 
                                        class="text-xs text-gray-500">{{ (file.size / 1024 / 1024).toFixed(1) }}&nbsp;mb</span
                                        >&nbsp;<span class="text-xs font-medium text-gray-900">{{ file.name }}</span>
                                    </li>
                                </ul>
                                <!-- End files list -->
                            </label>
                            <div class="mt-1 sm:mt-0 sm:col-span-2">
                                <div
                                    class="
                                    max-w-lg
                                    flex
                                    justify-center
                                    px-6
                                    pt-5
                                    pb-6
                                    border-2 border-gray-300 border-dashed
                                    rounded-md
                                    "
                                    :class="{'border-blue-500': dropZoneActive}"
                                    @dragover.prevent="dropZoneActive = true"
                                    @dragenter.prevent="dropZoneActive = true"
                                    @dragleave.prevent="dropZoneActive = false"
                                    @drop.prevent="handleFileDrop"
                                >
                                    <div class="space-y-1 text-center">
                                        <svg
                                            class="mx-auto h-12 w-12 text-gray-400"
                                            stroke="currentColor"
                                            fill="none"
                                            viewBox="0 0 48 48"
                                            aria-hidden="true"
                                        >
                                            <path
                                            d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                                            stroke-width="2"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                            />
                                        </svg>
                                        <div class="flex text-sm text-gray-600">
                                            <label
                                            for="file-upload"
                                            class="
                                                relative
                                                cursor-pointer
                                                bg-white
                                                rounded-md
                                                font-medium
                                                text-indigo-600
                                                hover:text-indigo-500
                                                focus-within:outline-none
                                                focus-within:ring-2
                                                focus-within:ring-offset-2
                                                focus-within:ring-indigo-500
                                            "
                                            >
                                            <span @click="chooseFile">Choose a file</span>
                                            <input
                                                id="images"
                                                name="images"
                                                type="file"
                                                ref="file"
                                                class="sr-only"
                                                @change="chooseFileSelected"
                                            />
                                            </label>
                                            <p class="pl-1">or drag and drop</p>
                                        </div>
                                        <p class="text-xs text-gray-500">
                                            PNG, JPG, GIF up to 20MB each
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
  
            <div class="pt-5">
                <div class="flex justify-end">
                    <button  v-if="is_uploading == false" 
                        type="submit"
                        @click="submitFile"
                        class="
                            ml-3
                            inline-flex
                            justify-center
                            py-2
                            px-4
                            border border-transparent
                            shadow-sm
                            text-sm
                            font-medium
                            rounded-md
                            text-white
                            bg-indigo-600
                            hover:bg-indigo-700
                            focus:outline-none
                            focus:ring-2
                            focus:ring-offset-2
                            focus:ring-indigo-500
                            disabled:opacity-50
                        "
                        :disabled="is_ok_to_send == false"
                    >
                        Upload
                    </button>
                    <button v-else
                        class="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm
                                font-medium rounded-md text-white bg-green-600
                                disabled:opacity-50"
                        :disabled=true
                    >
                        Uploading ... 
                    </button>
                </div>
            </div>
        </form>
        <message-modal  :show_message="show_message"
                        :title="'Upload completed'"
                        :message="'Your upload of ' + upload_files_list_count + ' images has completed successfully'"
                        :next_route="'adminImages'"
        ></message-modal>
    </admin-form-layout>
</template>
<script>
    import axios from "axios";
    import {getStorage} from "@/saveStorage";
    import MessageModal from '@/modals/message.vue'
    import AdminFormLayout from "@/layouts/adminFormLayout.vue";
    import {Switch, SwitchGroup, SwitchLabel} from '@headlessui/vue'
    import {
        DocumentTextIcon
    } from "@heroicons/vue/solid";
  
  export default {
      components: {
          MessageModal,
          AdminFormLayout,
          DocumentTextIcon,
          Switch,
          SwitchGroup,
          SwitchLabel
      },
  
      data() {
          return {
              api_url: process.env.VUE_APP_API_URL,
  
              title: '',
              description: '',
              membership: false,
              preview: false,
              store: false,
              price: '',
  
              dropZoneActive: false,

              upload_files_list: [],
              upload_files_list_count: 0,
              is_uploading:      false,
              has_uploaded:      false,
              max_size_allowed:  (20 * 1024 * 1024),
              allowed_types:     [
                'image/jpg',
                'image/jpeg',
                'image/png',
                'image/gif',
              ],

              show_message:     false,
          };
      },
  
      computed: {
          got_option() {
              return (this.membership == true  ||  this.preview == true)
          },
  
          got_photos() {
              return (this.upload_files_list != [])
          },
  
          is_ok_to_send() {
              return (this.title != ''  &&  this.got_option  &&  this.got_photos)
          }
      },
      
      created() {
      },
  
      mounted() {
      },
  
    methods: {
        chooseFile() {
            const fileInput = document.getElementById('images');
            fileInput.click();
        },
    
        chooseFileSelected(e) {
            this.handleFileDrop({ dataTransfer: document.getElementById('images') });
        },
    
        handleFileDrop(event) {
            const dropped_filelist = event.dataTransfer.files

            // Go through dropped files and handle pre-upload-checks
            for (let i = 0; i < dropped_filelist.length; i++) {
                let dropped_file = dropped_filelist.item(i)

                // Remove if already on the list
                this.upload_files_list = this.upload_files_list.filter(e => {
                    return e.name !== dropped_file.name;
                })

                // Add to list
                if (dropped_file.size < this.max_size_allowed ) {
                    if ( this.allowed_types.includes(dropped_file.type.toLowerCase()) ) {
                        this.upload_files_list.push(dropped_file);
                    }
                    else {
                        console.log('File "' + dropped_file.name + '" not added - wrong type: ' + dropped_file.type);
                    }
                }
                else {
                    console.log('File "' + dropped_file.name + '" not added - too big: ' + dropped_file.size);
                }
            }
        },
      
        submitFile() {
            this.is_uploading = true;

            const token = getStorage('token')
            const headers = {
                "Content-Type": "multipart/form-data",
                "Authorization": "Bearer " + token,
            }


            let formData = new FormData()

            formData.append("title", this.title)
            formData.append("description", this.description)
            formData.append("is_preview", this.preview)
            formData.append("is_members_only", this.membership)
            this.upload_files_list.forEach( file => {
                formData.append("photos[]", file);
            });
    
            /*
                            'title'           => 'required|string|min:4|max:500',
                            'description'     => 'required|string|min:4|max:8000',
                            'cover'           => 'required|mimes:jpg,gif,png|max:20000',
                            'video'           => 'required|mimes:mp4,avi,mov,mkv,m4v,wmv|max:100000',
                            'is_preview'      => 'required|in:0,1',
                            'is_members_only' => 'required|in:0,1',
                            'price'           => 'sometimes|numeric|between:0.00,99.99',
            */
            //console.log('form data:')
            //for (var pair of formData.entries()) {
            //    console.log(pair[0]+ ', ' + pair[1]); 
            //}
    
            axios.post(this.api_url + '/api/admin/photosets/upload', formData, {headers: headers}).then((res) => {
                //console.log('upload result = ', res)
                res.data.files;
                res.status;

                this.has_uploaded            = true;
                this.is_uploading            = false;
                this.upload_files_list_count = this.upload_files_list.length;
                this.upload_files_list       = [];

                this.show_message = true;
            });
        },
    },
  };
  </script>
  
  <style>
  </style>