<template>
    <BrochureLayout>
      <div class="bg-zinc-900">
        <page_header>
          <template v-slot:title>
            Terms and Conditions
          </template>
          <template v-slot:tag>

          </template>
        </page_header>
        <div class="max-w-7xl mx-auto overflow-hidden sm:px-6 lg:px-8 mb-3 p-10">
          <p class=" p-2 text-pink-700 text-center">borderlandbound.com<br>
            Terms-of-Service Agreement</p>
          <p class="p-2 text-white">Borderland Digital, a UK limited company (the “Company”), welcomes you to borderlandbound.com (the “Website”).
            It is important to the Company that you and other visitors have the best possible experience while using the
            Website, and that, when you use the Website, you understand your legal rights and obligations.
            Please read this terms-of-service agreement, which is a legal agreement between you and the Company
            that governs your access to and use of the Website, including any content, functionality, and services
            offered on or through the Website. Your access to the Website is on the condition that you agree to this
            agreement. Please pay special attention to the following: (1) disclaimer of warranties (section 14); (2)
            limit on liability and exclusion of damages (sections 15 and 16); (3) place for resolving disputes
            (section 19.2); (4) mandatory arbitration (section 20.1); (5) class action waiver (section 20.5); and
            (6) limitation on time to file disputes (section 20.6).</p>
          <p class="p-2 text-white">
            Section 230(d) Notice:In accordance with <a href="https://www.law.cornell.edu/uscode/text/47/230" target="_blank" class="text-pink-700 hover:text-pink-900"> 47 U.S.C. § 230(d)</a>, you are notified that parental control protections
            (including computer hardware, software, or filtering services) are commercially available that may help in
            limiting access to material that is harmful to minors. You may find information about providers of these
            protections on the Internet by searching “parental control protection” or similar terms.
          </p>
          <p class="p-2 text-white">
            Age Restriction: Only adults (1) who are at least 18-years old and (2) who have reached the age of majority
            where they live may access the Website. The Company forbids all persons who do not meet these age requirements
            from accessing the Website. If minors have access to your computer, please restrain their access to sexually
            explicit material by using any of the following products, which the Company provides for informational purposes
            only and does not endorse: <a href="https://www.27labs.com/" target="_blank" class="text-pink-700 hover:text-pink-900">CYBERsitter™</a> |
            <a href="https://www.netnanny.com/" target="_blank" class="text-pink-700 hover:text-pink-900">Net Nanny®</a> |
            <a href="https://www.cyberpatrol.com/" target="_blank" class="text-pink-700 hover:text-pink-900">CyberPatrol</a> |
            <a href="https://www.asacp.org/" target="_blank" class="text-pink-700 hover:text-pink-900">ASACP</a>.
          </p>
          <p class="p-2 text-pink-700 font-bold">1. Introduction</p>
          <ul>
            <li class="p-2 text-white">The Website allows you to purchase access to digital content, including videos for streaming or download or both. To view the videos, you will need a personal computer, tablet, phone, or other device that meets the Website’s system and compatibility requirements. When streaming, the resolution and quality of the video you receive will depend on a number of factors, including your Internet bandwidth, which may fluctuate while a video is being streamed.</li>
            <li class="p-2 text-white">This agreement applies to all users of the Website, whether you are a “visitor” or a “registered user.” By clicking on the “I Agree” button on the warning page, checking the appropriate box during sign up, purchasing a premium membership or content, or accessing any part of the Website, you agree to this agreement. If you do not want to agree to this agreement, you must leave the Website. If you breach any part of this agreement, the Company may revoke your license to access the Website, block your access, and terminate your account (if you have one).</li>
            <li class="p-2 text-white">The Company may change this agreement on one or more occasions by updating this page. The top of this page will tell you when the Company last updated this agreement. Changes will take effect on the “last updated” date stated on the top of this page. Changes will not operate retroactively. The Company will try to notify you when it changes this agreement if it can do so in a reasonable manner. But you should frequently check this page to make sure that you are operating under the most current version of this agreement. The Company will consider your continued use of the Website after it posts the changes as your acceptance of the changes even if you do not read them. If you do not agree to the changes, your sole remedy is to stop accessing the Website.</li>
            <li class="p-2 text-white">If you have any questions about this agreement or any questions or comments about the Website, please email the Company at <a class="text-pink-700 hover:text-pink-900">enquires@borderlandbound.com</a>.</li>
          </ul>
          <p class="p-2 text-white"> <span class="text-pink-700 font-bold">2. Adult-Oriented Content and Affirmative Representations</span>. The Website contains uncensored sexually explicit material unsuitable for minors. Only adults (1) who are at least 18-years old and (2) who have reached the age of majority where they live may access the Website. If you do not meet these age requirements, you must not access the Website and must leave now. By accessing the Website, you state that the following facts are accurate:</p>
          <ul>
            <li class="p-2 text-white">You (1) are at least 18-years old, (2) have reached the age of majority where you live, and (3) have the legal capacity to enter into this agreement;</li>
            <li class="p-2 text-white">All information you provided to the Company is accurate, and you will promptly update this information when necessary to make sure that it remains accurate;</li>
            <li class="p-2 text-white">You own (or have permission to use) the credit card you pay with and authorize the Company (or its authorized payment processing agent) to charge the credit card according to your purchase;</li>
            <li class="p-2 text-white">You are aware of the adult nature of the content available on the Website, and you are not offended by visual images, verbal descriptions, and audio sounds of a sexually oriented nature, which may include graphic visual depictions and descriptions of nudity and sexual activity;</li>
            <li class="p-2 text-white">You are familiar with your community’s laws affecting your right to access adult-oriented materials, including sexually explicit material depicting bondage, S/M, and other fetish activities;</li>
            <li class="p-2 text-white">You have the legal right to access adult-oriented materials, including sexually explicit material depicting bondage, S/M, and other fetish activities, and the Company has legal right to transmit them to you;</li>
            <li class="p-2 text-white">You are voluntarily requesting adult-oriented materials for your own private enjoyment;</li>
            <li class="p-2 text-white">You are not accessing the Website from a place, country, or location in which doing so would, or could be considered a violation of any law;</li>
            <li class="p-2 text-white">You will not share these materials with a minor or otherwise make them available to a minor; and</li>
            <li class="p-2 text-white">By accessing the Website, you will have released and discharged the providers, owners, and creators of the Website from all liability that might arise.</li>
          </ul>
          <p class="p-2 text-white"><span class="text-pink-700 font-bold">3. Accessing the Website.</span> The Company may withdraw or amend this Website, and any service or material it provides on the Website, in its sole discretion without notice. The Company will not be liable if for any reason all or any part of the Website is unavailable at any time or for any period. From time to time, the Company may restrict access to some parts of the Website, or the entire Website, to users, including registered users. You are responsible for making all arrangements necessary for you to have access to the Website.</p>
          <p class="p-2 text-white">4.1 <strong class="text-pink-700 font-bold">Account Creation.</strong> You must complete the registration process by providing the Company with accurate information as prompted by the registration form. You must also choose a password and a username.</p>
          <p class="p-2 text-white">4.2 <strong class="text-pink-700 font-bold">Responsibility for Account. </strong>You are responsible for maintaining the confidentiality of your password and account. Further, you are responsible for all activities that occur under your account. You will promptly notify the Company of any unauthorized use of your account or any other breach of security.</p>
          <p class="p-2 text-white">4.3 <strong class="text-pink-700 font-bold">Liability for Account Misuse. </strong>The Company will not be liable for any loss that you may incur as a result of someone else using your password or account, either with or without your knowledge. You could be held liable for losses incurred by the Company or another party due to someone else using your account or password.</p>
          <p class="p-2 text-white">4.4 <strong class="text-pink-700 font-bold">Use of Other Accounts.</strong> You must not use anyone else’s account at any time.</p>
          <p class="p-2 text-white">4.5 <strong class="text-pink-700 font-bold">Account Security. </strong>The Company cares about the integrity and security of your personal information. But the Company cannot guarantee that unauthorized third parties will never be able to defeat the Website’s security measures or use any personal information you provide to the Company for improper purposes. You acknowledge that you provide your personal information at your own risk.</p>
          <p class="p-2 text-white">4.6 <strong class="text-pink-700 font-bold">Communication Preferences. </strong>By registering for an account, you consent to receiving electronic communications from the Company relating to your account. These communications may involve sending emails to your email address provided during registration or posting communications on the Website and will include notices about your account (e.g., payment authorizations, change in password or payment method, confirmation emails, and other transactional information) and are part of your relationship with the Company. You acknowledge that any notices, agreements, disclosures, or other communications that the Company sends to you electronically will satisfy any legal communication requirements, including that these communications be in writing. The Company recommends that you keep copies of electronic communications by printing a paper copy or saving an electronic copy. You also consent to receiving certain other communications from the Company, including newsletters about new features and content, special offers, promotional announcements, and customer surveys via email or other methods. You acknowledge that communications you receive from the Company may contain sexually explicit material unsuitable for minors. If you no longer want to receive certain non-transactional communications, please review the <a href="https://www.borderlandbound.com/privacy">Privacy Policy</a> regarding opting out of marketing communications.</p>
          <p class="p-2 text-white">5. <strong class="text-pink-700 font-bold">Intellectual-Property Rights</strong></p>
          <p class="p-2 text-white">5.1 <strong class="text-pink-700 font-bold">Ownership.</strong>The Website and its entire contents, features, and functionality (including all information, software, text, displays, images, video, and audio, and the design, selection, and arrangement of it) are owned by the Company, the Company’s licensors, or other providers of that material and are protected by copyright, trademark, patent, trade secret, and other intellectual property or proprietary rights laws.</p>
          <p class="p-2 text-white">5.2 <strong class="text-pink-700 font-bold">License Grant. </strong>This agreement grants you a nonexclusive, nonsublicensable, nontransferable, limited license to use the Website and any purchased content for your personal, noncommercial use only. You must not reproduce, distribute, modify, create derivative works of, publicly display, publicly perform, republish, download, store, or transmit any of the material on the Website, except as follows:</p>
          <p class="p-2 text-white">(a) Your computer may temporarily store copies of those materials in RAM incidental to your accessing and viewing those materials.</p>
          <p class="p-2 text-white">(b) You may store files that are automatically cached by your Web browser for display enhancement purposes.</p>
          <p class="p-2 text-white">(c) You may print or download one copy of a reasonable number of pages of the Website for your own personal, noncommercial use and not for further reproduction, publication, or distribution.</p>
          <p class="p-2 text-white">(d) You may download or stream any audiovisual content to which you have properly gained access solely for your personal, noncommercial use and not for further reproduction, publication, or distribution.</p>
          <p class="p-2 text-white">(e) If the Company provides desktop, mobile, or other applications for download, you may download a single copy to your computer or mobile device solely for your own personal, noncommercial use, on condition that you agree to be bound by the Company’s end user license agreement for those applications.</p>
          <p class="p-2 text-white">(f) If the Company provides social media features with certain content, you may take those actions as are enabled by those features.</p>
          <p class="p-2 text-white">5.3 <strong class="text-pink-700 font-bold">License Restrictions</strong></p>
          <p class="p-2 text-white">(a) You must not:</p>
          <p class="p-2 text-white">(i) Download any purchased content unless authorized by the Company in writing.</p>
          <p class="p-2 text-white">(ii) Modify copies of any materials from this Website.</p>
          <p class="p-2 text-white">(iii) Use any illustrations, photographs, video or audio sequences, or any graphics separately from the accompanying text.</p>
          <p class="p-2 text-white">(iv) Delete or alter any copyright, trademark, or other proprietary rights notices from copies of materials from this Website.</p>
          <p class="p-2 text-white">(b) You must not access or use for any commercial purposes any part of the Website or any services or materials available through the Website.</p>
          <p class="p-2 text-white">(c) If you print, copy, modify, download, stream, or otherwise use or provide any other person with access to any part of the Website in breach of this agreement, your right to use the Website will stop immediately and you must, at the Company’s option, return or destroy any copies of the materials you have made. No interest in or to the Website or any content on the Website is transferred to you, and the Company reserves all rights not expressly granted. Any use of the Website not expressly permitted by this agreement is a breach of this agreement and may violate copyright, trademark, and other laws.</p>
          <p class="p-2 text-white">5.4 <strong class="text-pink-700 font-bold">Trademarks.</strong>The Company’s name; the term FETISH PROS; the Company logo; the Website logo; and all related names, logos, product and service names, designs, and slogans are trademarks of the Company or its affiliates or licensors. You must not use those marks without the Company’s prior written permission. All other names, logos, product and service names, designs, and slogans on this Website are the trademarks of their respective owners.</p>
          <p class="p-2 text-white">6. <strong class="text-pink-700 font-bold">Prohibited Uses</strong></p>
          <p class="p-2 text-white">6.1 You must use the Website only for lawful purposes and in accordance with this agreement. You must use the Website:</p>
          <p class="p-2 text-white">(a) In any way that violates any applicable federal, state, local, or international law or regulation (including any laws regarding the export of data or software to and from the US or other countries).</p>
          <p class="p-2 text-white">(b) For the purpose of exploiting, harming or attempting to exploit or harm minors in any way by exposing them to inappropriate content, asking for personally identifiable information or otherwise.</p>
          <p class="p-2 text-white">(c) To transmit, or procure the sending of, any advertising or promotional material without the Company’s prior written consent, including any “junk mail”, “chain letter,” or “spam” or any other similar solicitation.</p>
          <p class="p-2 text-white">(d) To impersonate or attempt to impersonate the Company, a Company employee, another user, or any other person or entity (including by using email addresses or screen names associated with any of the foregoing).</p>
          <p class="p-2 text-white">(e) To engage in any other conduct that restricts or inhibits anyone’s use or enjoyment of the Website, or which, as determined by the Company, may harm the Company or users of the Website or expose them to liability.</p>
          <p class="p-2 text-white">6.2 Additionally, you must not:</p>
          <p class="p-2 text-white">(a) Use the Website in any manner that could disable, overburden, damage, or impair the site or interfere with any other party’s use of the Website, including their ability to engage in real time activities through the Website.</p>
          <p class="p-2 text-white">(b) Use any robot, spider, or other automatic device, process, or means to access the Website for any purpose, including monitoring or copying any of the material on the Website.</p>
          <p class="p-2 text-white">(c) Use any manual process to monitor or copy any of the material on the Website or for any other unauthorized purpose without the Company’s prior written consent.</p>
          <p class="p-2 text-white">(d) Use any device, software or routine that interferes with the proper working of the Website.</p>
          <p class="p-2 text-white">(e) Introduce any viruses, trojan horses, worms, logic bombs, or other material which is malicious or technologically harmful.</p>
          <p class="p-2 text-white">(f) Attempt to gain unauthorized access to, interfere with, damage, or disrupt any parts of the Website, the server on which the Website is stored, or any server, computer, or database connected to the Website.</p>
          <p class="p-2 text-white">(g) Attack the Website via a denial-of-service attack or a distributed denial-of-service attack.</p>
          <p class="p-2 text-white">(h) Otherwise attempt to interfere with the proper working of the Website.</p>
          <p class="p-2 text-white">7. <strong class="text-pink-700 font-bold">Termination</strong></p>
          <p class="p-2 text-white">7.1 <strong class="text-pink-700 font-bold">Termination on Notice. </strong>Either party may terminate this agreement at any time by notifying the other party.</p>
          <p class="p-2 text-white">7.2 <strong class="text-pink-700 font-bold">Termination by the Company. </strong>The Company may suspend, disable, or terminate your access to the Website (or any part of it) if it determines that you have breached this agreement or that your conduct would tend to damage the Company’s reputation and goodwill. If the Company terminates your access for any of these reasons, you must not access the Website. The Company may block your email address and IP address to prevent further access.</p>
          <p class="p-2 text-white">7.3 <strong class="text-pink-700 font-bold">Effect of Termination. </strong>On termination, your right to access the Website and all licenses granted by the Company terminates. Termination of your access to the Website will not relieve you of any obligations arising or accruing before termination or limit any liability that you otherwise may have to the Company or any third party. You are solely responsible for making sure that any recurring billing is cancelled. To cancel recurring billing, please contact the payment processor you signed up through.</p>
          <p class="p-2 text-white">7.4 <strong class="text-pink-700 font-bold">Survival.</strong>This agreement’s provisions that by their nature should survive termination will survive termination, including ownership provisions, warranty disclaimers, and limitations of liability.</p>
          <p class="p-2 text-white">8. <strong class="text-pink-700 font-bold">Changes to the Website. </strong>The Company may update the Website’s content from time to time, but its content is not necessarily complete or up-to-date. Any of the Website’s material may be out of date at any given time, and the Company is not required to update that material.</p>
          <p class="p-2 text-white">9. <strong class="text-pink-700 font-bold">Information About You and Your Visits to the Website. </strong>For information about how the Company collects, uses, and shares your personal data, please review the <a href="https://www.borderlandbound.com/privacy">Privacy Policy</a>.</p>
          <p class="p-2 text-white">10. <strong class="text-pink-700 font-bold">Premium Membership, Trial Memberships, Billing, and Cancellation</strong></p>
          <p class="p-2 text-white">10.1 <strong class="text-pink-700 font-bold">Premium Membership</strong></p>
          <p class="p-2 text-white">(a) <strong class="text-pink-700 font-bold">Ongoing Membership. </strong>Your premium membership, which may start with a trial membership, will continue month-to-month (or any longer term selected) and automatically renew unless you cancel your membership (or your membership is nonrecurring) or the Company terminates it. You must have Internet access and provide the Company with a current, valid, accepted method of payment. The Company or its authorized payment processor will bill the membership fee to your chosen payment method. You must cancel your premium membership before it renews each term to avoid billing of the next term’s premium membership fees to your chosen payment method.</p>
          <p class="p-2 text-white">(b) <strong class="text-pink-700 font-bold">Differing Memberships.</strong> The Company may offer several membership plans, including special promotional plans or memberships with differing conditions and limitations. Any material different terms from those described in this agreement will be disclosed at your signup or in other communications made available to you.</p>
          <p class="p-2 text-white">10.2 <strong class="text-pink-700 font-bold">Trial Memberships (If Available). </strong>Your premium membership may start with a trial. The trial period of your premium membership lasts for the period specified during signup. The Company or its payment processor will begin billing your payment method for monthly membership fees at the end of the trial period of your premium membership, and your premium membership will automatically renew monthly unless you cancel before the end of the trial period. The Company or its payment processor may authorize your payment method through various methods, including authorizing it up to approximately one month of service as soon as you register. In some cases, your available balance or credit limit may be reduced to reflect the authorization during your trial period. The Company or its payment processor will continue to bill your payment method monthly for your premium membership fee until you cancel.</p>
          <p class="p-2 text-white">10.3 <strong class="text-pink-700 font-bold">Billing</strong></p>
          <p class="p-2 text-white">(a) <strong class="text-pink-700 font-bold">Recurring Billing (does not apply if you selected one-time charge). </strong>By starting your premium membership and providing or designating a payment method, you hereby authorize the Company or its payment processor to charge you a premium membership fee at the rate in effect when you originally signed up, and any other charges you may incur in connection with your use of the Website, such as taxes or possible transaction fees. Your premium membership will continue for the length of the initial term you select, and, at the end of your prepaid initial term, it will automatically renew for additional prepaid periods of the same length. You must cancel your premium membership before it renews to avoid billing of the next term’s premium membership fees to your payment method. Your account will automatically be charged at the rates in effect at the time you originally signed up.</p>
          <p class="p-2 text-white">(b) <strong class="text-pink-700 font-bold">Price Changes. </strong>The Company may adjust pricing for its service or any components of it in any way and at any time as it may determine in its sole discretion. Any price changes will not affect your current membership (including any renewals) unless the Company gives you 30-days advance email notice to you.</p>
          <p class="p-2 text-white">(c) <strong class="text-pink-700 font-bold">Billing Cycle</strong> <strong class="text-pink-700 font-bold">(does not apply if you selected one-time charge). </strong>The membership fee will be billed at the beginning of the initial term of your premium membership and every 30 days afterwards until you cancel your premium membership (unless you selected one-time charge when you purchased your premium membership). The Company or its payment processor automatically bills your payment method every 30 days. Membership fees are fully earned on payment.</p>
          <p class="p-2 text-white">(d) <strong class="text-pink-700 font-bold">Billing Disputes. </strong>If you believe that the Company has charged you in error, you must notify the Company in writing no later than 30 days after you receive the billing statement in which the error first appeared. If you fail to notify the Company in writing of a dispute within this 30-day deadline, you waive any disputed charges. You must submit any billing disputes in writing to <a href="mailto:enquires@borderlandbound.com">enquires@borderlandbound.com</a> and include a detailed statement describing the nature and amount of the disputed charges. The Company will correct any mistakes in a bill and add or credit them against your future payments.</p>
          <p class="p-2 text-white">(e) <strong class="text-pink-700 font-bold">Chargebacks. </strong>You are liable to the Company for any credit card chargebacks or related fees that the Company incurs on your account. If you fail to pay the Company for any credit card chargeback or related fees no later than 30 days after its initial demand for payment, you will pay the Company $100 in additional liquidated damages, plus any costs the Company incurs for each chargeback or related fee.</p>
          <p class="p-2 text-white">(f) <strong class="text-pink-700 font-bold">No Refunds. </strong>The Company considers all purchases final when made, except that the Company may approve a refund in the form of a credit on request if exceptional circumstances exist. If you believe exceptional circumstances exist, please email the Company at <a href="mailto:enquiries@borderlandbound.com">enquiries@borderlandbound.com</a> and explain the exceptional circumstances that you believe merits a refund. The Company is not making any promise that it will give you a refund. If the Company gives you a refund, the Company will issue the refund in the form of a credit to the payment method you used for your purchase; the Company will not make refunds in the form of cash, check, or free services. The provision of a refund in one instance does not entitle you to a refund in the future for similar instances; nor does it obligate the Company to provide refunds in the future, under any circumstance.</p>
          <p class="p-2 text-white">(g) <strong class="text-pink-700 font-bold">Cancellation. </strong>You may cancel your premium membership at any time, and you will continue to have access to the Website through the end of your membership term. The Company does not provide refunds or credits for any partial-month membership periods. To cancel your premium membership, you may visit <a rel="nofollow" href="https://support.ccbill.com//">support.ccbill.com/</a> or call +1-888-596-9279.</p>
          <p class="p-2 text-white">11.<strong class="text-pink-700 font-bold">Buying Clips Individually (Pay-Per-Clip)</strong></p>
          <p class="p-2 text-white">11.1 <strong class="text-pink-700 font-bold">Purchases.</strong> The Website may offer you the ability to buy clips on a pay-per-clip basis. If it does, you may buy clips without registering for an account. It is your responsibility to check the price before buying a clip. Pricing excludes any taxes or currency transmission charges, which are extra costs charged to you.</p>
          <p class="p-2 text-white">11.2 <strong class="text-pink-700 font-bold">Payment.</strong>The Company accepts payment via the payment methods identified on the Website before checkout. Unless the Website indicates otherwise, you must have a valid accepted form of payment on file to make a purchase. You must abide by any relevant terms of service or other legal agreement, whether with the Company or a third party, that governs your use of a given payment method. You must pay in advance for all content purchased. The Company or its payment processor will charge your credit card or other form of payment for the price listed for the applicable clip, along with any additional amounts relating to applicable taxes, bank fees, and currency fluctuations.</p>
          <p class="p-2 text-white">11.3 <strong class="text-pink-700 font-bold">No Recurring Billing. </strong>All pay-per-clip purchases are one-time charges, meaning the Company will not charge you again unless you purchase another clip.</p>
          <p class="p-2 text-white">11.4 <strong class="text-pink-700 font-bold">Price Changes. </strong><span style="mso-ligatures:none">The Company may adjust pricing for clips at any time as the Company may determine in its sole discretion. The Company does not provide price protection or refunds in the event of a price reduction or promotional offering.</span></p>
          <p class="p-2 text-white">11.5 <strong class="text-pink-700 font-bold">Billing Errors.</strong> If you believe that the Company has charged you in error, you must notify the Company in writing no later than 30 days after you receive the billing statement in which the error first appeared. If you fail to notify the Company in writing of a dispute within this 30-day period, you waive any disputed charges. You must submit any billing disputes to the Company by email at <a href="mailto:enquires@borderlandbound.com">enquires@borderlandbound.com</a> and include a detailed statement describing the nature and amount of the disputed charges. The Company will correct any mistakes in a bill and credit them against your future purchases.</p>
          <p class="p-2 text-white">11.6 <strong class="text-pink-700 font-bold">No Refunds. </strong>All sales and transactions are final. Once you pay for a clip, it is non-refundable. But the Company may approve a refund in the form of a credit on request if exceptional circumstances exist. If you believe exceptional circumstances exist, please email the Company at <a href="mailto:enquiries@borderlandbound.com">enquiries@borderlandbound.com</a> and explain the exceptional circumstances that you believe merits a refund. The amount and form of a refund, and the decision to provide it, is at the Company’s sole discretion. The provision of a refund in one instance does not entitle you to a refund in the future for similar instances; nor does it obligate the Company to provide refunds in the future, under any circumstance.</p>
          <p class="p-2 text-white">12. <strong class="text-pink-700 font-bold">Links from the Website.</strong>  If the Website contains links to other sites and resources provided by third parties, these links are provided for your convenience only. This includes links contained in advertisements, including banner advertisements and sponsored links. The Company has no control over the contents of those sites or resources and accept no responsibility for them or for any loss or damage that may arise from your use of them. If you decide to access any of the third-party websites linked to this Website, you do so entirely at your own risk and subject to the terms of use for those websites.</p>
          <p class="p-2 text-white">14. <strong class="text-pink-700 font-bold">Warranty Disclaimers</strong></p>
          <p class="p-2 text-white">14.1 You understand that the Company cannot and does not guarantee or warrant that files available for downloading from the Internet or the Website will be free of viruses or other destructive code. You are responsible for implementing sufficient procedures and checkpoints to satisfy your particular requirements for anti-virus protection and accuracy of data input and output, and for maintaining a means external to the Website for any reconstruction of any lost data. The Company will not be liable for any loss or damage caused by a distributed denial-of-service attack, viruses, or other technologically harmful material that may infect your computer equipment, computer programs, data, or other proprietary material due to your use of the Website or any services or items obtained through the Website or to your downloading of any material posted on it, or on any website linked to it.</p>
          <p class="p-2 text-white">14.2 Your use of the Website, its content, and any services or items obtained through the Website is at your own risk. The Company provides the Website, its content, and any services or items obtained through the Website “as is,” “with all faults,” and “as available,” without making any warranty, either express or implied. The Company is not making any warranty (1) that the Website, its content, or any services or items obtained through the Website will be accurate, reliable, error-free, or uninterrupted; (2) that defects will be corrected; (3) that the Website or the server that makes it available are free of viruses or other harmful components; or (4) that the Website or any services or items obtained through the Website will otherwise meet your needs or expectations.</p>
          <p class="p-2 text-white">14.3 The Company is not making any warranty, whether express, implied, statutory, or otherwise, including warranty of merchantability, title, noninfringement, privacy, security, and fitness for a particular purpose. No advice or information, whether oral or written, obtained from the Company, the Website, or elsewhere will create any warranty not expressly stated in this agreement.<strong class="text-pink-700 font-bold"></strong></p>
          <p class="p-2 text-white">15.<strong class="text-pink-700 font-bold">Limit on Liability; Release</strong></p>
          <p class="p-2 text-white">15.1 The Company, its directors, officers, employees, agents, subsidiaries, affiliates, licensors, content providers, and service providers will not be liable to you for any of the following:</p>
          <p class="p-2 text-white">(a) Errors, mistakes, or inaccuracies of content;</p>
          <p class="p-2 text-white">(b) Personal injury or property damage resulting from your access to and use of the Website or its content;</p>
          <p class="p-2 text-white">(c) Content or conduct that is infringing, inaccurate, obscene, indecent, offensive, threatening, harassing, defamatory, libelous, abusive, invasive of privacy, or illegal;</p>
          <p class="p-2 text-white">(d) Unauthorized access to or use of the Company’s servers and any personal or financial information stored in them, including unauthorized access or changes to your account, transmissions, or data;</p>
          <p class="p-2 text-white">(e) Interruption or cessation of transmission to or from the Website;</p>
          <p class="p-2 text-white">(f) Bugs, viruses, Trojan horses, malware, ransomware, or other disabling code that may be transmitted to or through the Website by any person or that might infect your computer or affect your access to or use of the Website, your other services, hardware, or software; </p>
          <p class="p-2 text-white">(g) Incompatibility between the Website and your other services, hardware, or software;</p>
          <p class="p-2 text-white">(h) Delays or failures you might experience in starting, conducting, or completing any transmissions to or transactions with the Website; or</p>
          <p class="p-2 text-white">(i) Loss or damage incurred because of the use of any content posted, emailed, sent, or otherwise made available through the Website.</p>
          <p class="p-2 text-white">15.2 You hereby release the Company, its directors, officers, employees, agents, subsidiaries, affiliates, licensors, content providers, and service providers from all liability arising out of the conduct of other users or third parties, including disputes between you and one or more other users or third parties.</p>
          <p class="p-2 text-white">16.<strong class="text-pink-700 font-bold">Exclusion of Damages; Exclusive Remedy</strong><strong class="text-pink-700 font-bold"></strong></p>
          <p class="p-2 text-white">16.1 Unless caused by gross negligence or intentional misconduct, the Company, its directors, officers, employees, agents, subsidiaries, affiliates, licensors, content providers, and service providers will not be liable to you for any direct, indirect, special (including so-called consequential damages), statutory, punitive, or exemplary damages arising out of or relating to your access or your inability to access the Website or the content. This exclusion applies regardless of theory of liability and even if you told the Company about the possibility of these damages or the Company knew or should have known about the possibility of these damages.</p>
          <p class="p-2 text-white">16.2 The Company, its directors, officers, employees, agents, subsidiaries, affiliates, licensors, content providers, and service providers will not be liable to you for any damages for (1) personal injury, (2) pain and suffering, (3) emotional distress, (4) loss of revenue, (5) loss of profits, (6) loss of business or anticipated savings, (7) loss of use, (8) loss of goodwill, (9) loss of data, (10) loss of privacy, or (11) computer failure related to your access of or your inability to access the Website or the content. This exclusion applies regardless of theory of liability and even if you told the Company about the possibility of these damages or the Company knew or should have known about the possibility of these damages.</p>
          <p class="p-2 text-white">16.3 If you are dissatisfied with the Website or have any other complaint, your exclusive remedy is to stop using the Website. The maximum liability of the Company and its directors, officers, employees, agents, subsidiaries, affiliates, licensors, content providers, and service providers to you for any claim will not exceed the greater of $100 and the amount you have paid to the Company for the applicable purchase out of which liability arose even if the remedy fails of its essential purpose.</p>
          <p class="p-2 text-white">17. <strong class="text-pink-700 font-bold">Scope of Disclaimers, Exclusions, and Limits. </strong>The disclaimers, exclusions, and limits stated in sections 14, 15, 16 apply to the greatest extent allowed by law, but no more. The Company does not intend to deprive you of any mandatory protections provided to you by law. Because some jurisdictions may prohibit the disclaimer of some warranties, the exclusion of some damages, or other matters, one or more of the disclaimers, exclusions, or limits will not apply to you.</p>
          <p class="p-2 text-white">18. <strong class="text-pink-700 font-bold">Indemnification</strong><strong class="text-pink-700 font-bold"></strong></p>
          <p class="p-2 text-white">18.1 <strong class="text-pink-700 font-bold">In General. </strong>You will pay the Company, its directors, officers, employees, agents, contractors, subsidiaries, affiliates, licensors, content providers, and service providers (the “<strong class="text-pink-700 font-bold">Indemnified Parties</strong>”) for any loss of an Indemnified Party that is caused by any of the following: (a) your access of or conduct on the Website; (b) your breach of this agreement; (c) your violation of rights of any person, including intellectual property, publicity, and privacy rights; (d) your violation of any applicable law; (e) your tortious acts or omissions; or (f) your criminal acts or omissions. But you are not required to pay if the loss was caused by the Indemnified Party’s actual intentional misconduct.</p>
          <p class="p-2 text-white">18.2 <strong class="text-pink-700 font-bold">Definitions</strong></p>
          <p class="p-2 text-white">(a) “<strong class="text-pink-700 font-bold">Loss</strong>” means an amount that the Indemnified Party is legally responsible for or pays in any form. <span style="mso-no-proof:yes">Amounts</span> include, for example,<span style="mso-no-proof:yes">a judgment</span>, <span style="mso-no-proof:yes">a settlement</span>, a fine, damages, injunctive relief, staff compensation, a decrease in property value, and expenses for defending against a claim for a loss (including fees for legal counsel, expert witnesses, and other advisers). A loss can be tangible or intangible; can arise from bodily injury, property damage, or other causes; can be based on tort, breach of contract, or any other theory of recovery; and includes incidental, direct, and consequential damages.</p>
          <p class="p-2 text-white">(b) A loss is “<strong class="text-pink-700 font-bold">caused by</strong>”<strong class="text-pink-700 font-bold"> </strong>an event if the<span style="mso-no-proof:yes">loss</span> would not have happened without the event, even if the event is not a proximate cause of the loss.</p>
          <p class="p-2 text-white">18.3 <strong class="text-pink-700 font-bold">Indemnified Party’s Duty to Notify.</strong> The Indemnified Party will<span style="mso-no-proof:yes">notify</span> you before the 30th day after the Indemnified Party knows or should reasonably have known of a claim for a loss that you might be compelled to pay. But the Indemnified Party’s failure to timely notify you does not end your obligation, except if that failure prejudices your ability to defend or mitigate losses.</p>
          <p class="p-2 text-white">18.4 <strong class="text-pink-700 font-bold">Legal Defense of a Claim. </strong>The Indemnified Party has control over defending a claim for a loss (including settling it) unless the Indemnified Party directs you to control the defense.If the Indemnified Party directs you to control the defense, you will not settle any litigation without the Indemnified Party’s written consent if the settlement (1) imposes a penalty or limitation on the Indemnified Party, (2) admits the Indemnified Party’s fault, or (3) does not fully release the Indemnified Party from liability. You and the Indemnified Party will cooperate with each other in good faith on a claim.</p>
          <p class="p-2 text-white">18.5 <strong class="text-pink-700 font-bold">No Exclusivity. </strong>The Indemnified Parties’ rights under this section 18 do not affect other rights they might have.</p>
          <p class="p-2 text-white">19. <strong class="text-pink-700 font-bold">Governing Law; Place for Resolving Disputes</strong></p>
          <p class="p-2 text-white">19.1 UK law, without giving effect to any conflicts of law principles, governs all matters arising out of or relating to the Website or this agreement. The predominant purpose of this agreement is providing services and licensing access to intellectual property and not a “sale of goods.” This agreement will not be governed by the United Nations Convention on Contracts for the International Sale of Goods, the application of which is expressly excluded.</p>
          <p class="p-2 text-white">19.2 For purposes of this section 19, the Website will be deemed solely based in the state of Arizona and will be deemed a passive website that does not give rise to personal jurisdiction over the Company, either specific or general, in any other jurisdiction.</p>
          <p class="p-2 text-white">20. <strong class="text-pink-700 font-bold">Alternative Dispute Resolution</strong></p>
          <p class="p-2 text-white">20.1 <strong class="text-pink-700 font-bold">Arbitration. </strong>As the exclusive means of initiating adversarial proceedings to resolve any dispute arising out of or relating to the Website or this agreement, a party may demand that any such dispute be resolved by arbitration administered by the Arbitration Resolution Services, Inc. (ARS) (or a similar online dispute resolution provider if ARS is not available) in accordance with its rules available at <a rel="nofollow" href="http://www.arbresolutions.com">www.arbresolutions.com</a>, and each party hereby consents to any such dispute being so resolved. The arbitrator, and not any federal, state, or local court or agency, will have exclusive authority to resolve all disputes arising out of or relating to the interpretation, enforceability, or formation of this agreement, including any claim that all or any part of this agreement is void or voidable. Each party will be responsible for paying any filing, administrative, and arbitrator fees associated with the arbitration. The arbitrator may grant whatever relief that would be available in a court at law or in equity, except that the arbitrator must not award punitive or exemplary damages, or damages otherwise limited or excluded in this agreement. The arbitrator’s award will include costs of arbitration, reasonable legal fees under section 20.3, and reasonable costs for expert and other witnesses. Judgment on any award rendered in any such arbitration may be entered in any court having jurisdiction. Unless required by law, neither a party nor an arbitrator will disclose the existence, content, or results of any arbitration under this agreement without the advance written consent of both parties.</p>
          <p class="p-2 text-white">20.2 <strong class="text-pink-700 font-bold">Injunctive Relief.</strong> The parties acknowledge that breach by either party of the obligations under this agreement could cause irreparable harm for which damages would be an inadequate remedy. Nothing in this section 20 will prevent either party from seeking injunctive or other equitable relief from the courts for matters related to data security, intellectual property, or unauthorized access to the Website, in each case without posting a bond or other security and without proof of actual money damages in connection with the claim.</p>
          <p class="p-2 text-white">20.3 <strong class="text-pink-700 font-bold">Recovery of Expenses</strong><strong class="text-pink-700 font-bold">.</strong>In any proceedings between the parties arising out of or relating to the Website or this agreement, the prevailing party will be entitled to recover from the other party, besides any other relief awarded, all expenses that the prevailing party incurs in those proceedings, including legal fees and expenses. For purposes of this section 20.3, “<strong class="text-pink-700 font-bold">prevailing party</strong>” means, for any proceeding, the party in whose favor an award is rendered, except that if in those proceedings the award finds in favor of one party on one or more claims or counterclaims and in favor of the other party on one or more other claims or counterclaims, neither party will be the prevailing party. If any proceedings are voluntarily dismissed or are dismissed as part of settlement of that dispute, neither party will be the prevailing party in those proceedings.</p>
          <p class="p-2 text-white">20.4 <strong class="text-pink-700 font-bold">Jury Trial Waiver.</strong> Each party <span style="mso-no-proof:yes">hereby</span> waives its right to a trial by jury in any proceedings arising out <span style="mso-no-proof:yes">of</span> or relating to the Website or this agreement. Either party may enforce this waiver up to and including the first day of trial.</p>
          <p class="p-2 text-white">20.5 <strong class="text-pink-700 font-bold">Class Action Waiver.</strong> All claims must be brought in the parties’ individual capacity, and not as a plaintiff or class member in any purported class or representative proceeding, and, unless the Company agrees otherwise, the arbitrator will not consolidate more than one person’s claims. Both parties acknowledge that each party is waiving the right to participate in a class action.</p>
          <p class="p-2 text-white">20.6 <strong class="text-pink-700 font-bold">Limitation on Time to Bring Claims. </strong>A party will not bring a claim arising out of or relating to the Website or this agreement more than one year after the cause of action arose. Any claim brought after one year is barred.</p>
          <p class="p-2 text-white">21. <strong class="text-pink-700 font-bold">General</strong></p>
          <p class="p-2 text-white">21.1 <strong class="text-pink-700 font-bold">Entire Agreement.</strong> This agreement constitutes the entire agreement between you and the Company about your access to and use of the Website. It supersedes all earlier or contemporaneous agreements between you and the Company about access to and use of the Website. Any additional terms on the Website will govern the items to which they pertain.</p>
          <p class="p-2 text-white">21.2 <strong class="text-pink-700 font-bold">Changes.</strong> The Company may change this agreement on one or more occasions. The Company will try to post changes on the Website at least 15 days before they become effective. Changes will become effective on the “last updated” date stated at the top of this page. Changes will not apply to continuing disputes or to disputes arising out of (or relating to) events happening before the posted changes. While the Company will try to notify you when the Company changes this agreement, the Company does not assume an obligation to do so, and it is your responsibility to frequently check this page to review the most current agreement. By continuing to use the Website after the Company posts changes to this agreement, you agree to the revised agreement. If you do not agree to the revised agreement, your exclusive remedy is to stop accessing the Website. If you need more information about the changes or have any other questions or comments about the changes, please contact the Company at <a href="mailto:enquiries@borderlandbound.com">enquiries@borderlandbound.com</a>.</p>
          <p class="p-2 text-white">21.3 <strong class="text-pink-700 font-bold">Assignment and Delegation. </strong>The Company may assign its rights or delegate any performance under this agreement without your consent. You will not assign your rights or delegate your performance under this agreement without the Company’s advanced written consent. Any attempted assignment of rights or delegation of performance in breach of this section 21.3 is void.</p>
          <p class="p-2 text-white">21.4 <strong class="text-pink-700 font-bold">No Waivers.</strong> The parties may waive a provision in this agreement only by a writing signed by the party or parties against whom the waiver is sought to be enforced. No failure or delay in exercising any right or remedy, or in requiring the satisfaction of any condition, under this agreement, and no act, omission, or course of dealing between the parties, operates as a waiver or estoppel of any right, remedy, or condition. A waiver made in writing on one occasion is effective only in that instance and only for the purpose stated. A waiver once given is not to be construed as a waiver on any future occasion or against any other person.</p>
          <p class="p-2 text-white">21.5 <strong class="text-pink-700 font-bold">Severability. </strong>The parties intend as follows:</p>
          <p class="p-2 text-white">(a) that if any provision of this agreement is held to be unenforceable, then that provision will be modified to the minimum extent necessary to make it enforceable, unless that modification is not permitted by law, in which case that provision will be disregarded;</p>
          <p class="p-2 text-white">(b) that if modifying or disregarding the unenforceable provision would result in failure of an essential purpose of this agreement, the entire agreement will be held unenforceable;</p>
          <p class="p-2 text-white">(c) that if an unenforceable provision is modified or disregarded in accordance with this section <span style="mso-bidi-font-size:11.0pt;mso-bidi-font-family:Arial">21.5</span><span style="mso-bidi-font-size:11.0pt;mso-bidi-font-family:Arial">, then the rest of the agreement will remain in effect as written; and</span></p>
          <p class="p-2 text-white">(d) that any unenforceable provision will remain as written in any circumstances other than those in which the provision is held to be unenforceable.</p>
          <p class="p-2 text-white">21.6 <strong class="text-pink-700 font-bold">Notices</strong></p>
          <p class="p-2 text-white">(a) <strong class="text-pink-700 font-bold">Sending Notice to the Company. </strong>You may send notice to the Company by email at <a href="mailto:enquiries@borderlandbound.com">enquiries@borderlandbound.com</a> unless a specific email address is set out for giving notice. The Company will consider an email notice received by the Company only when its server sends a return message to you acknowledging receipt. The Company may change its contact information on one or more occasions by posting the change on the Website. Please check the Website for the most current information for sending notice to the Company.</p>
          <p class="p-2 text-white">(b) <strong class="text-pink-700 font-bold">Sending Notice to You­—Electronic Notice.</strong> You consent to receiving any notice from the Company in electronic form either (1) by email to the last known email address the Company has for you or (2) by posting the notice on a place on the Website chosen for this purpose. The Company will consider notices sent to you by email received when its email service shows transmission to your email address. You state that any email address you gave the Company for contacting you is a current and valid email address for receiving notice, and that your computer has hardware and software configured to send and receive email through the Internet and to print any email you receive.</p>
          <p class="p-2 text-white">21.7 <strong class="text-pink-700 font-bold">Force Majeure.</strong> The Company is not responsible for any failure to perform if unforeseen circumstances or causes beyond its reasonable control delays or continues to delay its performance, including (a) acts of God, including fire, flood, earthquakes, hurricanes, tropical storms, or other natural disasters; (b) war, riot, arson, embargoes, acts of civil or military authority, or terrorism; (c) fiber cuts; (d) strikes, or shortages in transportation, facilities, fuel, energy, labor, or materials; (e) failure of the telecommunications or information services infrastructure; and (f) hacking, SPAM, or any failure of a computer, server, network, or software.</p>
          <p class="p-2 text-white">21.8 <strong class="text-pink-700 font-bold">No Third-Party Beneficiaries. </strong>This agreement does not, and the parties do not intend it to, confer any rights or remedies on any person other than the parties to this agreement.</p>
          <p class="p-2 text-white">21.9 <strong class="text-pink-700 font-bold">Relationship of the Parties. </strong><span style="mso-bidi-font-size:11.0pt;mso-bidi-font-family:Arial">This agreement does not, and the parties do not intend it to, create a partnership, joint venture, agency, franchise, or employment relationship between the parties and the parties expressly disclaim the existence of any of these relationships between them. Neither of the parties is the agent for the other, and neither party has the right to bind the other on any agreement with a third party.</span></p>
          <p class="p-2 text-white">21.10 <strong class="text-pink-700 font-bold">Successors and Assigns</strong><strong class="text-pink-700 font-bold">.</strong> This agreement inures to the benefit of, and are binding on, the parties and their respective successors and assigns. This section 21.10 does not address, directly or indirectly, whether a party may assign rights or delegate obligations under this agreement. Section 21.3 addresses these matters.</p>
          <p class="p-2 text-white">21.11 <strong class="text-pink-700 font-bold">Electronic Communications Not Private.</strong> The Company does not provide facilities for sending or receiving confidential electronic communications. You should consider all messages sent to the Company or from the Company as open communications readily accessible to the public. You should not use the Website to send or receive messages you only intend the sender and named recipients to read. Users or operators of the Website may read all messages you send to the Website regardless of whether they are intended recipients.</p>
          <p class="p-2 text-white">21.12 <strong class="text-pink-700 font-bold">Electronic Signatures. </strong>Any affirmation, assent, or agreement you send through the Website will bind you. You acknowledge that when you click on an “I agree,” “I consent,” or other similarly worded “button” or entry field with your finger, mouse, keystroke, or other device, your agreement or consent will be legally binding and enforceable and the legal equivalent of your handwritten signature.</p>
          <p class="p-2 text-white"><span >&nbsp;
            </span>Borderland Digital Ltd
            UK </p>
          <p class="p-2 text-white"> Users who want to gain access to the members-only area of the Website must be a member in good standing. Users seeking to buy clips individually are redirected to <a href="#">www.borderlandbound.com</a>. The Company posts the current membership fees for the Website on the registration page. The Company may change the membership fees at any time. Users may contact the Company at <a href="mailto:enquires@borderlandbound.com">enquires@borderlandbound.com</a> to resolve any billing disputes or to receive further information about the Website.</p>
          <p class="p-2 text-white">21.15 <strong class="text-pink-700 font-bold">Feedback.</strong> The Company encourage you to provide feedback about the Website. But the Company will not treat as confidential any suggestion or idea provided by you, and nothing in this agreement will restrict its right to use, profit from, disclose, publish, or otherwise exploit any feedback, without payment to you.</p>
          <p class="p-2 text-white">21.16 <strong class="text-pink-700 font-bold">Your Comments and Concerns.</strong> You should direct all feedback, comments, requests for technical support, and other communications relating to the Website to <a href="mailto:enquiries@borderlandbound.com">enquiries@borderlandbound.com</a>.</p></div>

        </div>
        <!-- Promo Pricing-->
        <pricing-component></pricing-component>
      
    </BrochureLayout>
  </template>
  <script>
import BrochureLayout from "../layouts/BrochureLayout";
import { StarIcon } from "@heroicons/vue/solid";
import page_header from "@/reuseable/page_header";
import pricingComponent from "@/reuseable/pricing_component.vue";

export default {
  components: {
    pricingComponent,
    BrochureLayout,
    StarIcon,
    page_header,
  },
  data() {
    return {};
  },
  methods: {},
  created() {},
};
</script>
  <style></style>


