<template>
    <admin-layout>
        <h1>Admin home</h1>
    </admin-layout>
</template>

<script>
import AdminLayout from '@/layouts/adminLayout.vue'
export default {
    components: {
        AdminLayout
    }
}
</script>

<style>

</style>