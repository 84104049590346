<template>
      <div>
        <div class="max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:px-8">
          <p class="text-center text-sm font-semibold uppercase text-gray-500 tracking-wide">What’re You Waitin’ For? Join Instantly!</p>
          <div class="mt-6 grid grid-cols-2 gap-8 md:grid-cols-6 lg:grid-cols-5">
            <div class="col-span-1 flex justify-center md:col-span-2 lg:col-span-1" v-for="cover in covers" :key="cover.cover_url">
              <img class="h-32 w-auto p-1 bg-white border rounded max-w-sm" :src="cover.cover_thumbnail_url" alt="Tuple" />
            </div>
          </div>
        </div>
      </div>
</template>

<script>
import axios from 'axios'
export default {
    data() {
        return {
            api_url: process.env.VUE_APP_API_URL,

            covers: []
        }
    },
    methods: {
        getCovers() {
            axios.get(this.api_url + '/api/videos/random_covers').then(response => {
                //console.log(response)
                this.covers = response.data.records
            })
        },
    },
    created() {
        //console.log('photos promo')
        this.getCovers()    
        }
}
</script>

<style>

</style>