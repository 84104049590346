<template>
  <div class="mx-auto py-4 px-4 max-w-7xl sm:px-6 lg:px-8 lg:py-4">
    <div class="space-y-5 sm:space-y-4 md:max-w-xl lg:max-w-3xl xl:max-w-none mb-4">
          <h2 class="text-white text-3xl font-extrabold tracking-tight sm:text-4xl">Our Videos</h2>
        </div>
    <div>
      <div class="mt-1 flex rounded-md shadow-sm mb-8">
        <div class="relative flex flex-grow items-stretch focus-within:z-10">
          <div class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
            <video-camera-icon class="h-5 w-5 text-gray-400" aria-hidden="true"/>
          </div>
          <input type="text" name="search" id="search"
                 class="block w-full rounded-none rounded-l-md pl-10 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                 placeholder="Search Videos" v-model="search"/>
        </div>
        <button type="button"
                class="relative -ml-px inline-flex items-center space-x-2  bg-pink-900 px-4 py-2 text-sm font-medium text-white hover:bg-pink-900 focus:border-pink-900 focus:outline-none focus:ring-1 focus:ring-pink-900"
        @click="SearchAllVideos">
          <search-icon class="h-5 w-5 text-white" aria-hidden="true"/>
          <span>Search</span>
        </button>
        <button type="button"
                class="relative -ml-px inline-flex items-center space-x-2 rounded-r-md  bg-pink-700 px-4 py-2 text-sm font-medium text-white hover:bg-pink-900 focus:border-pink-900 focus:outline-none focus:ring-1 focus:ring-pink-900"
        @click="getAllVideos">
          <video-camera-icon class="h-5 w-5 text-white" aria-hidden="true"/>
          <span>All Videos</span>
        </button>
      </div>
    </div>
    <div v-if="isMember == false" class="mb-4 space-y-12 font-medium text-white">
        <div class="mt-1 text-base font-medium text-white">
            Upgrade to full membership to see our Members Videos.
        </div>
        <div class="mt-1 text-base font-medium text-white">
            <a href="/member/signup" 
               class="hover:bg-pink-900 w-full rounded-md border border-transparent bg-pink-700 py-3 px-4 text-base font-medium text-white shadow-sm"
            >Register</a> 
        </div>
    </div>

    <div v-else>
        <ul role="list" class="space-y-12 sm:grid sm:grid-cols-2 sm:gap-x-6 sm:gap-y-12 sm:space-y-0 lg:grid-cols-3 lg:gap-x-8">
          <li v-for="video in videos" :key="video.title">
            <div class="space-y-4 p-1 hover:cursor-pointer">
              <div class="aspect-w-3 aspect-h-2">
                <img @click="openVideo(video.video_url, video.download_url, video.title, video.description)" class="object-cover shadow-lg rounded-lg w-full" :src="hovered === video.title ? video.cover_url : video.cover_thumbnail_url" @mouseover="hovered = video.title"
                     @mouseleave="hovered = null" :alt="videoTitle.title" />
              </div>

              <div class="space-y-2">
                <div class="text-lg leading-6 font-medium space-y-1 text-white">
                  <h3>{{ video.title }}</h3>
                </div>
                <ul role="list" class="flex space-x-5">
                  <li>
                  </li>
                </ul>
              </div>
            </div>
          </li>
        </ul>
    </div>

    <nav v-if="isMember == true"
         class="bg-pink-700 px-4 py-3 flex items-center justify-between border-t border-pink-900 sm:px-6 mt-5" aria-label="Pagination">
    <div class="hidden sm:block">
      <p class="text-sm text-white">
        Showing Page
        {{ ' ' }}
        <span class="font-medium">{{videosPag.page}}</span>
        {{ ' ' }}
        of
        {{ ' ' }}
        <span class="font-medium">{{videosPag.page_count}}</span>

      </p>
    </div>
    <div class="flex-1 flex justify-between sm:justify-end">
      <a href="#" v-show='this.videosPag.page > 1' class="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50" @click="getLastPage"> Previous </a>
      <a href="#" class="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50" @click="getNextPage"> Next </a>
    </div>
  </nav>
    <video-player :open="openVideoPop" 
                  :videoLink="videoLink" 
                  :downloadLink="downloadLink" 
                  :videoTitle="videoTitle" 
                  :videoDesc="videoDesc"></video-player>
    </div>

</template>

<script>
import {MailIcon, PhoneIcon, SearchIcon, VideoCameraIcon, UserIcon, SearchCircleIcon} from '@heroicons/vue/solid'
import videoPlayer from "@/modals/videoPlayer";
import {getStorage} from "@/saveStorage";
import axios from "axios";
import MembersLayout from "@/layouts/BrochureLayout";

export default {
   components: {
    MembersLayout,
    videoPlayer,
    MailIcon, PhoneIcon, SearchIcon, VideoCameraIcon, UserIcon
  },
  data() {
    return {
      api_url: process.env.VUE_APP_API_URL,

      auth: {},
      videos: {},
      hovered: null,
      videosPag: {},
      openVideoPop: false,
      videoLink: '',
      downloadLink: '',
      videoTitle: '',
      videoDesc: '',
      search: ''
    }
  },

  computed: {
    isMember() {
      let is_member = false;
      if (this.auth && this.auth.is_member == true) {
        is_member = true;
      }

      return is_member;
    },
  },

  created() {
    this.auth = getStorage('auth')
    this.getAllVideos()
  },

  methods: {
    SearchAllVideos() {
      this.videos = {};
      const config = {
         headers: { Authorization: `Bearer ` + this.auth.token.replace(/^"(.+(?="$))"$/, '$1') }
      };

      const bodyParameters = {
         key: "value"
      };
      axios.get(this.api_url + '/api/members_videos?per_page=9&q=' + this.search, config).then(response => {
        this.videos = response.data.records
        this.videosPag = response.data.pagination
      })
    },

    getAllVideos() {
      this.search = '';
      this.videos = {};

      const config = {
         headers: { Authorization: `Bearer ` + this.auth.token.replace(/^"(.+(?="$))"$/, '$1') }
      };

      const bodyParameters = {
         key: "value"
      };
      axios.get(this.api_url + '/api/members_videos?per_page=9', config).then(response => {
        this.videos = response.data.records
        this.videosPag = response.data.pagination
      })
    },

    getNextPage() {
      this.videos = {};

      const config = {
         headers: { Authorization: `Bearer ` + this.auth.token.replace(/^"(.+(?="$))"$/, '$1') }
      };

      const bodyParameters = {
         key: "value"
      };

      var url = this.api_url + '/api/members_videos';
          url = url + '?q=' + (this.search || '');
          url = url + '&per_page=9&page=' + parseInt(this.videosPag.page + 1);

      axios.get(url, config).then(response => {
        this.videos = response.data.records
        this.videosPag = response.data.pagination
      })
    },
    
    getLastPage() {
      this.videos = {};

      const config = {
         headers: { Authorization: `Bearer ` + this.auth.token.replace(/^"(.+(?="$))"$/, '$1') }
      };

      const bodyParameters = {
         key: "value"
      };
      
      var url = this.api_url + '/api/members_videos';
          url = url + '?q=' + (this.search || '');
          url = url + '&per_page=9&page=' + parseInt(this.videosPag.page - 1);

      axios.get(url, config).then(response => {
        this.videos = response.data.records
        this.videosPag = response.data.pagination
      })
    },

    openVideo(event, download, title, desc) {
      this.videoTitle = title
      this.videoDesc = desc
      this.videoLink = event
      this.downloadLink = download
      this.openVideoPop = true
    }
  }
}
</script>