<template>
<AdminLayout>

  <div class="md:flex md:items-center md:justify-between p-5 bg-white rounded-t-lg">
    <div class="flex-1 min-w-0">
      <h2 class="text-2xl leading-7 text-blue-600 sm:text-3xl sm:truncate">All Images</h2>
    </div>
    <div class="mt-4 flex md:mt-0 md:ml-4">
      <p class="mt-2 pr-2 text-indigo-600 font-bold">Page {{videoPag.page}} of {{videoPag.page_count}}</p>
      <button type="button" class="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-gray-600 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-indigo-500" @click="getLastPage">Previous</button>
      <button type="button" class="ml-3 inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-500 hover:bg-indigo-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-indigo-500" @click="getNextPage">Next</button>
    </div>
  </div>

  <div class="bg-white shadow overflow-hidden sm:rounded-md">
    <ul role="list" class="divide-y divide-gray-200">
      <li v-for="video in videos" :key="video.id">
          <div class="flex items-center px-4 py-4 sm:px-6">
            <div class="min-w-0 flex-1 flex items-center">
              <div class="flex-shrink-0">
                <img class="h-12 w-12" :src="video.cover_thumbnail_url" alt />
              </div>
              <div class="min-w-0 flex-1 px-4 md:grid md:grid-cols-2 md:gap-4">
                <div>
                  <p
                    class="text-sm font-medium text-indigo-600 truncate"
                  >{{ video.title }}</p>
                  <p class="mt-2 flex items-center text-sm text-gray-500">

                  </p>
                </div>
                <div class="hidden md:block">
                    <div>
                    <input type=checkbox 
                           :checked="video.is_hidden == false"
                           @click="toggle_hidden(video)" /> Show
                  </div>
                  <div>
                    <p class="text-sm text-gray-900">
                      Added on <time :datetime="video.created_at">{{ video.created_at.slice(0, 10) }}</time>
                    </p>
                  </div>
                </div>
              </div>
            </div>
                <div>
                    <button
                    type="submit"
                    @click="delete_areyousure(video.id)"
                    class="
                    ml-3
                    inline-flex
                    justify-center
                    py-2
                    px-4
                    border border-transparent
                    shadow-sm
                    text-sm
                    font-medium
                    rounded-md
                    text-white
                    bg-red-600
                    hover:bg-red-700
                    disabled:opacity-50
                "
                >
                Delete
                </button>
            </div>
          </div>
      </li>
    </ul>
  </div>
</AdminLayout>
</template>

<script>
import {
  CheckCircleIcon,
  ChevronRightIcon,
  MailIcon,
  BanIcon
} from "@heroicons/vue/solid";
import AdminLayout from "@/layouts/adminLayout.vue";
import {getStorage} from "@/saveStorage";
import axios from "axios";
export default {
  components: {
    CheckCircleIcon,
    ChevronRightIcon,
    BanIcon,
    MailIcon,
    AdminLayout,
  },
  data() {
    return {
      api_url:  process.env.VUE_APP_API_URL,
      videos:   null,
      videoPag: 0,
      token:    null,
      search:   '',
    };
  },

  created() {
    this.token = getStorage('token');
    this.search = this.$route.query.search;
  },
  mounted() {
    this.getAllVideos()
  },

  methods: {
    toggle_hidden(media) {
      const config = {
        headers: { Authorization: `Bearer ` + this.token }
      };

      let toggle = 'hide';
      if (media.is_hidden == true) {
        toggle = 'unhide';
      }

      axios.post(this.api_url + '/api/admin/photosets/' + media.id + '/' + toggle, {}, config).then(response => {
        //console.log(response.data)
      });
    },

    async getAllVideos() {
      const config = {
        headers: { Authorization: `Bearer ` + this.token }
      };

      const bodyParameters = {
         key: "value"
      };

      var url = this.api_url + '/api/admin/photosets';
          url = url + '?q=' + (this.search || '');
          url = url + '&page=1';

      await axios.get(url, config).then(response => {
        this.videos = response.data.records
        this.videoPag = response.data.pagination
        this.search = response.data.search.q
      })
    },

    getNextPage() {
      const config = {
        headers: { Authorization: `Bearer ` + this.token }
      };

      const bodyParameters = {
         key: "value"
      };

      var page = parseInt(this.videoPag.page) + 1;
      if (page > this.videoPag.page_count) {
          page = this.videoPag.page_count;
      }

      var url = this.api_url + '/api/admin/photosets';
            url = url + '?q=' + (this.search || '');
            url = url + '&page=' + page;

      axios.get(url, config).then(response => {
        this.videos = response.data.records
        this.videoPag = response.data.pagination
        this.search = response.data.search.q
      })
    },

    getLastPage() {
      const config = {
        headers: { Authorization: `Bearer ` + this.token }
      };

      const bodyParameters = {
         key: "value"
      };

      var page = parseInt(this.videoPag.page) - 1;
      if (page < 1) {
          page = 1;
      }

      var url = this.api_url + '/api/admin/photosets';
            url = url + '?q=' + (this.search || '');
            url = url + '&page=' + page;

      axios.get(url, config).then(response => {
        this.videos = response.data.records
        this.videoPag = response.data.pagination
        this.search = response.data.search.q
      })
    },


    delete_areyousure(photoset_id) {
        let _self = this;
        this.$swal({
              title:            'Are you sure?',
              html:             '<b>This operation cannot be undone.</b><br><br> \
                                 You can un-tick the "show" check-box to hide the photoset instead.<br><br> \
                                 If you are sure you want to delete, then click "Yes", otherwise click "No".',

              showCancelButton:   true,
              allowEscapeKey:     false,
              allowOutsideClick:  false,


              confirmButtonColor: '#4f46e5',
              cancelButtonColor:  '#dc2626',
              confirmButtonText:  'No',
              cancelButtonText:   'Yes',
              icon:               'question',
              background:         '#3f3f46',
        }).then(function(response) {
            // NOTE: confirm and cancel have been swapped so that the default is to NOT delete
            if (response && response.isConfirmed == true) {
                _self.dont_delete_file();
            } 
            else {
                _self.delete_file(photoset_id);
            }
        });
    },

    delete_file(photoset_id) {
      const config = {
        headers: {
          Authorization: `Bearer ` + this.token,
        },
        maxContentLength: Infinity,
        maxBodyLength: Infinity,
      };
      axios
          .post(
              this.api_url + '/api/admin/photosets/' + photoset_id +'/delete',
              this.video,
              config,

          )
          .then((response) => {
            this.$swal({
              title:              'Photoset deleted',
              confirmButtonColor: '#be185d',
              confirmButtonText:  'Close',
              icon:               'success',
              background:         '#3f3f46',
            });
            this.$router.push({name: 'adminImages'})
          });
    },

    dont_delete_file() {
        this.$swal({
            title:              'Photoset was not deleted',
            confirmButtonColor: '#be185d',
            confirmButtonText:  'Close',
            icon:               'success',
            background:         '#3f3f46',
        });
    },
  },
};
</script>