<template>
  <brochure-layout>
    <div class="bg-zinc-900">
      <store-menu></store-menu>
      <page_header>
        <template v-slot:title>
          Your Cart
        </template>
        <template v-slot:tag>

        </template>
      </page_header>
    <div class="mx-auto max-w-2xl px-4 pt-16 pb-24 sm:px-6 lg:max-w-7xl lg:px-8">
      <h1 class="text-3xl font-bold tracking-tight text-pink-700 sm:text-4xl"></h1>
      <form class="mt-12 lg:grid lg:grid-cols-12 lg:items-start lg:gap-x-12 xl:gap-x-16">
        <section aria-labelledby="cart-heading" class="lg:col-span-7">
          <h2 id="cart-heading" class="sr-only">Items in your shopping cart</h2>

          <ul role="list" class="divide-y divide-gray-200 border-t border-b border-gray-200">
            <li v-for="(video, index) in shoppingCart" :key="video.id" class="flex py-6 sm:py-10">
              <div class="flex-shrink-0">
                <img :src="video.image" :alt="video.title" class="h-24 w-24 rounded-md object-cover object-center sm:h-48 sm:w-48" />
              </div>

              <div class="ml-4 flex flex-1 flex-col justify-between sm:ml-6">
                <div class="relative pr-9 sm:grid sm:grid-cols-2 sm:gap-x-6 sm:pr-0">
                  <div>
                    <div class="flex justify-between">
                      <h3 class="text-sm text-pink-700">
                        {{ video.title }}
                      </h3>
                    </div>
                    <div class="mt-1 flex text-sm">
                    </div>
                    <p class="mt-1 text-sm font-medium text-pink-700">${{ parseFloat(video.price).toFixed(2) }}</p>
                    <p class="mt-5 text-white text-sm
                              rounded-md border border-transparent bg-pink-700 py-1 px-2 text-base 
                              hover:bg-pink-900 hover:cursor-pointer"
                       v-on:click="removeItem(index)">Remove</p>
                  </div>

                  <div class="mt-4 sm:mt-0 sm:pr-9">



                  </div>
                </div>

                <p class="mt-4 flex space-x-2 text-sm text-gray-700">

                </p>
              </div>
            </li>
          </ul>
        </section>

        <!-- Order summary -->
        <section aria-labelledby="summary-heading" class="mt-16 rounded-lg bg-zinc-900 px-4 py-6 sm:p-6 lg:col-span-5 lg:mt-0 lg:p-8">
          <h2 id="summary-heading" class="text-lg font-medium text-pink-700">Order summary</h2>

          <dl class="mt-6 space-y-4">
            <div class="flex items-center justify-between">
              <dt class="text-sm text-white">Subtotal</dt>
              <dd class="text-sm font-medium text-pink-700">${{ parseFloat(totalPrice).toFixed(2) }}</dd>
            </div>


            <div class="flex items-center justify-between border-t border-gray-200 pt-4">
              <dt class="text-base font-medium text-white">Order total</dt>
              <dd class="text-base font-medium text-pink-700">${{ parseFloat(totalPrice).toFixed(2) }}</dd>
            </div>
          </dl>

          <div v-if="is_registered == true" class="mt-6">
            <button type="submit" 
                    class="w-full rounded-md border border-transparent bg-pink-700 py-3 px-4 text-base font-medium text-white shadow-sm hover:bg-pink-900 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-50"
                    @click="checkout()"
            >Checkout</button>
          </div>
          <div v-else
               class="mt-6 text-base font-medium text-white">
            <a href="/member/signin" 
               class="hover:bg-pink-900 w-full rounded-md border border-transparent bg-pink-700 py-3 px-4 text-base font-medium text-white shadow-sm"
            >sign-in</a> 

            or 

            <a href="/member/signup?type=free" 
               class="hover:bg-pink-900 w-full rounded-md border border-transparent bg-pink-700 py-3 px-4 text-base font-medium text-white shadow-sm"
            >register</a> 
            (a free account)
          </div>
        </section>
      </form>
    </div>
  </div>
  </brochure-layout>
</template>

<script>
import { CheckIcon, ClockIcon, QuestionMarkCircleIcon } from '@heroicons/vue/solid'
import BrochureLayout from "@/layouts/BrochureLayout";
import storeMenu from "@/ecommerce/storeMenu"
import {getStorage, saveStorage} from "@/saveStorage";
import page_header from "@/reuseable/page_header";
export default {
  components: {
    CheckIcon, ClockIcon, QuestionMarkCircleIcon, BrochureLayout, storeMenu, page_header
  },

  data() {
    return {
      token:        null,
      shoppingCart: null,
      totalPrice:   0,
    }
  },

  computed: {
    is_registered() {
      let ir = false;

      if (this.token && this.token != '') {
          ir = true;
      }

      return ir;
    },

    totalCart() {
    }
  },

  created() {
    this.token        = getStorage('token');
    this.shoppingCart = getStorage('cart');
    this.buildPricing()

  },

  methods: {
    checkout() {
      this.$router.push({name: 'StoreCheckout'})
    },
    buildPricing() {
      if (this.shoppingCart.length > 0) {
        let price = 0
        this.shoppingCart.forEach(function (item) {
          price = price + item.price
        })
        this.totalPrice = price
      } else {
        this.totalPrice = 0
      }
    },
    removeItem(index) {
      this.shoppingCart.splice(index, 1);
      this.$swal({
        title:              'Item removed from cart',
        confirmButtonColor: '#be185d',
        confirmButtonText:  'Close',
        icon:               'success',
        background:         '#3f3f46',
      })
      this.buildPricing()
      saveStorage('cart', this.shoppingCart);
    }
  }
}

</script>