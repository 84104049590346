<template>
  <BrochureLayout>
    <div class="bg-zinc-900">
      <page-header>
        <template v-slot:title>Member sign-up completed</template>
      </page-header>
      <div class="max-w-7xl mx-auto overflow-hidden sm:px-6 lg:px-8 mb-3 p-10 border-t-2 border-b-2 border-gray-200 mt-5 text-center">
<!--        <p class="text-white mb-2">Click </p>-->
        <div class="text-white">
          Subscription approved! Please &nbsp;&nbsp;
          <a href="/member/signin" class="px-2 py-1 border border-transparent text-center text-base font-medium rounded-md shadow-sm text-pink-700 bg-white hover:bg-indigo-50 sm:px-8"> Sign in </a>
          &nbsp;&nbsp;to continue.
        </div>

      </div>

    </div>
  </BrochureLayout>
</template>

<script>
import BrochureLayout from '../layouts/BrochureLayout.vue'
import {StarIcon} from '@heroicons/vue/solid'
import VideoStreaming from '@/reuseable/video_streaming.vue'
import PageHeader from '@/reuseable/page_header.vue'

export default {
  components: {
    BrochureLayout,
    StarIcon,
    VideoStreaming,
    PageHeader
  },
  data() {
    return {}
  },
  mounted() {
    // Parse URL parameters
    const urlParams = new URLSearchParams(window.location.search);

    // Push purchase data to dataLayer
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: 'subscription_purchase',
      ecommerce: {
        transaction_id: urlParams.get('subscriptionId'),
        value: parseFloat(urlParams.get('initialPrice')),
        currency: "GBP",
        items: [{
          item_id: urlParams.get('typeId'),
          item_name: `${urlParams.get('initialPeriod')} Day Subscription`,
          price: parseFloat(urlParams.get('initialPrice'))
        }]
      }
    });
  },
  methods: {},
}
</script>