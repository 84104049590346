<template>
  <BrochureLayout>
    <div class="bg-zinc-900">
      <page-header>
        <template v-slot:title>About</template>
        <template v-slot:tag>The most beautiful girls in bondage are here at Borderland Bound.
        </template>
      </page-header>
      <div class="max-w-7xl mx-auto overflow-hidden sm:px-6 lg:px-8 mb-3 p-10 border-t-2 border-b-2 border-gray-200 mt-5 text-center">
        <p class="text-white mb-2">The most beautiful girls in bondage are here at Borderland Bound.</p>

        <p class="text-white mb-2">Membership grants you instant access to those ladies, tightly bound and gagged.</p>

        <p class="text-white mb-2">Including our latest archives, you'll get at least 7 updates per week of Photo Sets & Video Clips.</p>

        <p class="text-white mb-2">We'll also be adding Full Length Videos, Behind the Scenes footage, Exclusive Clips & More.</p>

        <p class="text-white mb-2">JOIN NOW and have access to it all.</p>
      </div>
      <video-streaming></video-streaming>
    </div>
  </BrochureLayout>
</template>

<script>
import BrochureLayout from '../layouts/BrochureLayout'
import {StarIcon} from '@heroicons/vue/solid'
import VideoStreaming from '@/reuseable/video_streaming'
import PageHeader from '@/reuseable/page_header'

export default {
  components: {
    BrochureLayout,
    StarIcon,
    VideoStreaming,
    PageHeader
  },
  data() {
    return {}
  },
  methods: {},
}
</script>