<template>
    <admin-layout>
        <div class="space-y-8 divide-y divide-gray-200">
            <div class="space-y-8 divide-y divide-gray-200 sm:space-y-5">
                <div>
                    <div>
                        <h3 class="text-lg leading-6 font-medium text-gray-900">
                            Create DMCA Notice
                        </h3>
                    </div>

                    <div class="mt-6 sm:mt-5 space-y-6 sm:space-y-5">
                        <!-- Image title -->
                        <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                            <label for="company" class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                                Company
                            </label>
                            <div class="mt-1 sm:mt-0 sm:col-span-2">
                                <div class="max-w-lg flex rounded-md shadow-sm">
                                    <select id="company" v-model="company" name="company" 
                                            class="flex-1 block w-full focus:ring-indigo-500 focus:border-indigo-500 min-w-0 
                                                   rounded-none rounded-r-md sm:text-sm border-gray-300"
                                            placeholder="Please select a company">

                                        <option v-for="company in companies" 
                                                :key="company.id"
                                                :value="company.id">{{ company.name }}
                                        </option>
                                    </select>
                                </div>
                                <span class="font-small text-sm text-pink-800">{{ errors['company'] }}</span>
                            </div>
                        </div>
                    </div>

                    <div class="mt-6 sm:mt-5 space-y-6 sm:space-y-5">
                        <!-- Image title -->
                        <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                            <label for="links" class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                                Links
                            </label>
                            <div class="mt-1 sm:mt-0 sm:col-span-2">
                                <div class="max-w-lg flex rounded-md shadow-sm">
                                    <textarea rows="15" name="links" id="links" v-model="links" 
                                              class="flex-1 block w-full focus:ring-indigo-500 focus:border-indigo-500 min-w-0 
                                                     rounded-none rounded-r-md sm:text-sm border-gray-300" 
                                              placeholder="Please enter the links to the content"/>

                                </div>
                                <span class="font-small text-sm text-pink-800">{{ errors['links'] }}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="pt-5">
                <div class="flex justify-end">
                    <button
                        type="submit"
                        @click="add_notice"
                        class="ml-3 inline-flex justify-center py-2 px-4
                                border border-transparent
                                shadow-sm font-medium rounded-md
                                text-white
                                bg-indigo-600 
                                hover:bg-indigo-700
                                disabled:opacity-50"
                        style="float:right;"
                    >
                        Add
                    </button>
                </div>
            </div>
        </div>
    </admin-layout>
</template>
<script>
    import axios          from "axios";
    import { getStorage } from "@/saveStorage";
    import AdminLayout    from "@/layouts/adminLayout.vue";
  
    export default {
        components: {
            AdminLayout,
        },
  
        data() {
            return {
                api_url:    process.env.VUE_APP_API_URL,
                errors:     [],
    
                companies:  [],
                company:    null,
                links:      [],
            };
        },
  
        watch: {},
        computed: {},
      
        created() {
            this.token  = getStorage('token');
            this.search = this.$route.query.search;

            this.getAllCompanies();
        },
    
        mounted() {
        },
  
        methods: {
            async getAllCompanies() {
                const config = {
                    headers: { Authorization: `Bearer ` + this.token }
                };

                var url = this.api_url + '/api/admin/companies?per_page=99';

                await axios.get(url, config).then(response => {
                    this.companies  = response.data.records
                })
            },  
          
            async add_notice() {
                const config = {
                    headers: { Authorization: `Bearer ` + this.token }
                };

                var url = this.api_url + '/api/admin/dmcanotices/create';

                var data = {
                    "company_id": this.company,
                    "links": this.links,
                    "type":  "notice",
                    "force": 0
                }

                axios.post(url, data, config)
                .then((response) => {
                    this.$swal({
                        title:              'Notice Created',
                        confirmButtonColor: '#be185d',
                        confirmButtonText:  'Close',
                        icon:               'success',
                        background:         '#3f3f46',
                    });

                    this.$router.push({name: 'allNotices'});
                })
                .catch(error => {
                    var error_msg     = '';
                    var error_details = '';
                    if (error.response.data.message) {
                        error_msg = error.response.data.message;	
                    }
                    
                    if (error.response.data.errors) {
                        Object.keys(error.response.data.errors).forEach(key => {
                            error_details = error_details + error.response.data.errors[key].toString() + "<br>";

                            // load api errors into client errors
                            this.errors[key] = error.response.data.errors[key].toString();
                        });
                    }
                });

            },
        },
    };
</script>
  
<style>
</style>