<template>
    <admin-layout>
        <div class="space-y-8 divide-y divide-gray-200">
            <div class="space-y-8 divide-y divide-gray-200 sm:space-y-5">
                <div>
                    <div class="w-100 flex justify-between">
                        <div>
                            <h3 class="text-lg leading-6 font-medium text-gray-900">
                                All DMCA Companies
                            </h3>
                        </div>
                        <div v-if="pagination" 
                             class="mt-4 flex md:mt-0 md:ml-4">
                            <p class="mt-2 pr-2 text-indigo-600 font-bold">Page {{ pagination.page }} of {{ pagination.page_count }}</p>
                            <button v-if="pagination.page > 1"
                                    type="button" 
                                    class="inline-flex items-center px-4 py-2 
                                           border border-transparent rounded-md shadow-sm 
                                           text-sm font-medium text-white bg-gray-600 
                                           hover:bg-gray-700 
                                           focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-indigo-500" 
                                           @click="getPrevPage">Previous</button>
                            <button v-if="pagination.page < pagination.page_count"
                                    type="button" 
                                    class="ml-3 inline-flex items-center px-4 py-2 
                                           border border-transparent rounded-md shadow-sm 
                                           text-sm font-medium text-white bg-blue-500 
                                           hover:bg-indigo-600 
                                           focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-indigo-500" 
                                           @click="getNextPage">Next</button>
                        </div>
                        <div>
                            <button @click="open_edit()"
                                    class="py-2 px-4 text-white bg-green-600 hover:bg-green-700 rounded-lg">
                                Create Company
                            </button>
                        </div>
                    </div>
                    <div class="mt-8 flex flex-col">
                        <div class="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                            <div class="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                                <div class="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                                    <table class="min-w-full divide-y divide-gray-300">
                                        <thead class="bg-gray-50">
                                            <tr>
                                                <th scope="col" class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">Name</th>
                                                <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Web</th>
                                                <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Firewall</th>
                                                <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Host</th>
                                                <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">ISP</th>
                                                <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Notices</th>
                                                <th scope="col" class="px-3 py-3.5">
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody class="divide-y divide-gray-200 bg-white">
                                            <tr v-for="company in companies" :key="company.id">
                                                <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-small text-gray-900 sm:pl-6">{{ company.name }}<br>{{ company.email }}</td>
                                                <td class="whitespace-nowrap px-3 py-4 text-sm font-small text-gray-500">{{ company.web }}</td>
                                                <td class="whitespace-nowrap px-3 py-4 text-sm font-small text-gray-500">{{ (company.has_firewall == true) ? 'Yes' : 'No' }}</td>
                                                <td class="whitespace-nowrap px-3 py-4 text-sm font-small text-gray-500">{{ company.host }}<br>{{ company.host_email }}</td>
                                                <td class="whitespace-nowrap px-3 py-4 text-sm font-small text-gray-500">{{ company.isp }}<br>{{ company.isp_email }}</td>
                                                <td class="whitespace-nowrap px-3 py-4 text-sm font-small text-gray-500">{{ (company.notices > 0) ? 'Yes' : 'No' }}</td>
                                                <td class="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                                                    <button @click="open_edit(company)" class="mr-3 text-indigo-600 hover:text-indigo-900">
                                                        Edit
                                                    </button>
                                                    <button @click="delete_areyousure(company)" class="text-red-600 hover:text-red-900">
                                                        Delete
                                                    </button>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>                    
                </div>
            </div>
        </div>
        <message-modal  :show_message="show_message"
                        :title="'Upload completed'"
                        :message="'The upload has completed successfully'"
                        :next_route="'adminVideos'"
        ></message-modal>
        <editDMCACompanyVue :open="edit_open"
                            :mode="edit_mode"
                            :company="edit_company"
                            @close="close_edit"
        ></editDMCACompanyVue>
    </admin-layout>
</template>
<script>
    import axios from "axios";
    import {getStorage} from "@/saveStorage";
    import AdminLayout from "@/layouts/adminLayout.vue";
    import {Switch, SwitchGroup, SwitchLabel} from '@headlessui/vue'
    import {
        DocumentTextIcon
    } from "@heroicons/vue/solid";
    import MessageModal from '@/modals/message.vue'
    import editDMCACompanyVue from "./modals/editDMCACompany.vue";

    export default {
        components: {
            MessageModal,
            AdminLayout,
            DocumentTextIcon,
            Switch,
            SwitchGroup,
            SwitchLabel,
            editDMCACompanyVue
        },
  
        data() {
            return {
                api_url:      process.env.VUE_APP_API_URL,
                companies:    [],
                pagination:   null,
                search:       null,

                show_message: false,
                edit_open:    false,
                edit_company: null,
            };
        },
  
        watch: {
        },

        computed: {
        },
      
        created() {
            this.token  = getStorage('token');
            this.search = this.$route.query.search;

            this.getAllCompanies();
        },
    
        mounted() {
            if (this.$route.query.add) {
                this.open_edit();
            }
        },
  
        methods: {
            open_edit(company) {
                if (company == null) {
                    this.edit_company = {
                        'name':            null,
                        'email':           null,
                        'web':             null,
                        'has_firewall':    false,
                        'host':            null,
                        'host_email':      null,
                        'isp':             null,
                        'isp_email':       null,
                    }
                    this.edit_mode    = 'Add';
                }
                else {
                    this.edit_company = company;
                    this.edit_mode    = 'Edit';
                }
                this.edit_open = true;
            },

            close_edit() {
                this.edit_company = null;
                this.edit_open    = false;
                
                this.getCompanies(this.pagination.page);
            },

            getAllCompanies() {
                this.getCompanies(1);
            },
            getPrevPage() {
                this.getCompanies(this.pagination.page - 1);
            },
            getNextPage() {
                this.getCompanies(this.pagination.page + 1);
            },

            async getCompanies(page) {
                const config = {
                    headers: { Authorization: `Bearer ` + this.token }
                };

                var url = this.api_url + '/api/admin/companies';
                    url = url + '?q=' + (this.search || '');
                    url = url + '&per_page=8&page=' + page;

                await axios.get(url, config).then(response => {
                    this.companies  = response.data.records
                    this.pagination = response.data.pagination
                    this.search     = response.data.search.q
                })
            },

            delete_areyousure(company) {
                let _self = this;
                this.$swal({
                    title:            'Are you sure?',
                    html:             '<b>This operation cannot be undone.</b><br><br> \
                                       Click "yes" to confirm that <br> \
                                       you wish to DELETE this company.<br><br> \
                                       If not, click "No" to cancel.',

                    showCancelButton:   true,
                    allowEscapeKey:     false,
                    allowOutsideClick:  false,

                    confirmButtonColor: '#4f46e5',
                    cancelButtonColor:  '#dc2626',
                    confirmButtonText:  'No',
                    cancelButtonText:   'Yes',
                    icon:               'question',
                    background:         '#3f3f46',
                }).then(function(response) {
                    // NOTE: confirm and cancel have been swapped so that the default is to NOT delete
                    if (response && response.isConfirmed == true) {
                        // do nothing
                    } 
                    else {
                        _self.delete(company);
                    }
                });
            },

            delete(company) {
                const config = {
                    headers: { Authorization: `Bearer ` + this.token }
                };

                var url = this.api_url + '/api/admin/companies/' + company.id + '/delete';

                var data = {
                    "company_id": this.company,
                }

                axios.post(url, data, config)
                .then((response) => {
                    this.getCompanies(this.pagination.page);
                    this.$swal({
                        title:              'Company Deleted',
                        confirmButtonColor: '#be185d',
                        confirmButtonText:  'Close',
                        icon:               'success',
                        background:         '#3f3f46',
                    });
                })
                .catch(error => {
                    var error_msg     = '';
                    if (error.response.data.message) {
                        error_msg = error.response.data.message;	
                    }
                    this.$swal({
                        title:              'Company was not Deleted',
                        html:               error_msg,
                        confirmButtonColor: '#be185d',
                        confirmButtonText:  'Close',
                        icon:               'success',
                        background:         '#3f3f46',
                    });
                });
            },
        },
    };
</script>
  
<style>
</style>