<template>
  <admin-form-layout>
    <div>
      <div class="space-y-8 divide-y divide-gray-200">
        <div class="space-y-8 divide-y divide-gray-200 sm:space-y-5">
          <div>
            <div>
              <h3 class="text-lg leading-6 font-medium text-gray-900">
                Video edit
              </h3>
            </div>
            <div class="mt-6 sm:mt-5 space-y-6 sm:space-y-5">
              <!-- Image title -->
              <div
                class="
                  sm:grid
                  sm:grid-cols-3
                  sm:gap-4
                  sm:items-start
                  sm:border-t
                  sm:border-gray-200
                  sm:pt-5
                "
              >
                <label
                  for="title"
                  class="
                    block
                    text-sm
                    font-medium
                    text-gray-700
                    sm:mt-px sm:pt-2
                  "
                >
                  Title
                </label>
                <div class="mt-1 sm:mt-0 sm:col-span-2">
                  <div class="max-w-lg flex rounded-md shadow-sm">
                    <input
                      v-model="video.title"
                      type="text"
                      name="title"
                      id="title"
                      autocomplete="title"
                      class="
                        flex-1
                        block
                        w-full
                        focus:ring-indigo-500 focus:border-indigo-500
                        min-w-0
                        rounded-none rounded-r-md
                        sm:text-sm
                        border-gray-300
                      "
                    />
                  </div>
                </div>
              </div>
              <!-- Image title end -->

              <!-- Description -->
              <div
                  class="
                  sm:grid
                  sm:grid-cols-3
                  sm:gap-4
                  sm:items-start
                  sm:border-t
                  sm:border-gray-200
                  sm:pt-5
                "
              >
                <label
                    for="description"
                    class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                >
                  Description
                </label>
                <div class="mt-1 sm:mt-0 sm:col-span-2">
                  <div class="max-w-lg flex rounded-md shadow-sm">
                    <div class="flex-1 block w-full p-3 bg-white
                                min-w-0
                                rounded-none rounded-r-md
                                sm:text-sm
                                border-gray-300
                                focus:ring-indigo-500 focus:border-indigo-500
                                h-64 overflow-scroll overflow-x-hidden	">
                      <editor v-model="video.description" />
                    </div>
                  </div>
                </div>
              </div>
              <!-- Description end -->

              <div
                  class="
                  sm:grid
                  sm:grid-cols-3
                  sm:gap-4
                  sm:items-start
                  sm:border-t
                  sm:border-gray-200
                  sm:pt-5
                "
              >
                <SwitchGroup as="div" class="flex items-center">
                  <Switch v-model="video.is_members_only"
                          :class="[video.is_members_only ? 'bg-indigo-600' : 'bg-gray-200', 'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500']">
                    <span aria-hidden="true"
                          :class="[video.is_members_only ? 'translate-x-5' : 'translate-x-0', 'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200']"/>
                  </Switch>
                  <SwitchLabel as="span" class="ml-3">
                    <span class="text-sm font-medium text-gray-900">Membership Only</span>
                    <span class="text-sm text-gray-500"> - Will only be available to members section</span>
                  </SwitchLabel>
                </SwitchGroup>
              </div>
  
              <div
                  class="
                  sm:grid
                  sm:grid-cols-3
                  sm:gap-4
                  sm:items-start
                  sm:border-t
                  sm:border-gray-200
                  sm:pt-5
                "
              >
                <SwitchGroup as="div" class="flex items-center">
                  <Switch v-model="video.is_preview"
                          :class="[video.is_preview ? 'bg-indigo-600' : 'bg-gray-200', 'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500']">
                    <span aria-hidden="true"
                          :class="[video.is_preview ? 'translate-x-5' : 'translate-x-0', 'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200']"/>
                  </Switch>
                  <SwitchLabel as="span" class="ml-3">
                    <span class="text-sm font-medium text-gray-900">Preview available</span>
                    <span class="text-sm text-gray-500"> - Available to show to non members</span>
                  </SwitchLabel>
                </SwitchGroup>
              </div>
  

                <!-- Price -->
              <div
                  class="
                  sm:grid
                  sm:grid-cols-3
                  sm:gap-4
                  sm:items-start
                  sm:border-t
                  sm:border-gray-200
                  sm:pt-5
                "

              >
                <label
                    for="price"
                    class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                >
                  Price
                </label>
                <div class="mt-1 sm:mt-0 sm:col-span-2">
                  <div class="max-w-lg flex rounded-md shadow-sm">
                    <input
                        v-model="video.price"
                        type="text"
                        name="price"
                        id="price"
                        autocomplete="title"
                        class="
                        flex-1
                        block
                        w-full
                        focus:ring-indigo-500 focus:border-indigo-500
                        min-w-0
                        rounded-none rounded-r-md
                        sm:text-sm
                        border-gray-300
                      "
                    />
                  </div>
                  <p class="mt-2 text-sm text-gray-500" id="email-description">Any video set to 0 will not show in the store</p>
                </div>
              </div>

              <!-- Price end -->


            </div>
          </div>
        </div>
      </div>
      <div class="pt-5">
          <div class="flex justify-end">
            <button
                type="submit"
                @click="delete_areyousure"
                class="
                ml-3
                inline-flex
                justify-center
                py-2
                px-4
                border border-transparent
                shadow-sm
                text-sm
                font-medium
                rounded-md
                text-white
                bg-red-600
                hover:bg-red-700
                disabled:opacity-50
              "
            >
              Delete
            </button>
            <button
              type="submit"
              @click="submitFile"
              class="
                ml-3
                inline-flex
                justify-center
                py-2
                px-4
                border border-transparent
                shadow-sm
                text-sm
                font-medium
                rounded-md
                text-white
                bg-indigo-600
                hover:bg-indigo-700
                disabled:opacity-50
              "
            >
              Update
            </button>
          </div>
        </div>
    </div>
  </admin-form-layout>
</template>
<script>
import { getStorage } from "@/saveStorage";
import AdminFormLayout from "@/layouts/adminFormLayout.vue";
import axios from "axios";
import Editor from "../reuseable/editor"
export default {
  components: {
    AdminFormLayout,
    Editor
  },
  data() {
    return {
      api_url: process.env.VUE_APP_API_URL,
      auth: {},
      video: {},
      id: 0,
    };
  },

  computed: {
    got_option() {
        return (this.video.is_member_only == true  ||  this.video.is_preview == true)
    },
  },

  methods: {
    async getVideo() {
      const config = {
        headers: {
          Authorization:
            `Bearer ` + this.auth.token.replace(/^"(.+(?="$))"$/, "$1"),
        },
        maxContentLength: Infinity,
        maxBodyLength: Infinity,
      };
      await axios
        .get(
            this.api_url + '/api/admin/videos/' + this.id +'/edit',
          config
        )
        .then((response) => {
          this.video = response.data
        });
    },
    submitFile() {
      const config = {
        headers: {
          Authorization:
              `Bearer ` + this.auth.token.replace(/^"(.+(?="$))"$/, "$1"),
        },
        maxContentLength: Infinity,
        maxBodyLength: Infinity,
      };
      axios
          .post(
              this.api_url + '/api/admin/videos/' + this.id +'/edit',
              this.video,
              config,

          )
          .then((response) => {
            this.$swal({
              title:              'Video Updated',
              confirmButtonColor: '#be185d',
              confirmButtonText:  'Close',
              icon:               'success',
              background:         '#3f3f46',
            })
          });
    },

    delete_areyousure() {
        let _self = this;
        this.$swal({
              title:            'Are you sure?',
              html:             '<b>This operation cannot be undone.</b><br><br> \
                                 You can un-tick the "show" check-box to hide the video instead.<br><br> \
                                 If you are sure you want to delete, then click "Yes", otherwise click "No".',

              showCancelButton:   true,
              allowEscapeKey:     false,
              allowOutsideClick:  false,

              confirmButtonColor: '#4f46e5',
              cancelButtonColor:  '#dc2626',
              confirmButtonText:  'No',
              cancelButtonText:   'Yes',
              icon:               'question',
              background:         '#3f3f46',
        }).then(function(response) {
            // NOTE: confirm and cancel have been swapped so that the default is to NOT delete
            if (response && response.isConfirmed == true) {
                _self.dont_delete_file();
            } 
            else {
                _self.delete_file();
            }
        });
    },

    delete_file() {
      const config = {
        headers: {
          Authorization:
              `Bearer ` + this.auth.token.replace(/^"(.+(?="$))"$/, "$1"),
        },
        maxContentLength: Infinity,
        maxBodyLength: Infinity,
      };
      axios
          .post(
              this.api_url + '/api/admin/videos/' + this.id +'/delete',
              this.video,
              config,

          )
          .then((response) => {
            this.$swal({
              title:              'Video deleted',
              confirmButtonColor: '#be185d',
              confirmButtonText:  'Close',
              icon:               'success',
              background:         '#3f3f46',
            })
            this.$router.push({name: 'adminVideos'})
          });
    },

    dont_delete_file() {
        this.$swal({
            title:              'Video was not deleted',
            confirmButtonColor: '#be185d',
            confirmButtonText:  'Close',
            icon:               'success',
            background:         '#3f3f46',
        });
    },
  },
  created() {
    this.auth = getStorage("auth");
    this.id = this.$route.query.id;
    this.getVideo();
  },
};
</script>