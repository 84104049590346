<template>
  <ccbill-form-layout>
    <div class="relative px-4 py-2 w-100">
      <div class="mx-auto w-100 px-4 pt-2 pb-2">
        <div class="mx-auto" style="width:80%;">
          <div class="flex justify-between">
            <div>
              <h1 class="mb-2 text-3xl font-bold tracking-tight text-white sm:text-4xl">Order Complete</h1>
            </div>

          </div>

          <ul role="list" class="mb-3 w-full divide-y divide-gray-200 border-t border-b border-gray-200">
            <li v-for="video in shoppingCart" :key="video.id" class="w-full py-2 sm:py-2">
              <div class="flex justify-between">
                <div class="text-sm text-gray-400">
                  {{ video.title }}
                </div>
                <div class="ml-4 text-sm text-white">
                  ${{ parseFloat(video.price).toFixed(2) }}
                </div>
              </div>
            </li>
          </ul>
          <div
               class="relative px-4 py-2 mb-2">
            <div class="text-base font-medium text-white">
              Your payment was successful. Click here to See your <a href="/member/home"
                                                                     class="hover:bg-pink-900 w-full rounded-md border border-transparent bg-pink-700 py-3 px-4 text-base font-medium text-white shadow-sm"
            >Purchases</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ccbill-form-layout>
</template>


<script>
import md5            from 'crypto-js/md5';
import axios          from "axios";
import { CheckIcon, ClockIcon, QuestionMarkCircleIcon } from '@heroicons/vue/solid'
import BrochureLayout from "@/layouts/BrochureLayout";
import ccbillFormLayout from "@/layouts/ccbillFormLayout.vue";
//import storeMenu      from "@/ecommerce/storeMenu"
import { getStorage, saveStorage } from "@/saveStorage";

export default {
    components: {
        CheckIcon, ClockIcon, QuestionMarkCircleIcon,
        // storeMenu,
        // BrochureLayout
        ccbillFormLayout
    },

    data() {

    },

    computed: {

    },

    created() {

    },

    mounted() {
    },

  methods: {
  }

}
</script>