<template>
  <div class="relative bg-pink-900">
    <div class="h-80 absolute inset-x-0 bottom-0 xl:top-0 xl:h-full">
      <div class="h-full w-full xl:grid xl:grid-cols-2">
        <div class="h-full xl:relative xl:col-start-2">
          <img class="h-full w-full object-cover opacity-25 xl:absolute xl:inset-0" src="@/assets/background_grey.jpg"
               alt="People working on laptops"/>
          <div aria-hidden="true"
               class="absolute inset-x-0 top-0 h-32 bg-gradient-to-b from-pink-900 xl:inset-y-0 xl:left-0 xl:h-full xl:w-32 xl:bg-gradient-to-r"/>
        </div>
      </div>
    </div>
    <div
        class="max-w-4xl mx-auto px-4 sm:px-6 lg:max-w-7xl lg:px-8 xl:grid xl:grid-cols-2 xl:grid-flow-col-dense xl:gap-x-8">
      <div class="relative pt-12 pb-64 sm:pt-24 sm:pb-64 xl:col-start-1 xl:pb-24">
        <p class="mt-3 text-3xl font-extrabold text-white">Membership</p>
        <p class="mt-5 text-lg text-gray-300">Becoming a Membership Subscriber to BorderlandBound.com grants you immediate access to 1000s of Images and Video Clips featuring the most beautiful girls, all tied up and gagged</p>
        <p class="mt-5 text-lg text-gray-300">Join for 1 month at $29.95, recurring, or 3 months at $59.95, recurring.</p>
        <div class="mt-12 grid grid-cols-1 gap-y-12 gap-x-6 sm:grid-cols-2">
          <p v-for="item in metrics" :key="item.id">
            <span class="block text-2xl font-bold text-white">{{ item.stat }}</span>
            <span class="mt-1 block text-base text-gray-300"
            ><span class="font-medium text-white">{{ item.emphasis }}</span> {{ item.rest }}</span
            >
          </p>
          <a href="/member/signup" v-if="!loggedUser" class="inline-flex bg-gradient-to-r from-white to-white bg-origin-border px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-pink-700 justify-center w-full">Join Now</a>
          <a href="/member/signup" v-else class="inline-flex bg-gradient-to-r from-white to-white bg-origin-border px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-pink-700 justify-center w-full">Members' Area</a>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import {getStorage, saveStorage} from "@/saveStorage";
export default {
  data() {
    return {
      api_url: process.env.VUE_APP_API_URL,
      metrics: [
        {id: 1, stat: 200, emphasis: 'Videos', rest: 'in our members area'},
        {id: 2, stat: 2000, emphasis: 'Photosets', rest: 'in our members area'},
      ],
      videos: 0
    }
  },
  methods: {
    getLatestStats() {
      axios.get(this.api_url + '/api/status').then(response => {
        this.metrics[0].stat = response.data.counts.videos
        this.metrics[1].stat = response.data.counts.photosets
        saveStorage('counts', response.data.counts)
      })
    }
  },
  created() {
    this.getLatestStats()
  },
  computed:{
    loggedUser() {
      if(getStorage('LoggedUser')) {
        return JSON.parse(getStorage('LoggedUser'))
      } else {
        return false
      }
    },
  }
}
</script>