<template>
    <form-layout>
        <div class="relative px-4 py-16 sm:px-6 sm:py-24 lg:py-32 lg:px-8">
            <div class="sm:mx-auto sm:w-full sm:max-w-md">
                <img  class="mx-auto h-24 w-auto"  src="@/assets/logo.png"  />
                <p class="mt-2 text-center text-sm text-white">&nbsp;Reset Password</p>
            </div>
            <div class="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
                <div class="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
                    <div class="space-y-6">
                        <div v-if="form_sent == false">
                            <label for="password" class="block text-sm font-medium text-gray-700">Password</label>
                            <div class="mt-1">
                                <input  type="password"  name="password"  id="password"
                                        v-model="formData.password"
                                        class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block
                                               w-full sm:text-sm border-gray-300 rounded-md"
                                        placeholder="Must be a minimum of six characters" />
                                <span class="font-small text-pink-800">{{ errors['password'] }}</span>
                            </div>
                        </div>

                        <div v-if="form_sent == false">
                            <label for="c_password" class="block text-sm font-medium text-gray-700">Confirm Password</label>
                            <div class="mt-1">
                                <input  type="password"  name="confirmPassword"  id="confirmPassword"
                                        v-model="formData.c_password"
                                        class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block
                                               w-full sm:text-sm border-gray-300 rounded-md"
                                        placeholder="Must match your password" />
                                <span class="font-small text-pink-800">{{ errors['c_password'] }}</span>
                            </div>
                        </div>


                        <!-- Message -->
                        <div v-if="form_sent == false" 
                             class="flex items-center justify-between">
                            <div class="flex items-center">
                                <div class="text-sm">
                                    <div class="font-medium text-grey-500">
                                        Please enter your new password.
                                    </div>
                                </div>
                            </div>
                        </div>


                        <!-- Submit Button -->
                        <div v-if="form_sent == false">
                            <button  type="submit"
                                    @click="submitForm"
                                    class="w-full flex justify-center py-2 px-4 border border-transparent rounded-md
                                        shadow-sm text-sm font-medium text-white bg-pink-700 
                                        hover:bg-pink-900
                                        focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                                Continue
                            </button>
                        </div>
                        <div v-else
                             class="mt-2 font-medium text-grey-500">
                            <b>Password Reset</b><br>
                            You may now login using your new password.
                            <br>
                            <br>
                            <a href="/"
                               class="w-full flex justify-center py-2 px-4 border border-transparent rounded-md
                                      shadow-sm text-sm font-medium text-white bg-pink-700 
                                      hover:bg-pink-900
                                      focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                                Home
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </form-layout>
</template>

<script>
import { useStore }    from "vuex";
import { useRoute }    from "vue-router";
import alertError      from '@/alerts/error.vue'
import axios           from "axios";
import formLayout      from '@/layouts/formLayout'

export default {
    components: {
        alertError,
        formLayout
    },

    data() {
        return {
            api_url:        process.env.VUE_APP_API_URL,
            store:          useStore(),
            route:          useRoute(),
            formData: {
                token:      '',
                password:   '',
                c_password: ''
            },
            form_sent:      false,
            errors:         [],
        };
    },

    mounted() {
        this.formData.token = this.route.params.token;
    },

    methods: {
        submitForm() {
            this.errors = [];

            axios.post(this.api_url + '/api/reset', this.formData)
            .then(response => {
                this.error     = false;
                this.form_sent = true;
            })
            .catch(error => {
                var error_msg     = '';
                var error_details = '';
                if (error.response.data.message) {
                    error_msg = error.response.data.message;	
                }
        
                if (error.response.data.errors) {
                    Object.keys(error.response.data.errors).forEach(key => {
                        error_details = error_details + error.response.data.errors[key].toString() + "<br>";

                        // load api errors into client errors
                        this.errors[key] = error.response.data.errors[key].toString();
                    });
                }

                this.$swal({
                    title:              error_msg,
                    html:               error_details,
                    confirmButtonColor: '#be185d',
                    confirmButtonText:  'Close',
                    icon:               'error',
                    background:         '#3f3f46',
                });
            });
        }
    }
};
</script>

<style>
</style>