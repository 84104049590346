<template>
    <TransitionRoot as="template" :show="open">
        <Dialog as="div" class="relative z-10" @close="$parent.edit_open = false;  open = false">
            <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
                <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </TransitionChild>
  
            <div class="fixed inset-0 z-10 overflow-y-auto">
                <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                    <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200" leave-from="opacity-100 translate-y-0 sm:scale-100" leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
                        <DialogPanel class="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all w-4/5">
                            <div>

                                <DialogTitle as="h3" class="p-3 rounded-lg bg-gray-300 bg-opacity-75 text-lg font-medium leading-6 text-gray-900 mb-4">DMCA Notice</DialogTitle>
                                <div v-if="open == true">

                                    <div class="mt-6 sm:mt-5 space-y-6 sm:space-y-5">
                                        <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                                            <div class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                                                {{ notice.type }}
                                            </div>
                                            <div class="mt-1 sm:mt-0 sm:col-span-2">
                                                {{ sent_formatted }}
                                            </div>
                                        </div>
                                    </div>

                                    <div class="mt-6 sm:mt-5 space-y-6 sm:space-y-5">
                                        <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                                            <div class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                                                Content Removed
                                            </div>
                                            <div class="mt-1 sm:mt-0 sm:col-span-2">
                                                {{ (notice.is_content_removed == true) ? 'Yes' : 'No' }}
                                            </div>
                                        </div>
                                    </div>

                                    <div class="mt-6 sm:mt-5 space-y-6 sm:space-y-5">
                                        <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                                            <div class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                                                Company
                                            </div>
                                            <div class="mt-1 sm:mt-0 sm:col-span-2">
                                                {{ notice.company_name }}<br>{{ notice.company_web }}
                                            </div>
                                        </div>
                                    </div>

                                    <div class="mt-6 sm:mt-5 space-y-6 sm:space-y-5">
                                        <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                                            <div class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                                                Firewall
                                            </div>
                                            <div class="mt-1 sm:mt-0 sm:col-span-2">
                                                {{ (notice.company_has_firewall == true) ? 'Yes' : 'No' }}
                                            </div>
                                        </div>
                                    </div>

                                    <div class="mt-6 sm:mt-5 space-y-6 sm:space-y-5">
                                        <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                                            <div class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                                                Host
                                            </div>
                                            <div class="mt-1 sm:mt-0 sm:col-span-2">
                                                <b>{{ notice.company_host }}</b> {{ notice.company_host_email }}
                                            </div>
                                        </div>
                                    </div>

                                    <div class="mt-6 sm:mt-5 space-y-6 sm:space-y-5">
                                        <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                                            <div class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                                                ISP
                                            </div>
                                            <div class="mt-1 sm:mt-0 sm:col-span-2">
                                                <b>{{ notice.company_isp }}</b> {{ notice.company_isp_email }}
                                            </div>
                                        </div>
                                    </div>

                                    <div class="mt-6 sm:mt-5 space-y-6 sm:space-y-5">
                                        <!-- Image title -->
                                        <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                                            <label for="links" class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                                                Links
                                            </label>
                                            <div class="mt-1 sm:mt-0 sm:col-span-2">
                                                <ul>
                                                    <li v-for="link in notice.links" :key="link">{{ link }}</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div class="mt-5 sm:mt-6">
                                <button type="submit"  
                                        @click="submit"
                                        class="ml-3 inline-flex justify-center py-2 px-4
                                                border border-transparent
                                                shadow-sm font-medium rounded-md
                                                text-white
                                                bg-indigo-600 
                                                hover:bg-indigo-700
                                                disabled:opacity-50"
                                        style="float:right;">
                                    OK
                                </button>
                            </div>
                        </DialogPanel>
                    </TransitionChild>
                </div>
            </div>
        </Dialog>
    </TransitionRoot>

</template>
<script>
    import moment from 'moment';
    import { Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue'
  
    export default {
        props: [
            'open', 
            'notice',
        ],

        components: {
            Dialog,
            DialogPanel,
            DialogTitle,
            TransitionRoot,
            TransitionChild
        },
  
        data() {
            return {
                api_url:     process.env.VUE_APP_API_URL,
                dmca_notice: null,
            };
        },
  
        watch: {},
        computed: {
            sent_formatted() {
                if (this.notice) {
                    var sent_at = (this.dmca_notice.is_content_removed == true) ? this.dmca_notice.content_removed_at : this.dmca_notice.sent_at;

                    return moment(sent_at).format('Do MMMM YYYY, hh:mm:ss');
                }
            }
        },
      
        created() {
        },
    
        mounted() {
        },
  
        methods: {
            async submit() {
                this.$parent.edit_open = false;
                this.$emit('close');
            }
        },
    };
</script>
  
<style>
</style>