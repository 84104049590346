<template>
  <brochure-layout>
    <store-menu></store-menu>
  <div class="bg-zinc-900">
    <div class="pt-6 pb-16 sm:pb-24">
      <div class="mt-8 max-w-2xl mx-auto px-4 sm:px-6 lg:max-w-7xl lg:px-8">
        <div class="lg:grid lg:grid-cols-12 lg:auto-rows-min lg:gap-x-8">
          <div class="lg:col-start-8 lg:col-span-5">
            <div class="flex justify-between">
              <h1 class="text-xl font-medium text-pink-700">
                {{ video.title }}
              </h1>
              <p class="text-xl font-medium text-pink-700">
                £{{ video.price }}
              </p>
            </div>
            <!-- Reviews -->
          </div>

          <!-- Image gallery -->
          <div class="mt-8 lg:mt-0 lg:col-start-1 lg:col-span-7 lg:row-start-1 lg:row-span-3">
            <h2 class="sr-only">Images</h2>

            <div class="grid grid-cols-1 lg:grid-rows-1 lg:gap-8">
              <img :src="video.cover_url" :alt="video.title" class="w-full"  />
            </div>
          </div>

          <div class="mt-8 lg:col-span-5">

              <a v-if="1==0 && video.download_url" 
                      class="mt-8 w-full bg-pink-700 border border-transparent rounded-md py-3 px-8 flex
                             items-center justify-center text-base font-medium text-white hover:bg-pink-900 
                             focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                      :href="video.download_url" 
                      type="button" 
              >DOWNLOAD</a>
              <button v-if="addedToCart === false" class="mt-8 w-full bg-pink-700 border border-transparent rounded-md py-3 px-8 flex
              items-center justify-center text-base font-medium text-white hover:bg-pink-900 focus:outline-none
              focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              @click="addToCart(video.title, video.id, video.cover_url, video.price)"
              >Add to cart</button>
              <button v-else class="mt-8 w-full bg-pink-700 border border-transparent rounded-md py-3 px-8 flex
                items-center justify-center text-base font-medium text-white hover:bg-pink-900 focus:outline-none
                focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                disabled
              >Added</button>


            <!-- Product details -->
            <div class="mt-10">
              <h2 class="text-sm font-medium text-white">Description</h2>

              <div class="mt-4 prose prose-sm text-white" v-html="video.description" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
    </brochure-layout>
</template>

<script>
import storeMenu from "@/ecommerce/storeMenu"
import { StarIcon } from '@heroicons/vue/solid'
import { RadioGroup, RadioGroupLabel, RadioGroupOption } from '@headlessui/vue'
import { CurrencyDollarIcon, GlobeIcon } from '@heroicons/vue/outline'
import BrochureLayout from "@/layouts/BrochureLayout";
import {getStorage, saveStorage} from "@/saveStorage";
import axios from "axios";
export default {
  components: {
      storeMenu, StarIcon, RadioGroup, RadioGroupLabel, RadioGroupOption, CurrencyDollarIcon, GlobeIcon, BrochureLayout, 
  },

  data() {
    return {
      api_url:   process.env.VUE_APP_API_URL,
      video:     {},
      videosPag: {},

      id:          '',
      cart:        [],
      addedToCart: false,

      total_price: 0,
    }
  },

  created() {
    this.id   = this.$route.query.id

    this.auth = getStorage('auth')
    if (!this.auth) {
        this.auth = {
            'token': ''
        }
    }

    this.cart = getStorage('cart')
    this.getListing()

    // Already added?
    let added_check = this.cart.filter(e => e.id == this.id)
    if (added_check  &&  added_check.length > 0) {
        this.addedToCart = true
    }
  },

  methods: {
    addToCart(title, id, image, price) {
        var total_price = this.calcCartTotal();
        if ((parseFloat(total_price) + parseFloat(price)) > 100) {
            console.log('is > 100');
            this.$swal({
                title:              '',
                html:               'Item not added. <br>Your shopping cart total cannot be over $100.',
                confirmButtonColor: '#be185d',
                confirmButtonText:  'Ok',
                icon:               'success',
                background:         '#3f3f46',
            });
        }
        else {
            const item = {
                'title': title,
                'id': id,
                'image': image,
                'price': price
            }
            this.cart.push(item);
            saveStorage('cart', this.cart);
            this.addedToCart = true
        }
    },

    calcCartTotal() {
        var shopping_cart = getStorage('cart');
        var total_price   = 0;

        if (shopping_cart  &&  shopping_cart.length > 0) {
            shopping_cart.forEach(function (item) {
                total_price = parseFloat(total_price) + parseFloat(item.price);
            });
        }

        total_price = parseFloat(total_price).toFixed(2);

        return total_price;
    },

    getListing() {
      const config = {
        headers: { Authorization: `Bearer ` + this.auth.token.replace(/^"(.+(?="$))"$/, '$1') }
      };

      axios.get(this.api_url + '/api/videos/' + this.id + '/shop', config).then(response => {
        this.video = response.data.records[0];
      })
    },
  },
}
</script>