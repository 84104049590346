<!-- This example requires Tailwind CSS v2.0+ -->
<template>
  <TransitionRoot as="template" :show="open">
    <Dialog as="div" class="relative z-10" @close="open = false">
      <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
        <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
      </TransitionChild>

      <div class="fixed z-10 inset-0 overflow-y-auto">
        <div class="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
          <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200" leave-from="opacity-100 translate-y-0 sm:scale-100" leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
            <DialogPanel class="relative bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-lg sm:w-full sm:p-6">
              <div>
                <div class="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-red-100">
                  <ExclamationIcon class="h-6 w-6 text-red-600" aria-hidden="true" />
                </div>
                <div class="mt-3 text-center sm:mt-5">
                  <DialogTitle as="h3" class="text-lg leading-6 font-medium text-gray-900"> Attention </DialogTitle>
                  <div class="mt-2">
                    <p class="text-sm text-gray-500 font-bold pb-2">
                      This Website contains sexually-oriented adult content which may include visual
                      images and verbal descriptions of nude adults, adults engaging in sexual acts, and other audio and visual material
                      of a sexually-explicit nature. Permission to enter this Website and to view and download its contents is strictly
                      limited to consenting adults who affirm that the following conditions apply:
                    </p>
                    <ol class="list-decimal">
                      <li class="text-sm text-gray-500 pb-2">That you are 18 years of age or older (21-years old where 18 is not the age of majority),and that you are voluntarily choosing to view and access such sexually-explicit images and content for your own personal use.</li>
                      <li class="text-sm text-gray-500 pb-2">That you intend to view the sexually-explicit material in the privacy of your home, or in a place where there are no other persons viewing this material who are either minors, or who may be offended by viewing such material.</li>
                      <li class="text-sm text-gray-500 pb-2">That you are familiar with your local community standards and that the sexually-explicit materials which you have chosen to view and/or download from this Website are well within the contemporary community standards of acceptance and tolerance of your community for sexually-explicit material.</li>
                    </ol>
                    <p class="text-sm text-gray-500">
                      If all of these conditions apply to you, you are permitted to enter. If any of these conditions do not apply to you, you are forbidden from entering and viewing the contents of this Website and you should now leave.
                    </p>
                  </div>
                </div>
              </div>
              <div class="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
                <button type="button" class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-pink-700 text-base font-medium text-white hover:bg-pink-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pink-700 sm:col-start-2 sm:text-sm" @click="submit()">I Understand</button>
                <button type="button" class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:col-start-1 sm:text-sm" @click="open = false" ref="cancelButtonRef">Leave</button>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script>
import { Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue'
import { ExclamationIcon } from '@heroicons/vue/outline'

export default {
  props: ['open'],
  components: {
    Dialog,
    DialogPanel,
    DialogTitle,
    TransitionRoot,
    TransitionChild,
    ExclamationIcon
  },
  methods: {
    submit() {
      this.$router.push({name: 'homePage'})
    }
  }
}
</script>