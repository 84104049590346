<template>
    <TransitionRoot as="template" :show="open">
        <Dialog as="div" class="relative z-10" @close="$parent.edit_open = false;  open = false">
            <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
                <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </TransitionChild>
  
            <div class="fixed inset-0 z-10 overflow-y-auto">
                <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                    <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200" leave-from="opacity-100 translate-y-0 sm:scale-100" leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
                        <DialogPanel class="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all w-4/5">
                            <div>
                                <DialogTitle as="h3" class="p-3 rounded-lg bg-gray-300 bg-opacity-75 text-lg font-medium leading-6 text-gray-900 mb-4">{{ mode_title }} Company</DialogTitle>
                                <div v-if="open == true"
                                     class="grid grid-cols-2 gap-2 mt-3 sm:mt-5">
                                    <div class="mb-2">
                                        <label for="name" class="block text-sm font-medium text-gray-700">Company Name</label>
                                        <div class="mt-1">
                                            <input type="text" name="name" id="name" 
                                                    v-model="company.name"
                                                    class="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm" />
                                            <span class="font-small text-sm text-pink-800">{{ errors['name'] }}</span>
                                        </div>
                                    </div>
                                    <div class="mb-2">
                                        <label for="email" class="block text-sm font-medium text-gray-700">Company Email</label>
                                        <div class="mt-1">
                                            <input type="email" name="email" id="email" 
                                                    v-model="company.email"
                                                    class="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm" />
                                            <span class="font-small text-sm text-pink-800">{{ errors['email'] }}</span>
                                        </div>
                                    </div>
                                    <div class="mb-2">
                                        <label for="web" class="block text-sm font-medium text-gray-700">Website</label>
                                        <div class="mt-1">
                                            <input type="text" name="web" id="web" 
                                                    v-model="company.web"
                                                    class="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm" />
                                            <span class="font-small text-sm text-pink-800">{{ errors['web'] }}</span>
                                        </div>
                                    </div>
                                    <div class="mb-2 pt-4">
                                        <SwitchGroup as="div" class="flex items-center">
                                            <Switch v-model="company.has_firewall"
                                                    name="has_firewall" id="has_firewall" 
                                                    :class="[company.has_firewall ? 'bg-indigo-600' : 'bg-gray-200', 'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500']">
                                                <span aria-hidden="true"
                                                    :class="[company.has_firewall ? 'translate-x-5' : 'translate-x-0', 'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200']"/>
                                            </Switch>
                                            <SwitchLabel as="span" class="ml-3">
                                                <span class="text-sm font-medium text-gray-700">Has Firewall</span>
                                            </SwitchLabel>
                                        </SwitchGroup>
                                    </div>
                                    <div class="col-span-2"><hr></div>
                                    <div class="mb-2">
                                        <label for="host" class="block text-sm font-medium text-gray-700">Host Name</label>
                                        <div class="mt-1">
                                            <input type="text" name="host" id="host" 
                                                    v-model="company.host"
                                                    class="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm" />
                                            <span class="font-small text-sm text-pink-800">{{ errors['host'] }}</span>
                                        </div>
                                    </div>
                                    <div class="mb-2">
                                        <label for="host_email" class="block text-sm font-medium text-gray-700">Host Email</label>
                                        <div class="mt-1">
                                            <input type="email" name="host_email" id="host_email" 
                                                    v-model="company.host_email"
                                                    class="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm" />
                                            <span class="font-small text-sm text-pink-800">{{ errors['host_email'] }}</span>
                                        </div>
                                    </div>
                                    <div class="mb-2">
                                        <label for="isp" class="block text-sm font-medium text-gray-700">ISP Name</label>
                                        <div class="mt-1">
                                            <input type="text" name="isp" id="isp" 
                                                    v-model="company.isp"
                                                    class="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm" />
                                            <span class="font-small text-sm text-pink-800">{{ errors['isp'] }}</span>
                                        </div>
                                    </div>
                                    <div class="mb-2">
                                        <label for="isp_email" class="block text-sm font-medium text-gray-700">ISP Email</label>
                                        <div class="mt-1">
                                            <input type="email" name="isp_email" id="isp_email" 
                                                    v-model="company.isp_email"
                                                    class="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm" />
                                            <span class="font-small text-sm text-pink-800">{{ errors['isp_email'] }}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="mt-5 sm:mt-6">
                                <button type="submit"  
                                        @click="submit"
                                        class="ml-3 inline-flex justify-center py-2 px-4
                                                border border-transparent
                                                shadow-sm font-medium rounded-md
                                                text-white
                                                bg-indigo-600 
                                                hover:bg-indigo-700
                                                disabled:opacity-50"
                                        style="float:right;">
                                    Save
                                </button>
                            </div>
                        </DialogPanel>
                    </TransitionChild>
                </div>
            </div>
        </Dialog>
    </TransitionRoot>
</template>
  
<script>
    import { Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue'
    import axios from "axios";
    import { getStorage } from "@/saveStorage";
    import { Switch, SwitchGroup, SwitchLabel } from '@headlessui/vue'

    export default {
        props: [
            'open', 
            'mode', 
            'company',
        ],

        components: {
            Dialog,
            DialogPanel,
            DialogTitle,
            TransitionRoot,
            TransitionChild,
            Switch,
            SwitchGroup,
            SwitchLabel
        },

        data() {
            return {
                api_url:    process.env.VUE_APP_API_URL,
                errors:     [],
                modal_key:  0,
            };
        },
  
        watch: {
        },

        computed: {
            mode_title() {
                return this.mode;
            }
        },
      
        created() {
            this.token  = getStorage('token');
        },
    
        mounted() {
        },
  
        methods: {
            closeMe() {
                this.$parent.edit_open    = false;
                this.$emit('close');
            },

            async submit() {
                this.errors = [];

                const config = {
                    headers: { Authorization: `Bearer ` + this.token }
                };

                var data = {
                    'name':            this.company.name,
                    'email':           this.company.email,
                    'web':             this.company.web,
                    'has_firewall':    this.company.has_firewall,
                    'host':            this.company.host,
                    'host_email':      this.company.host_email,
                    'isp':             this.company.isp,
                    'isp_email':       this.company.isp_email,
                }

                if (this.mode == 'Add') {
                    var url = this.api_url + '/api/admin/companies/create';
                    axios.post(url, data, config)
                    .then((response) => {
                        this.closeMe();
                        this.$swal({
                            title:              'Company Added',
                            confirmButtonColor: '#be185d',
                            confirmButtonText:  'Close',
                            icon:               'success',
                            background:         '#3f3f46',
                        });
                    })
                    .catch(error => {
                        console.log("error", error);
                        var error_msg     = '';
                        var error_details = '';
                        if (error.response.data.message) {
                            error_msg = error.response.data.message;	
                        }
                        
                        if (error.response.data.errors) {
                            Object.keys(error.response.data.errors).forEach(key => {
                                error_details = error_details + error.response.data.errors[key].toString() + "<br>";

                                // load api errors into client errors
                                this.errors[key] = error.response.data.errors[key].toString();
                            });
                        }
                    });
                }
                else {
                    var url = this.api_url + '/api/admin/companies/' + this.company.id + '/update';
                    axios.patch(url, data, config)
                    .then((response) => {
                        this.closeMe();
                        this.$swal({
                            title:              'Company Updated',
                            confirmButtonColor: '#be185d',
                            confirmButtonText:  'Close',
                            icon:               'success',
                            background:         '#3f3f46',
                        });
                    })
                    .catch(error => {
                        console.log("error", error);
                        var error_msg     = '';
                        var error_details = '';
                        if (error.response.data.message) {
                            error_msg = error.response.data.message;	
                        }
                        
                        if (error.response.data.errors) {
                            Object.keys(error.response.data.errors).forEach(key => {
                                error_details = error_details + error.response.data.errors[key].toString() + "<br>";

                                // load api errors into client errors
                                this.errors[key] = error.response.data.errors[key].toString();
                            });
                        }
                    });
                }
            },
        },
    };
</script>
