<template>
  <BrochureLayout>
    <div class="bg-zinc-900">
      <page_header>
        <template v-slot:title>
          Customs
        </template>
        <template v-slot:tag>
          These are your custom projects, where you control the scripts for our stunning bound and gagged girls.
        </template>
      </page_header>
      <div class="max-w-7xl mx-auto overflow-hidden sm:px-6 lg:px-8 mb-3 p-10">
        <p class=" p-5 text-white text-center border-t-2 border-gray-200">
          These are your custom projects, where you control the scripts for our stunning bound and gagged girls.
        </p>
        <p class=" p-5 text-white text-center border-b-2 border-gray-200">
           <a href="mailto:enquiries@borderlandbound.com"
            class="text-pink-700 hover:text-pink-900">enquiries@borderlandbound.info</a> for information on getting a high
            definition custom video shot to your specifications.
        </p>

        <p class="p-5 text-white text-center">IMPORTANT CUSTOM INFORMATION - Please read carefully.</p>

        <p class="text-white p-2">All scripts must be bondage-themed, with gags used.</p>

        <p class="text-white p-2">Customs with us work this way:</p>

        <p class="text-white p-2">You, the custom requester, would first send us your script so that we
          can determine if it is something we and our models are happy to shoot.</p>

        <p class="text-white p-2">Your script should not be too complicated or involved for us to 
          achieve in the allotted time we have with the model(s) and must be of no more than 1 page, 
          laid out in a simple to follow fashion - if it is too wordy, or jumps around back and 
          forth with too many details, we may miss things that are important to you.</p>

        <p class="text-white p-2">Once we have approved your script, subject to any agreed-upon 
          adjustments, we would then send you our price list. If you wish to proceed, we will 
          let you know which models are currently in our schedule for you to choose from.</p>

        <p class="text-white p-2">You would then forward us your full payment and we will 
          commit the selected model(s) to your custom project.</p>

        <p class="text-white p-2">Your completed custom video(s) is emailed to you as a digital 
          download(s) and after you have received it, will be published in our Store(s)/ web site(s).</p>

        <p class="text-white p-2">If you have specific wardrobe requirements that we or the girls are 
          unable to accommodate, you can send us UK-based retailer link(s) and we can order in and 
          add this to your cost.</p>

        <p class="text-white p-2">We will not entertain any scripts that may endanger the health and safety of any and all performers.
        </p>

        <p class="text-white p-2">IMPORTANT: **Although we will, with great care, cater your 
          custom to meet your specific requirements, your understanding is necessary with 
          fully edited video timings, which will, depending on circumstances, vary.***</p>

        <p class="text-white p-2">We require your patience and understanding in the event of 
          unforeseen variables which may occur on the day of/ run up to the shoot, including 
          any necessary modifications/ conflations within the scene due to wardrobe, make up, 
          props, positioning, bondage, gags etc, or last minute cancellations/ postponements 
          on the part of the model(s). Your flexibility with alternative model choices etc 
          would also be required.
        </p>

        <p class="text-white p-2"> In the event that we do have to reschedule your shoot(s) or suggest other models until we get it done (this can of course take time and may happen more than once), we ask that you again remain patient and respect the fact that we will ALWAYS be doing our utmost to fulfil your request as soon as we can.
        </p>
        <p class="text-white p-2">***No refunds will be issued, unless we are completely unable to find replacement model(s) to produce your custom.***</p>
        <p class="text-white p-2">If you agree to all of the above, we look forward to hearing from you!</p>

        <p class="text-white p-2">The Team - Borderland Bound</p>
      </div>
      <!-- Promo Pricing-->
      <pricing-component></pricing-component>
    </div>
  </BrochureLayout>
</template>
<script>
import BrochureLayout from '../layouts/BrochureLayout'
import { StarIcon } from '@heroicons/vue/solid'
import page_header from "@/reuseable/page_header";
import pricingComponent from '@/reuseable/pricing_component.vue'

export default {
  components: {
    pricingComponent,
    BrochureLayout,
    StarIcon,
    page_header
  },
  data() {
    return {}
  },
  methods: {},
  created() {
  }

}
</script>
<style></style>
