<template>
  <div class="relative pt-16 pb-32 overflow-hidden">
        <div aria-hidden="true" class="absolute inset-x-0 top-0 h-48" />
        <div class="relative">
          <div class="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:grid-flow-col-dense lg:gap-24">
            <div class="px-4 max-w-xl mx-auto sm:px-6 lg:py-16 lg:max-w-none lg:mx-0 lg:px-0">
              <div>
                <div>
                  <span class="h-12 w-12 rounded-md flex items-center justify-center bg-gradient-to-r from-pink-700 to-pink-700">
                    <FilmIcon class="h-6 w-6 text-white" aria-hidden="true" />
                  </span>
                </div>
                <div class="mt-6">
                  <h2 class="text-3xl font-extrabold tracking-tight text-white">High Res Image Sets, Video Streaming & Downloadable Clips</h2>

                  <div class="mt-6">
                    <a href="/latest-updates" class="inline-flex bg-gradient-to-r from-pink-700 to-pink-700 bg-origin-border px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white hover:from-pink-900 hover:to-pink-900"> Members’ Updates </a>
                  </div>
                </div>
              </div>
              <div class="mt-8 border-t border-gray-200 pt-6">
                <blockquote>
                  <div>
                    <p class="text-base text-white">
                      Download to keep and/or enjoy lightning-fast streaming from our library of thrilling video clips, including full length releases, presented in HD 1080p.
                    </p>
                  </div>
                  <footer class="mt-3">
                    <div class="flex items-center space-x-3">

                      <div class="text-base font-medium text-white"></div>
                    </div>
                  </footer>
                </blockquote>
              </div>
            </div>
             <div class="mt-12 sm:mt-16 lg:mt-0">
              <div class="pl-4 -mr-48 sm:pl-6 md:-mr-16 lg:px-0 lg:m-0 lg:relative lg:h-full">
                <img class="w-full rounded-xl shadow-xl ring-1 ring-black ring-opacity-5 lg:absolute lg:left-0 lg:h-full lg:w-auto lg:max-w-none" src="@/assets/aside1.jpg" alt="Inbox user interface" />
              </div>
            </div>
          </div>
        </div>
        <div class="mt-24">
          <div class="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:grid-flow-col-dense lg:gap-24">
            <div class="px-4 max-w-xl mx-auto sm:px-6 lg:py-32 lg:max-w-none lg:mx-0 lg:px-0 lg:col-start-2">
              <div>
                <div>
                  <span class="h-12 w-12 rounded-md flex items-center justify-center bg-gradient-to-r from-pink-700 to-pink-700 ">
                    <SparklesIcon class="h-6 w-6 text-white" aria-hidden="true" />
                  </span>
                </div>
                <div class="mt-6">
                  <h2 class="text-3xl font-extrabold tracking-tight text-white">Membership Options</h2>
                  <p class="mt-4 text-lg text-white">Join for 1 month at $29.95, recurring, or 3 months at $59.95, recurring.</p>
                  <p class="mt-4 text-lg text-white">Becoming a Membership Subscriber to BorderlandBound.com grants you immediate access to 1000s of Images and Video Clips featuring the most beautiful girls, all tied up and gagged</p>

                  <div class="mt-6">
                    <a href="/member/signup" v-if="!loggedUser" class="inline-flex bg-gradient-to-r from-pink-700 to-pink-700 bg-origin-border px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white hover:from-pink-900 hover:to-pink-900"> Sign up </a>
                    <a href="/member/home" v-else class="inline-flex bg-gradient-to-r from-pink-700 to-pink-700 bg-origin-border px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white hover:from-pink-900 hover:to-pink-900"> Members' Area </a>
                 </div>
                </div>
              </div>
            </div>
            <div class="mt-12 sm:mt-16 lg:mt-0 lg:col-start-1">
              <div class="pr-4 -ml-48 sm:pr-6 md:-ml-16 lg:px-0 lg:m-0 lg:relative lg:h-full">
                <img class="w-full rounded-xl shadow-xl ring-1 ring-black ring-opacity-5 lg:absolute lg:right-0 lg:h-full lg:w-auto lg:max-w-none" src="@/assets/aside2.jpg" alt="Customer profile user interface" />
              </div>
            </div>
          </div>
        </div>
      </div>
</template>
<script>
import {
  FilmIcon,
  SparklesIcon
} from '@heroicons/vue/outline'
import {getStorage} from "@/saveStorage";
export default {
  components: {
    FilmIcon,
    SparklesIcon
  },
  computed:{
    loggedUser() {
      if(getStorage('LoggedUser')) {
        return JSON.parse(getStorage('LoggedUser'))
      } else {
        return false
      }
    },
  }
}
</script>