<template>
  <!-- Component doesn't need a template -->
</template>

<script>
export default {
  name: 'GTMScript',
  mounted() {
    // GTM Script
    const script = document.createElement('script')
    script.innerHTML = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
      new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
      j=d.createElement(s);j.async=true;j.src="https://sgtm.borderlandbound.com/1uxwspncfy.js?"+i;
      f.parentNode.insertBefore(j,f)})(window,document,'script','dataLayer','1o=aWQ9R1RNLUtWR0hHU0cz&page=2')`
    document.head.appendChild(script)

    // NoScript iframe
    const noscript = document.createElement('noscript')
    const iframe = document.createElement('iframe')
    iframe.src = 'https://sgtm.borderlandbound.com/ns.html?id=GTM-KVGHGSG3'
    iframe.height = '0'
    iframe.width = '0'
    iframe.style.display = 'none'
    iframe.style.visibility = 'hidden'
    noscript.appendChild(iframe)
    document.body.insertBefore(noscript, document.body.firstChild)
  }
}
</script>
