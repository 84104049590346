<template>
    <admin-layout>
        <div class="space-y-8 divide-y divide-gray-200">
            <div class="space-y-8 divide-y divide-gray-200 sm:space-y-5">
                <div>
                    <div class="flex items-center justify-between">
                        <div class="flex-1 min-w-0">
                            <h3 class="text-lg leading-6 font-medium text-gray-900">
                                All DMCA Notices
                            </h3>
                        </div>
                        <div class="mt-4 flex md:mt-0 md:ml-4">
                            <p class="mt-2 pr-2 text-indigo-600 font-bold">Page {{ pagination.page }} of {{ pagination.page_count }}</p>
                            <button v-if="pagination.page > 1"
                                    type="button" 
                                    class="inline-flex items-center px-4 py-2 
                                           border border-transparent rounded-md shadow-sm 
                                           text-sm font-medium text-white bg-gray-600 
                                           hover:bg-gray-700 
                                           focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-indigo-500" 
                                           @click="getPrevPage">Previous</button>
                            <button v-if="pagination.page < pagination.page_count"
                                    type="button" 
                                    class="ml-3 inline-flex items-center px-4 py-2 
                                           border border-transparent rounded-md shadow-sm 
                                           text-sm font-medium text-white bg-blue-500 
                                           hover:bg-indigo-600 
                                           focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-indigo-500" 
                                           @click="getNextPage">Next</button>
                        </div>
                    </div>
                    <div class="mt-8 flex flex-col">
                        <div class="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                            <div class="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                                <div class="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                                    <table class="min-w-full divide-y divide-gray-300" :key="table_key">
                                        <thead class="bg-gray-50">
                                            <tr>
                                                <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Company</th>
                                                <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Status</th>
                                                <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Removed?</th>
                                                <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Links</th>
                                                <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Host</th>
                                                <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">ISP</th>
                                                <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Firewall?</th>
                                                <th scope="col" class="px-3 py-3.5"></th>
                                            </tr>
                                        </thead>
                                        <tbody class="divide-y divide-gray-200 bg-white">
                                            <tr v-for="dmca_notice in dmca_notices" :key="dmca_notice.id">
                                                <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-small text-gray-900 sm:pl-6">{{ dmca_notice.company_name }}<br>{{ dmca_notice.company_web }}</td>
                                                <td class="whitespace-nowrap px-3 py-4 text-sm font-small text-gray-500">{{ dmca_notice.type }}<br>
                                                                                                                         {{ (dmca_notice.is_content_removed == true) ? dmca_notice.content_removed_at : dmca_notice.sent_at }}</td>
                                                <td class="whitespace-nowrap px-3 py-4 text-sm font-small text-gray-500">{{ (dmca_notice.is_content_removed == true) ? 'Yes' : 'No' }}</td>
                                                <td class="whitespace-nowrap px-3 py-4 text-sm font-small text-gray-500">{{ dmca_notice.links.length }}</td>
                                                <td class="whitespace-nowrap px-3 py-4 text-sm font-small text-gray-500">{{ dmca_notice.company_host }}</td>
                                                <td class="whitespace-nowrap px-3 py-4 text-sm font-small text-gray-500">{{ dmca_notice.company_isp }}</td>
                                                <td class="whitespace-nowrap px-3 py-4 text-sm font-small text-gray-500">{{ (dmca_notice.company_has_firewall == true) ? 'Yes' : 'No' }}</td>
                                                <td class="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                                                    <button v-if="dmca_notice.next_type != 'No Further Escalations' && dmca_notice.next_type != 'Notice Is Complete'"
                                                            @click="escalate_areyousure(dmca_notice)" 
                                                            class="py-0 px-1 mr-2 text-white rounded-lg 
                                                                   border-2 border-indigo-600 bg-indigo-600
                                                                   hover:bg-indigo-900">
                                                        Escalate
                                                    </button>
                                                    <button v-if="dmca_notice.next_type != 'Notice Is Complete'"
                                                            @click="complete_areyousure(dmca_notice)" 
                                                            class="py-0 px-1 mr-2 text-white rounded-lg 
                                                                   border-2 border-green-600 bg-green-600
                                                                   hover:bg-green-900">
                                                        Complete
                                                    </button>
                                                    <button @click="open_view(dmca_notice)" 
                                                            class="py-0 px-1 mr-2 rounded-lg 
                                                                   text-indigo-600 border-2 border-gray-200 rounded-lg 
                                                                   hover:bg-black hover:bg-opacity-5">
                                                        Details
                                                    </button>
                                                    <button @click="delete_areyousure(dmca_notice)" 
                                                            class="py-0 px-1 text-white rounded-lg 
                                                                   border-2 border-red-600 bg-red-600
                                                                   hover:bg-red-900">
                                                        Del
                                                    </button>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <viewDMCACompany :open="view_open"
                         :notice="view_notice"
                         @close="close_view"
        ></viewDMCACompany>
    </admin-layout>
</template>

<script>
    import axios           from "axios";
    import { getStorage }  from "@/saveStorage";
    import AdminLayout     from "@/layouts/adminLayout.vue";
    import viewDMCACompany from "./modals/viewDMCANotice.vue";

    export default {
        components: {
            AdminLayout,
            viewDMCACompany,
        },

        data() {
            return {
                api_url: process.env.VUE_APP_API_URL,
                dmca_notices: [],
                pagination:   null,
                search:       null,

                view_open:    false,
                view_notice:  null,
                table_key:    0,
            };
        },

        watch: {},
        computed: {},
    
        created() {
            this.token  = getStorage('token');
            this.search = this.$route.query.search;

            this.getAllDmcaNotices();
        },
  
        mounted() {
        },

        methods: {
            open_view(dmca_notice) {
                this.view_notice = dmca_notice;
                this.view_open   = true;
            },

            close_view() {
                this.view_notice = null;
                this.view_open   = false;
            },

            escalate_areyousure(dmca_notice) {
                let _self = this;
                this.$swal({
                    title:            'Are you sure?',
                    html:             'Click "yes" to confirm that <br> \
                                       you wish to escalate this notice to <br> \
                                       "' + dmca_notice.next_type + '".<br><br> \
                                       If not, click "No" to cancel.',

                    showCancelButton:   true,
                    allowEscapeKey:     false,
                    allowOutsideClick:  false,

                    confirmButtonColor: '#4f46e5',
                    cancelButtonColor:  '#dc2626',
                    confirmButtonText:  'No',
                    cancelButtonText:   'Yes',
                    icon:               'question',
                    background:         '#3f3f46',
                }).then(function(response) {
                    // NOTE: confirm and cancel have been swapped so that the default is to NOT delete
                    if (response && response.isConfirmed == true) {
                        // do nothing
                    } 
                    else {
                        _self.escalate(dmca_notice);
                    }
                });
            },

            escalate(dmca_notice) {
                const config = {
                    headers: { Authorization: `Bearer ` + this.token }
                };

                var url = this.api_url + '/api/admin/dmcanotices/' + dmca_notice.id + '/escalate';

                var data = {
                    "company_id": this.company,
                }

                axios.post(url, data, config)
                .then((response) => {
                    this.getAllDmcaNotices();
                    this.$swal({
                        title:              'Notice Escalated',
                        confirmButtonColor: '#be185d',
                        confirmButtonText:  'Close',
                        icon:               'success',
                        background:         '#3f3f46',
                    });
                })
                .catch(error => {
                    var error_msg     = '';
                    if (error.response.data.message) {
                        error_msg = error.response.data.message;	
                    }
                    this.$swal({
                        title:              'Notice was not Escalated',
                        html:               error_msg,
                        confirmButtonColor: '#be185d',
                        confirmButtonText:  'Close',
                        icon:               'success',
                        background:         '#3f3f46',
                    });
                });
            },

            complete_areyousure(dmca_notice) {
                let _self = this;
                this.$swal({
                    title:            'Are you sure?',
                    html:             'Click "yes" to confirm that <br> \
                                       you wish to COMPLETE this notice and <br> \
                                       mark the content as removed.<br><br> \
                                       If not, click "No" to cancel.',

                    showCancelButton:   true,
                    allowEscapeKey:     false,
                    allowOutsideClick:  false,

                    confirmButtonColor: '#4f46e5',
                    cancelButtonColor:  '#dc2626',
                    confirmButtonText:  'No',
                    cancelButtonText:   'Yes',
                    icon:               'question',
                    background:         '#3f3f46',
                }).then(function(response) {
                    // NOTE: confirm and cancel have been swapped so that the default is to NOT delete
                    if (response && response.isConfirmed == true) {
                        // do nothing
                    } 
                    else {
                        _self.complete(dmca_notice);
                    }
                });
            },

            complete(dmca_notice) {
                const config = {
                    headers: { Authorization: `Bearer ` + this.token }
                };

                var url = this.api_url + '/api/admin/dmcanotices/' + dmca_notice.id + '/complete';

                var data = {
                    "company_id": this.company,
                }

                axios.post(url, data, config)
                .then((response) => {
                    this.getAllDmcaNotices();
                    this.$swal({
                        title:              'Notice Completed',
                        confirmButtonColor: '#be185d',
                        confirmButtonText:  'Close',
                        icon:               'success',
                        background:         '#3f3f46',
                    });
                })
                .catch(error => {
                    var error_msg     = '';
                    if (error.response.data.message) {
                        error_msg = error.response.data.message;	
                    }
                    this.$swal({
                        title:              'Notice was not Completed',
                        html:               error_msg,
                        confirmButtonColor: '#be185d',
                        confirmButtonText:  'Close',
                        icon:               'success',
                        background:         '#3f3f46',
                    });
                });
            },

            delete_areyousure(dmca_notice) {
                let _self = this;
                this.$swal({
                    title:            'Are you sure?',
                    html:             '<b>This operation cannot be undone.</b><br><br> \
                                       Click "yes" to confirm that <br> \
                                       you wish to DELETE this notice.<br><br> \
                                       If not, click "No" to cancel.',

                    showCancelButton:   true,
                    allowEscapeKey:     false,
                    allowOutsideClick:  false,

                    confirmButtonColor: '#4f46e5',
                    cancelButtonColor:  '#dc2626',
                    confirmButtonText:  'No',
                    cancelButtonText:   'Yes',
                    icon:               'question',
                    background:         '#3f3f46',
                }).then(function(response) {
                    // NOTE: confirm and cancel have been swapped so that the default is to NOT delete
                    if (response && response.isConfirmed == true) {
                        // do nothing
                    } 
                    else {
                        _self.delete(dmca_notice);
                    }
                });
            },

            delete(dmca_notice) {
                const config = {
                    headers: { Authorization: `Bearer ` + this.token }
                };

                var url = this.api_url + '/api/admin/dmcanotices/' + dmca_notice.id + '/delete';

                var data = {
                    "company_id": this.company,
                }

                axios.post(url, data, config)
                .then((response) => {
                    this.getAllDmcaNotices();
                    this.$swal({
                        title:              'Notice Deleted',
                        confirmButtonColor: '#be185d',
                        confirmButtonText:  'Close',
                        icon:               'success',
                        background:         '#3f3f46',
                    });
                })
                .catch(error => {
                    var error_msg     = '';
                    if (error.response.data.message) {
                        error_msg = error.response.data.message;	
                    }
                    this.$swal({
                        title:              'Notice was not Deleted',
                        html:               error_msg,
                        confirmButtonColor: '#be185d',
                        confirmButtonText:  'Close',
                        icon:               'success',
                        background:         '#3f3f46',
                    });
                });
            },


            getAllDmcaNotices() {
                this.getDmcaNotices(1)
            },
            getPrevPage() {
                this.getDmcaNotices(this.pagination.page - 1)
            },
            getNextPage() {
                this.getDmcaNotices(this.pagination.page + 1)
            },

            async getDmcaNotices(page) {
                const config = {
                    headers: { Authorization: `Bearer ` + this.token }
                };

                var url = this.api_url + '/api/admin/dmcanotices';
                    url = url + '?q=' + (this.search || '');
                    url = url + '&per_page=8&page=' + page;

                this.dmca_notices = [];
                await axios.get(url, config).then(response => {
                    this.dmca_notices = response.data.records
                    this.pagination   = response.data.pagination
                    this.search       = response.data.search.q
                });
            },
        },
    };
</script>

<style>
</style>