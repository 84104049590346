import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './assets/tailwind.css'
import Vue3VideoPlayer from '@cloudgeek/vue3-video-player'
import '@cloudgeek/vue3-video-player/dist/vue3-video-player.css'
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import UUID from "vue3-uuid"



const app = createApp(App);

app.use(store);
app.use(router);
app.use(Vue3VideoPlayer);
app.use(VueSweetalert2);
app.use(UUID)
app.provide('uuidNamespace', process.env.VUE_APP_UUID_NAMESPACE)





app.mount('#app')
