<template>
  <brochure-layout>
    <store-menu></store-menu>
    <div class="bg-zinc-900">
      <page_header>
        <template v-slot:title>
          Store
        </template>
        <template v-slot:tag>
          Welcome to the Borderland Bound clips store
        </template>
      </page_header>
      <div class="max-w-2xl mx-auto py-16 px-4 sm:py-24 sm:px-6 lg:max-w-7xl lg:px-8">
        <div class="text-center mb-4">
          <p class="text-white">Here you will find many exciting titles from our archives as well as brand new releases.</p>
          <p class="text-white">Please also visit our
            <a href="https://www.clips4sale.com/studio/64171/borderland-bound-" target="_blank" class="text-pink-700 hover:text-pink-900">Clips4Sale</a>
            Store for more.</p>
        </div>
        <nav class="bg-pink-700 px-4 py-3 flex items-center justify-between border-t border-pink-900 sm:px-6 mt-5 mb-5" aria-label="Pagination">
          <div class="hidden sm:block">
            <p class="text-sm text-white">
              Showing Page
              {{ ' ' }}
              <span class="font-medium">{{videosPag.page}}</span>
              {{ ' ' }}
              of
              {{ ' ' }}
              <span class="font-medium">{{videosPag.page_count}}</span>

            </p>
          </div>
          <div class="flex-1 flex justify-between sm:justify-end">
            <a href="#" 
                   v-if="videosPag.page > 1" 
                   class="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50" 
                   @click="getLastPage"> Previous </a>
                <a href="#" 
                   v-if="videosPag.page < videosPag.page_count"
                   class="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50" 
                   @click="getNextPage"> Next </a>
          </div>
        </nav>
        <div class="mt-5">
          <div class="mt-1 flex rounded-md shadow-sm mb-8">
            <div class="relative flex flex-grow items-stretch focus-within:z-10">
              <div class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                <video-camera-icon class="h-5 w-5 text-gray-400" aria-hidden="true"/>
              </div>
              <input type="text" name="search" id="search"
                     class="block w-full rounded-none rounded-l-md pl-10 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                     placeholder="Search Videos" v-model="search"/>
            </div>
            <button type="button"
                    class="relative -ml-px inline-flex items-center space-x-2  bg-pink-900 px-4 py-2 text-sm font-medium text-white hover:bg-pink-900 focus:border-pink-900 focus:outline-none focus:ring-1 focus:ring-pink-900"
                    @click="SearchAllVideos">
              <search-icon class="h-5 w-5 text-white" aria-hidden="true"/>
              <span>Search</span>
            </button>
            <button type="button"
                    class="relative -ml-px inline-flex items-center space-x-2 rounded-r-md  bg-pink-700 px-4 py-2 text-sm font-medium text-white hover:bg-pink-900 focus:border-pink-900 focus:outline-none focus:ring-1 focus:ring-pink-900"
                    @click="getStoreUpdates">
              <video-camera-icon class="h-5 w-5 text-white" aria-hidden="true"/>
              <span>All Videos</span>
            </button>
          </div>
        </div>
        <div class="grid grid-cols-1 gap-y-4 sm:grid-cols-2 sm:gap-x-6 sm:gap-y-10 lg:grid-cols-3 lg:gap-x-8">
          <div v-for="product in reverseProducts" :key="product.id"
               class="group relative bg-zinc-900 rounded-lg flex flex-col overflow-hidden border"
               @mouseover="hovered = product.title"
               @mouseleave="this.hovered = null">

            <div class="aspect-w-3 aspect-h-3 bg-zinc-900 group-hover:opacity-75 sm:aspect-none sm:h-52">
              <img class="w-full h-full object-center object-cover sm:w-full sm:h-full"
                   :src="hovered === product.title ? '/pixel.gif' : product.cover_thumbnail_url"
                   :alt="''" 
                   :style="'width:380px; height:208px; \
                            background: url(' + product.cover_url + ') no-repeat; \
                            background-size: cover;'" />

                   />
            </div>
            <div class="flex-1 p-4 space-y-2 flex flex-col">
              <h3 class="text-sm font-medium text-pink-700">
                <a :href="'/ecommerce/listing?id=' + product.id">
                  <span aria-hidden="true" class="absolute inset-0"/>
                  {{ product.title }}
                </a>
              </h3>
              <div class="flex-1 flex flex-col justify-end">
                <p class="text-sm italic text-white">{{ product.options }}</p>
                <p class="text-base font-medium text-pink-700">${{ product.price }}</p>

              </div>
              <div>
              </div>
            </div>
          </div>
        </div>
        <nav class="bg-pink-700 px-4 py-3 flex items-center justify-between border-t border-pink-900 sm:px-6 mt-5" aria-label="Pagination">
            <div class="hidden sm:block">
                <p class="text-sm text-white">
                    Showing Page
                    {{ ' ' }}
                    <span class="font-medium">{{videosPag.page}}</span>
                    {{ ' ' }}
                    of
                    {{ ' ' }}
                    <span class="font-medium">{{videosPag.page_count}}</span>
                </p>
            </div>
            <div class="flex-1 flex justify-between sm:justify-end">
                <a href="#" 
                   v-if="videosPag.page > 1" 
                   :disabled="paging_ok == false  ||  page == 1" 
                   class="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50" 
                   @click="getLastPage"> Previous </a>
                <a href="#" 
                   v-if="videosPag.page < videosPag.page_count"
                   :disabled="paging_ok == false"
                   class="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50" 
                   @click="getNextPage"> Next </a>
            </div>
        </nav>

       </div>
    </div>
  </brochure-layout>
</template>

<script>
import BrochureLayout from "@/layouts/BrochureLayout";
import page_header from "@/reuseable/page_header";
import storeMenu from "@/ecommerce/storeMenu"
import axios from "axios";
import {SearchIcon, VideoCameraIcon, SearchCircleIcon} from '@heroicons/vue/solid'
import {getStorage, saveStorage} from "@/saveStorage";

export default {
    components: {
        BrochureLayout,
        page_header,
        storeMenu,
        SearchIcon,
        SearchCircleIcon,
        VideoCameraIcon
    },

    data() {
        return {
            api_url:   process.env.VUE_APP_API_URL,
            auth:      null,
            products:  [],
            cart:      '',
            hovered:   null,
            videosPag: {},
            search:    '',

            preload_images:   [],
            preload_covers:   [],
            preload_thumbs:   [],
            cancel_preloads:  false,
            paging_ok:        false,
            covers_loading:   0,

            // Images
            img_cancel:       'data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEAAAAALAAAAAABAAEAAAI=;'
        }
    },

    computed: {
        reverseProducts: function() {
            var reversed = [];
            for (const id in this.products) {
                reversed.unshift(this.products[id]);
            }
            return reversed;
        }
    },

    created() {
        this.auth = getStorage('auth')
        if (!this.auth) {
            this.auth = {
                'token': ''
            }
        }

        this.getStoreUpdates()
        this.setCart()
    },

    watch: {
        preload_covers: {
            deep: true,
            handler(n) {
                if (this.preload_covers.length > 0) {
                    this.process_preload_covers();
                }
            }
        },
        preload_thumbs: {
            deep: true,
            handler(n) {
                if (this.preload_thumbs.length > 0) {
                    this.process_preload_thumbs();
                }
                else {
                    setTimeout(function() {
                        this.paging_ok = true;
                    }, 1000);
                }
            }
        }
    },

    methods: {
        process_preload_covers() {
            var _self = this;

            var cover     = _self.preload_covers.shift();
            var cover_img = new Image();

            cover_img.onload = function () {
                if (_self.products[cover.id]) {
                    _self.products[cover.id].cover_url = cover_img.src;
                    //console.log("cover["+cover.id+"] loaded");
                }
            }

            // request the image data
            cover_img.src = _self.api_url + '/api' + cover.cover_url;

            if (cover_img.complete) {
                //console.log("cover["+cover.id+"] complete");
            }

            _self.preload_images.push(cover_img);
        },
        process_preload_thumbs() {
            var _self     = this;
            //var thumb     = this.preload_thumbs[0];
            var thumb     = this.preload_thumbs.shift();
            var thumb_img = new Image();

            thumb_img.onload = function () {
                if (_self.products[thumb.id]) {
                    _self.products[thumb.id].cover_thumbnail_url = thumb_img.src;
                    //console.log("thumb["+thumb.id+"] loaded");
                }
            }

            // request the image data
            thumb_img.src = _self.api_url + '/api' + thumb.cover_thumbnail_url;

            if (thumb_img.complete) {
                _self.products[thumb.id].cover_thumbnail_url = thumb_img.cover_thumbnail_url;
                //console.log("thumb["+thumb.id+"] complete");
            }
        },

        SearchAllVideos() {
            const config = {
                headers: { Authorization: `Bearer ` + this.auth.token.replace(/^"(.+(?="$))"$/, '$1') }
            };

            var url = this.api_url + '/api/shop_videos';
                url = url + '?q=' + (this.search || '');
                url = url + '&per_page=9&page=1';

            axios.get(url, config).then(response => {
                this.products = [];
                this.products  = response.data.records.videos;
                this.videosPag = response.data.pagination;

                var t  = JSON.stringify(response.data.records.preloads);
                this.preload_thumbs = JSON.parse(t);
                this.preload_covers = JSON.parse(t);

                if (Object.keys(this.products).length > 0) {
                    for (const id in this.products) {
                        this.products[id].cover_url = '/spinner.gif';
                        this.products[id].cover_thumbnail_url = '/spinner.gif';
                    }
                }
            });
        },

        getStoreUpdates() {
            this.paging_ok = false;

            const config = {
                headers: { Authorization: `Bearer ` + this.auth.token.replace(/^"(.+(?="$))"$/, '$1') }
            };

            axios.get(this.api_url + '/api/shop_videos?per_page=9').then(response => {
                this.products  = [];
                this.products  = response.data.records.videos;
                this.videosPag = response.data.pagination;

                var t  = JSON.stringify(response.data.records.preloads);
                this.preload_thumbs = JSON.parse(t);
                this.preload_covers = JSON.parse(t);

                if (Object.keys(this.products).length > 0) {
                    for (const id in this.products) {
                        this.products[id].cover_url = '/spinner.gif';
                        this.products[id].cover_thumbnail_url = '/spinner.gif';
                    }
                }
            });
        },

        getNextPage() {
            this.paging_ok = false;

            const config = {
                headers: { Authorization: `Bearer ` + this.auth.token.replace(/^"(.+(?="$))"$/, '$1') }
            };

            var url = this.api_url + '/api/shop_videos';
                url = url + '?q=' + (this.search || '');
                url = url + '&per_page=9&page=' + parseInt(this.videosPag.page + 1);

            axios.get(url, config).then(response => {
                this.products = [];
                this.products = response.data.records.videos;
                this.videosPag = response.data.pagination;

                var t  = JSON.stringify(response.data.records.preloads);
                this.preload_thumbs = JSON.parse(t);
                this.preload_covers = JSON.parse(t);

                if (Object.keys(this.products).length > 0) {
                    for (const id in this.products) {
                        this.products[id].cover_url = '/spinner.gif';
                        this.products[id].cover_thumbnail_url = '/spinner.gif';
                    }
                }
            });
        },
        
        getLastPage() {
            const config = {
                headers: { Authorization: `Bearer ` + this.auth.token.replace(/^"(.+(?="$))"$/, '$1') }
            };

            var url = this.api_url + '/api/shop_videos';
                url = url + '?q=' + (this.search || '');
                url = url + '&per_page=9&page=' + parseInt(this.videosPag.page - 1);

            axios.get(url, config).then(response => {
                this.products  = [];
                this.products  = response.data.records.videos;
                this.videosPag = response.data.pagination;

                var t  = JSON.stringify(response.data.records.preloads);
                this.preload_thumbs = JSON.parse(t);
                this.preload_covers = JSON.parse(t);

                if (Object.keys(this.products).length > 0) {
                    for (const id in this.products) {
                        this.products[id].cover_url = '/spinner.gif';
                        this.products[id].cover_thumbnail_url = '/spinner.gif';
                    }
                }
            });
        },
            
        setCart() {
        const cart = getStorage('cart')
        if(cart) {
            this.cart = cart
        } else {
            saveStorage('cart', [])
            this.cart = getStorage('cart')
        }
        },
    }
}

</script>