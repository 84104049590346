<template>
  <GTMScript />
  <router-view/>
</template>

<script>
import GTMScript from './reuseable/gtm_script.vue'
export default {
    name: 'App',
    components: {
      GTMScript
    },
    created() {
        if (process.env.VUE_APP_ENV == 'production') {
            document.addEventListener('contextmenu', event => event.preventDefault());
        }
    }
}
</script>

<style>
</style>
