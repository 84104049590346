<template>
    <brochure-layout>
        <div class="relative">
            <div aria-hidden="true" class="hidden absolute w-1/2 h-full bg-zinc-900 lg:block" />
            <div class="relative bg-zinc-900 lg:bg-transparent">
                <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 lg:grid lg:grid-cols-2">
                    <div class="max-w-2xl mx-auto py-24 lg:py-24 lg:max-w-none">

                            <div class="lg:pr-16">
                                <h1 class="text-2xl font-bold tracking-tight text-white sm:tracking-tight sm:text-5xl xl:tracking-tight xl:text-6xl">Contact Us</h1>
                                <p class="text-sm font-medium text-white mt-4">Please use the form below to contact us if you have a custom request or if you are having any technical issues with the web site.</p>

                                <div class="mt-5">
                                    <label for="name" class="block text-sm font-medium text-white">Name</label>
                                    <div class="mt-1">
                                        <input type="text" name="name" id="name" 
                                               v-model="formData.name"
                                               class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md" 
                                               placeholder="your name" />
                                    </div>
                                    <span class="font-small text-pink-700">{{ errors['name'] }}</span>
                                </div>

                                <div class="mt-3">
                                    <label for="email" class="block text-sm font-medium text-white">Email</label>
                                    <div class="mt-1">
                                        <input type="email" name="email" id="email" 
                                               v-model="formData.email"
                                               class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md" 
                                               placeholder="you@example.com" />
                                    </div>
                                    <span class="font-small text-pink-700">{{ errors['email'] }}</span>
                                </div>

                                <div class="mt-3">
                                    <label for="comment" class="block text-sm font-medium text-white">Add your comment</label>
                                    <div class="mt-1">
                                        <textarea rows="4" name="comment" id="comment" 
                                                  v-model="formData.comment"
                                                  class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md" />
                                    </div>
                                    <span class="font-small text-pink-700">{{ errors['comment'] }}</span>
                                </div>

                                <div class="relative mt-3">
                                    <div class="absolute inset-0 flex items-center" aria-hidden="true">
                                        <div class="w-full border-t border-gray-300" />
                                    </div>
                                    <div class="relative flex justify-center">
                                        <span class="px-2 bg-white text-sm text-gray-500"></span>
                                    </div>
                                </div>
                                <button class="bg-pink-700 hover:bg-pink-900 text-white font-bold py-2 px-4 rounded mt-3 w-full"
                                        @click="submit()">
                                    Submit
                                </button>
                            </div>
                    </div>
                </div>
            </div>

            <div class="w-full h-48 sm:h-64 lg:absolute lg:top-0 lg:right-0 lg:w-1/2 lg:h-full">
                <img src="@/assets/aside3.jpg" alt="" class="w-full h-full object-center object-cover" />
            </div>
        </div>

    </brochure-layout>
</template>
<script>
import BrochureLayout from "@/layouts/BrochureLayout";
import axios          from "axios";

export default {
    components: {
        BrochureLayout
    },

    data() {
        return {
            api_url: process.env.VUE_APP_API_URL,
            formData: {
                name:    '',
                email:   '',
                comment: ''
            },
            errors: [],
        }
    },
    methods: {  
        submit() {
            this.error  = false;
            this.errors = [];

            axios.post(this.api_url + '/api/contact_form', this.formData).then(response => {
                this.$swal({
                    title:              '',
                    html:               'Your contact form has been submitted.',
                    confirmButtonColor: '#be185d',
                    confirmButtonText:  'Ok',
                    icon:               'success',
                    background:         '#3f3f46',
                });
                this.$router.push({name: 'homePage'})
            }).catch(error => {
                var error_msg     = '';
                var error_details = '';
                if (error.response.data.message) {
                    error_msg = error.response.data.message;	
                }
                
                if (error.response.data.errors) {
                    Object.keys(error.response.data.errors).forEach(key => {
                        error_details = error_details + error.response.data.errors[key].toString() + "<br>";

                        // load api errors into client errors
                        this.errors[key] = error.response.data.errors[key].toString();
                    });
                }

                this.$swal({
                    title:              error_msg,
                    html:               error_details,
                    confirmButtonColor: '#be185d',
                    confirmButtonText:  'Close',
                    icon:               'error',
                    background:         '#3f3f46',
                })
            });
        }
    }
}
</script>