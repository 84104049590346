<template>
  <BrochureLayout>
    <div class="bg-zinc-900">
      <page_header>
        <template v-slot:title>
          FAQ
        </template>
        <template v-slot:tag>
          You might find the answer(s) you seek here.
        </template>
      </page_header>
      <div class="max-w-7xl mx-auto overflow-hidden sm:px-6 lg:px-8 mb-3 p-10">
        <div v-for="question in questions" :key="question.name" class="p-5">
            <p class="text-sm font-medium text-pink-700 mt-4"> {{question.name}}</p>
            <p class="text-sm font-medium text-white mt-4">{{question.description}}</p>
        </div>
      </div>
      <!-- Promo Pricing-->
      <pricing-component></pricing-component>
    </div>
  </BrochureLayout>
</template>
<script>
import BrochureLayout from '../layouts/BrochureLayout'
import {StarIcon} from '@heroicons/vue/solid'
import page_header from "@/reuseable/page_header";
import pricingComponent from '@/reuseable/pricing_component.vue'

export default {
  components: {
    pricingComponent,
    BrochureLayout,
    StarIcon,
    page_header
  },
  data() {
    return {
    questions: [
            {
                name: 'Do I need to keep my login information for your site AND for CCBill?',
                description: 'Yes. Please keep your logins safe for access to the site and in the event of needing to contact www.ccbill.com',
               
            },
            {
                name: 'Do I need to keep a record of my CCBill subscription number?',
                description: 'Yes. We will always ask for your CCBill subscription number before any technical support can be given.',
                
            },
            {   name: 'Which login gives me access to the site?', 
                description: "Your login is the one you created using our sign up form, not the one you created at www.ccbill.com if different.", 
            },
            {
                name: 'How do I cancel my membership?',
                description: "We are unable to cancel memberships as we do not hold any card or payment details. You must contact www.ccbill.com with your subscription number to stop membership renewing.",
               
            },
            {
                name: 'Are images on the site downloadable?',
                description: " All photographic images can be downloaded from the site by clicking the download button",
               
            },
        {
                name: ' If I join the site, will I have access to all of your full length Videos found at your C4S Store?',
                description: "Of course not. You can check every GIF and thumbnail on our Latest Updates page to see every clip and photo set we have up there.",

            },
            {
                name: 'Can videos be downloaded and streamed?',
                description: "Yes. Videos are downloaded by clicking on the download button or streamed by clicking on the image for the video.",
               
            },
            {
                name: ' I have downloaded a video and I cannot get it to play?',
                description: "In the event of a video not playing after downloading, it may be that you are using an older version of Internet Explorer and you may have to add the text .mp4 after the file name. All other browsers are supported.",
               
            },
            {
                name: 'I have requested technical support and have not heard anything back yet?',
                description: "We endeavour to answer tech support requests within 12 hours, but depending on factors such as level of support needed, different time zones etc., these can take up to a maximum of 48 hours to be answered/read.",
               
            },
            {
                name: 'I am interested in ordering a custom video shoot, what do I do?',
                description: "Please view our custom page",
               
            },
            {
                name: 'Will my custom be exactly what I asked for in my script?',
                description: "We always try our best to accommodate everything that you ask for, but we reserve the right to deny any activity that may be deemed unsafe or unacceptable to the models or producers, or anything that does not fit the “house style”. These issues would be addressed before any payment is accepted.",
               
            },
            {
                name: 'Can I get graphic nudity in my custom?',
                description: "We generally work with the UK’s top glamour models, so as a rule, no. It would usually be topless or implied/soft nude, if required. We do, however, also work with some pornographic actresses, so this may be possible in some instances though may incur a higher custom rate.",
               
            },
            {
                name: 'Can I attend a shoot?',
                description: "No. We do not allow anyone other than cast or crew on set.",
               
            },
            {
                name: ' Do you need male models?',
                description: "No. This is a female driven website.",
               
            },
            {
                name: 'Are you looking for riggers?',
                description: "No. We have Borderland.",
               
            },
            {
                name: 'I sent in some feedback or ideas and did not receive a response?',
                description: "Although we read all correspondence with interest, we can not always reply directly because we have thousands of members, Clips4Sale Store and other customers, so must streamline all emails in accordance with their importance. We do, however, welcome constructive feedback and use to facilitate your enjoyment of the site.",
               
            },
        ]
    }
  },
  methods: {},
  created() {
  }

}
</script>
<style></style>