<template>
  <brochure-layout>


      <!-- Hero section -->
      <div class="relative">
        <div class="absolute inset-x-0 bottom-0 h-1/2 bg-zinc-900" />
        <div class="mx-auto sm:px-6 lg:px-8">
          <div class="relative shadow-xl sm:rounded-2xl sm:overflow-hidden w-full">
            <div class="absolute inset-0">
              <img class="h-full w-full object-cover" src="@/assets/header.jpg" alt="Girl bound and gagged in a chair" />
              <div class="absolute inset-0 bg-gradient-to-r from-pink-500 to-pink-500 mix-blend-multiply" />
            </div>
            <div class="relative px-4 py-16 sm:px-6 sm:py-24 lg:py-32 lg:px-8">
              <h1 class="text-center text-4xl font-extrabold tracking-tight sm:text-5xl lg:text-6xl">
                <span class="block text-white">The Most Beautiful Girls</span>
                <span class="block text-white">In Bondage</span>
              </h1>
              <p class="mt-6 max-w-lg mx-auto text-center text-xl text-white sm:max-w-3xl">The most beautiful girls in bondage are here at Borderland Bound.</p>
              <div class="mt-10 max-w-sm mx-auto sm:max-w-none sm:flex sm:justify-center">
                <div class="space-y-4 sm:space-y-0 sm:mx-auto sm:inline-grid sm:grid-cols-2 sm:gap-5">
                  <a href="/member/signup" class="flex items-center justify-center px-4 py-3 border border-transparent text-base font-medium rounded-md shadow-sm text-pink-700 bg-white hover:bg-indigo-50 sm:px-8" v-if="!loggedUser"> Sign up </a>
                  <a href="/member/home" class="flex items-center justify-center px-4 py-3 border border-transparent text-base font-medium rounded-md shadow-sm text-pink-700 bg-white hover:bg-indigo-50 sm:px-8" v-else> Members' Area </a>
                  <a href="/ecommerce/home" class="flex items-center justify-center px-4 py-3 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-pink-900 bg-opacity-60 hover:bg-opacity-70 sm:px-8"> Video Store </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Promo Pricing-->
      <pricing-component></pricing-component>


      <!-- Alternating Feature Sections -->
      <video-streaming></video-streaming>
      <!-- Stats section -->
      <membership-stats></membership-stats>
      <!-- CTA Section -->
      <div v-if="!loggedUser"  class="bg-zinc-900">
        <div class="max-w-4xl mx-auto py-16 px-4 sm:px-6 sm:py-24 lg:max-w-7xl lg:px-8 lg:flex lg:items-center lg:justify-between">
          <h2 class="text-4xl font-extrabold tracking-tight text-white sm:text-4xl">
            <span class="block">Ready to get started?</span>
            <span class="-mb-1 pb-1 block bg-gradient-to-r from-pink-700 to-pink-700 bg-clip-text text-transparent">Sign in or create an account.</span>
          </h2>
          <div class="mt-6 space-y-4 sm:space-y-0 sm:flex sm:space-x-5">
            <a href="/members/signup" class="flex items-center justify-center px-4 py-3 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-pink-700 hover:bg-pink-900"> Get started </a>
          </div>
        </div>
      </div>



  </brochure-layout>
</template>

<script>
import BrochureLayout from "@/layouts/BrochureLayout";
import VideoStreaming from "@/reuseable/video_streaming"
import MembershipStats from '@/reuseable/membership_stats'
import { Popover, PopoverButton, PopoverGroup, PopoverPanel } from '@headlessui/vue'
import {
  AnnotationIcon,
  ChatAlt2Icon,
  ChatAltIcon,
  DocumentReportIcon,
  HeartIcon,
  InboxIcon,
  FilmIcon,
  MenuIcon,
  PencilAltIcon,
  QuestionMarkCircleIcon,
  ReplyIcon,
  SparklesIcon,
  TrashIcon,
  UsersIcon,
  XIcon,
  ExternalLinkIcon
} from '@heroicons/vue/outline'
import { ChevronDownIcon } from '@heroicons/vue/solid'
import photosPromo from '@/reuseable/photos_promo.vue'
import pricingComponent from '@/reuseable/pricing_component.vue'
import {getStorage} from "@/saveStorage";
export default {
  components: {
    BrochureLayout,
    VideoStreaming,
    MembershipStats,
    Popover,
    PopoverButton,
    PopoverGroup,
    PopoverPanel,
    AnnotationIcon,
    ChatAlt2Icon,
    ChatAltIcon,
    DocumentReportIcon,
    HeartIcon,
    InboxIcon,FilmIcon,MenuIcon,PencilAltIcon,QuestionMarkCircleIcon,ReplyIcon,SparklesIcon,TrashIcon,UsersIcon,XIcon,ExternalLinkIcon,
    ChevronDownIcon, photosPromo, pricingComponent
  }, 
  computed:{
    loggedUser() {
      if(getStorage('LoggedUser')) {
        return JSON.parse(getStorage('LoggedUser'))
      } else {
        return false
      }
    },
  }
}

</script>
