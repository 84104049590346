<template>
    <BrochureLayout>
      <div class="bg-zinc-900">
        <page_header>
          <template v-slot:title>
            Complaints Policy
          </template>
          <template v-slot:tag>
          </template>
        </page_header>
        <div class="max-w-7xl mx-auto overflow-hidden sm:px-6 lg:px-8 mb-3 p-10">
            <p class="text-sm font-medium text-white mt-4">Please do not hesitate to contact us if you believe any of
              our content may be illegal or otherwise violates the Standards.</p>

                  <p class="text-sm font-medium text-white mt-4">All such reported complaints will be reviewed and
                    resolved within seven business days.</p>

                  <p class="text-sm font-medium text-white mt-4">Our team will liaise with you once a case has been
                    opened for your complaint and when our review is performed, we will state our case, with
                    evidence if applicable, should we believe you are in error.</p>

                  <p class="text-sm font-medium text-white mt-4">You may appeal our decision, and we may require further
                    information from you to support your case.
                    If, after review(s) it is found you are correct in your assessment, said content will be removed immediately.</p>

          <a href="/contact" class=" mt-2 inline-flex bg-gradient-to-r from-pink-700 to-pink-700 bg-origin-border px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white hover:from-pink-900 hover:to-pink-900">Contact us</a>

          <h2 class="text-xl font-medium text-white mt-4">Appeals Procedure</h2>

          <p class="text-sm font-medium text-white mt-4">If you have been a model for Borderland Bound and/or have been depicted in any content in which consent
          was not given or is void under applicable law, and would like to appeal removal of such content, please notify
          us by sending an email here: <a href="mailto:enquiries@borderlandbound.com" class="text-pink-700 hover:text-pink-900">enquiries@borderlandbound.com.</a></p>

          <p class="text-sm font-medium text-white mt-4">Should there be a disagreement regarding an appeal, we will allow the disagreement to be resolved by a neutral body.</p>

        </div>
        <!-- Promo Pricing-->
        <membership_stats></membership_stats>
        <pricing-component></pricing-component>
      </div>
    </BrochureLayout>
  </template>
  <script>
  import BrochureLayout from '../layouts/BrochureLayout'
  import {StarIcon} from '@heroicons/vue/solid'
  import page_header from "@/reuseable/page_header";
  import pricingComponent from '@/reuseable/pricing_component.vue'
  import membership_stats from "@/reuseable/membership_stats";
  export default {
    components: {
      pricingComponent,
      BrochureLayout,
      StarIcon,
      page_header,
      membership_stats
    },
    data() {
      return {}
    },
    methods: {},
    created() {
    }
  
  }
  </script>
  <style></style>