<template>
  <div class="bg-zinc-900">
    <div class="max-w-7xl mx-auto py-16 px-4 sm:py-24 sm:px-6 lg:px-8">
      <div class=" xl:flex xl:items-center xl:justify-between">
        <div>
          <h1 class="text-4xl font-extrabold sm:text-5xl sm:tracking-tight">
            <span class="text-white">Get instant access </span>
            <span class="text-pink-700">today</span>
          </h1>

        </div>
        <a href="/member/signup" v-if="!loggedUser" class="mt-8 w-full bg-pink-700 border border-transparent px-5 py-3 inline-flex items-center justify-center text-base font-medium rounded-md text-white hover:bg-pink-900 sm:mt-10 sm:w-auto xl:mt-0">Sign up</a>
        <a href="/member/home" v-else class="mt-8 w-full bg-pink-700 border border-transparent px-5 py-3 inline-flex items-center justify-center text-base font-medium rounded-md text-white hover:bg-pink-900 sm:mt-10 sm:w-auto xl:mt-0">Members' Area</a>

      </div>
     </div>
  </div>
</template>

<script>
import {getStorage} from "@/saveStorage";
export default {
  computed:{
    loggedUser() {
      if(getStorage('LoggedUser')) {
        return JSON.parse(getStorage('LoggedUser'))
      } else {
        return false
      }
    },
  }
}
</script>

<style>

</style>