<template>
  <BrochureLayout>
    <div class="bg-zinc-900">
      <page_header>
        <template v-slot:title>
          Latest Updates
        </template>
        <template v-slot:tag>
          Our site is updated at least 7 times per week.
        </template>
      </page_header>
      <div class="max-w-7xl mx-auto overflow-hidden sm:px-6 lg:px-8 mb-3 p-10">
        <p class=" p-5 text-center border-t-2 border-b-2 border-gray-200 text-white"> All bondage scenarios depicted
          within our productions (video & images)
          are strictly of a role play and story driven nature and feature fully
          consenting participants aged over 18 and established safe signals (clearly defined
          within each fantasy scene) between model(s) and crew are in continual
          use throughout the production. Thus, these are pure, tongue in cheek
          adventuristic fantasy scenarios, without exception fully consensual,
          contextual and supported by model release to this effect.
        </p>
      </div>
      <!-- Videos -->
      <div class="max-w-7xl mx-auto overflow-hidden sm:px-6 lg:px-8 mb-3">
        <div class="md:flex md:items-center md:justify-between mb-3 p-5">
          <div class="flex-1 min-w-0">
            <h2 class="text-2xl leading-7 text-white sm:text-3xl sm:truncate">Latest Videos</h2>
          </div>
          <div class="mt-4 flex md:mt-0 md:ml-4">

          </div>
        </div>
        <h2 class="sr-only">Products</h2>

        <div class="-mx-px border border-pink-700 rounded-lg grid grid-cols-2 sm:mx-0 md:grid-cols-3 lg:grid-cols-4">
          <div v-for="video in reverseVideos" :key="video.video_url"
               class="group relative p-4 border-r border-b border-pink-700 sm:p-6" 
               @mouseover="hovered = video.title" 
               @mouseleave="hovered = null"
               @click="show_desc(video)">
            <div v-if="use_anim_covers == 1"
                 class="rounded-lg overflow-hidden bg-zinc-900 aspect-w-1 aspect-h-1 group-hover:opacity-75">
              <img class="object-cover shadow-lg rounded-lg w-full" 
                   :src="video.cover_url"
                   :alt="videoTitle.title" />
            </div>
            <div v-else
                 class="rounded-lg overflow-hidden bg-zinc-900">
              <img class="rounded-lg" 
                   :src="hovered === video.title ? '/pixel.gif' : video.cover_thumbnail_url"
                   :alt="''" 
                   :style="'width:250px; height:140px; \
                            background: url(' + video.cover_url + ') no-repeat; \
                            background-size: cover;'" />
            </div>

            <div class="pt-10 pb-4 text-center">
              <h3 class="text-sm font-medium text-white">
                  <span aria-hidden="true" class="absolute inset-0"/>
                  {{ video.title }}
              </h3>
              <div class="mt-3 flex flex-col items-center">
              </div>
              <p class="mt-4 text-base font-medium text-gray-900"></p>
            </div>
          </div>
        </div>
        <div class="flex flex-row">
          <div class="basis-1/2">
            <p class="text-sm text-white m-2">Total Videos: {{counts.videos}}</p>
          </div>
          <div class="basis-1/2">
            <button @click="previousPage" :disabled="this.page === 1" class="ml-5 bg-pink-700 hover:bg-pink-900 text-white font-bold py-2 px-4 rounded mt-3 float-right " :class="{ 'disabled:bg-slate-50 text-pink-900' : this.page === 1}">Previous</button>

            <button @click="nextPage" class="bg-pink-700 hover:bg-pink-900 text-white font-bold py-2 px-4 rounded mt-3 float-right">Next</button>
          </div>
        </div>
      </div>

      <!-- Photo sets -->
      <div class="max-w-7xl mx-auto overflow-hidden sm:px-6 lg:px-8 mb-3 mt-4">
        <div class="md:flex md:items-center md:justify-between mb-3 p-5">
          <div class="flex-1 min-w-0">
            <h2 class="text-2xl leading-7 text-white sm:text-3xl sm:truncate">Latest Photo Sets</h2>
          </div>
          <div class="mt-4 flex md:mt-0 md:ml-4">

          </div>
        </div>
        <h2 class="sr-only">Products</h2>

        <div class="-mx-px border border-pink-700 rounded-lg grid grid-cols-2 sm:mx-0 md:grid-cols-3 lg:grid-cols-4">
          <div v-for="photoset in photosets" :key="photoset.photoset_url"
               class="group relative p-4 border-r border-b border-pink-700 sm:p-6">
            <div class="rounded-lg overflow-hidden bg-gray-200 aspect-w-1 aspect-h-1 group-hover:opacity-75">
              <img :src="photoset.cover_url" :alt="photoset.title" class="w-full h-full object-center object-cover"/>
            </div>
            <div class="pt-10 pb-4 text-center">
              <h3 class="text-sm font-medium text-white">
                <a :href="photoset.photoset_url">
                  <span aria-hidden="true" class="absolute inset-0"/>
                  {{ photoset.title }}
                </a>
              </h3>
              <div class="mt-3 flex flex-col items-center">

              </div>
              <p class="mt-4 text-base font-medium text-gray-900"></p>
            </div>
          </div>
        </div>
        <div class="flex flex-row">
          <div class="basis-1/2">
            <p class="text-sm text-white m-2">Total Photo sets: {{counts.photosets}}</p>
          </div>
          <div class="basis-1/2">
            <button @click="previousPage" 
                    :disabled="paging_ok == false  ||  page == 1" 
                    class="ml-5 bg-pink-700 hover:bg-pink-900 text-white font-bold py-2 px-4 rounded mt-3 float-right " 
                    :class="{ 'disabled:bg-slate-50 text-pink-900' : page == 1}"
            >Previous</button>

            <button @click="nextPage" 
                    :disabled="paging_ok == false" 
                    class=" bg-pink-700 hover:bg-pink-900 text-white font-bold py-2 px-4 rounded mt-3 float-right"
                    :class="{ 'disabled:bg-slate-50 text-pink-900' : paging_ok == true}"
            >Next</button>
          </div>
        </div>
      </div>
      <description-modal 
        :open="modal_open" 
        :title="modal_title" 
        :desc="modal_desc"
      ></description-modal>
    </div>
  </BrochureLayout>
</template>
<script>
import BrochureLayout from '../layouts/BrochureLayout'
import {StarIcon, XIcon} from '@heroicons/vue/solid'
import axios from "axios";
import page_header from "@/reuseable/page_header";
import { getStorage } from '@/saveStorage';
import DescriptionModal from '@/modals/description'

export default {
    components: {
        BrochureLayout,
        StarIcon,
        page_header,
        XIcon,
        DescriptionModal
    },

    data() {
        return {
            api_url:          process.env.VUE_APP_API_URL,
            use_anim_covers:  process.env.VUE_APP_USE_ANIM_COVERS,
            hovered:          null,
            videoTitle:       '',
            photosets:        [],
            videos:           [],
            counts:           [],
            page:             1,

            preload_images:   [],
            preload_covers:   [],
            preload_thumbs:   [],
            cancel_preloads:  false,
            paging_ok:        false,
            covers_loading:   0,

            modal_open:  false,
            modal_title: 'test title',
            modal_desc:  'test desc',

            // Images
            img_cancel:       'data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEAAAAALAAAAAABAAEAAAI=;'
        }
    },

    computed: {
        reverseVideos: function() {
            var reversed = [];
            for (const id in this.videos) {
                reversed.unshift(this.videos[id]);
            }
            return reversed;
        }
    },

    watch: {
        preload_covers: {
            deep: true,
            handler(n) {
                if (this.preload_covers.length > 0) {
                    this.process_preload_covers();
                }
            }
        },
        preload_thumbs: {
            deep: true,
            handler(n) {
                if (this.preload_thumbs.length > 0) {
                    this.process_preload_thumbs();
                }
                else {
                    setTimeout(function() {
                        this.paging_ok = true;
                    }, 1000);
                }
            }
        }
    },

    created() {
        this.getLatestUpdates()
        this.counts = getStorage('counts')
    },
    mounted() {
        //preload();
    },

    methods: {
        XXprocess_preload_covers() {
            var _self     = this;
            var cover     = this.preload_covers[0];
            //var cover     = this.preload_covers.shift();
            var cover_img = new Image();

            cover_img.onload = function () {
                if (_self.videos[cover.id]) {
                    _self.videos[cover.id].cover_url = cover.cover_url;
                }
                if (_self.preload_covers.length > 0) {
                    _self.preload_covers.shift();
                }
                //console.log("cover["+cover.id+"] loaded");
            }

            // request the image data
            cover_img.src = cover.cover_url;

            if (cover_img.complete) {
                if (_self.videos[cover.id]) {
                    _self.videos[cover.id].cover_url = cover.cover_url;
                }
                if (_self.preload_covers.length > 0) {
                    _self.preload_covers.shift();
                }
                //console.log("cover["+cover.id+"] complete");
            }
        },
        process_preload_covers() {
            var _self = this;

            var cover     = _self.preload_covers.shift();
            var cover_img = new Image();

            cover_img.onload = function () {
                if (_self.videos[cover.id]) {
                    _self.videos[cover.id].cover_url = cover_img.src;
                    //console.log("cover["+cover.id+"] loaded");
                }
            }

            // request the image data
            cover_img.src = _self.api_url + '/api' + cover.cover_url;

            if (cover_img.complete) {
                //console.log("cover["+cover.id+"] complete");
            }

            _self.preload_images.push(cover_img);
        },
        process_preload_thumbs() {
            var _self     = this;
            //var thumb     = this.preload_thumbs[0];
            var thumb     = this.preload_thumbs.shift();
            var thumb_img = new Image();

            thumb_img.onload = function () {
                if (_self.videos[thumb.id]) {
                    _self.videos[thumb.id].cover_thumbnail_url = thumb_img.src;
                    //console.log("thumb["+thumb.id+"] loaded");
                }
            }

            // request the image data
            thumb_img.src = _self.api_url + '/api' + thumb.cover_thumbnail_url;

            if (thumb_img.complete) {
                _self.videos[thumb.id].cover_thumbnail_url = thumb_img.cover_thumbnail_url;
                //console.log("thumb["+thumb.id+"] complete");
            }
        },

        show_desc(video) {
            this.modal_title = video.title;  
            this.modal_desc  = video.description;  
            this.modal_open  = true;
        },

        getLatestUpdates() {
            this.paging_ok = false;

            axios.get(this.api_url + '/api/recent_updates?page='+ this.page).then(response => {
                this.photosets      = response.data.records.photosets;
                this.videos         = response.data.records.videos;

                var t  = JSON.stringify(response.data.records.preloads);
                this.preload_thumbs = JSON.parse(t);
                this.preload_covers = JSON.parse(t);

                if (Object.keys(this.videos).length > 0) {
                    for (const id in this.videos) {
                        this.videos[id].cover_url = '/spinner.gif';
                        this.videos[id].cover_thumbnail_url = '/spinner.gif';
                    }
                }
            });
        },

        nextPage() {
            this.paging_ok = false;

            // Clear current preloads
            if (Object.keys(this.preload_images).length > 0) {
                for (const id in this.preload_images) {
                    this.preload_images[id].src = '';
                }
            }
            this.preload_images = [];


            this.page = this.page+1;
            axios.get(this.api_url + '/api/recent_updates?page=' + this.page).then(response => {
                this.photosets = response.data.records.photosets;
                this.videos    = response.data.records.videos;

                var t  = JSON.stringify(response.data.records.preloads);
                this.preload_thumbs = JSON.parse(t);
                this.preload_covers = JSON.parse(t);

                if (Object.keys(this.videos).length > 0) {
                    for (const id in this.videos) {
                        this.videos[id].cover_url = '/spinner.gif';
                        this.videos[id].cover_thumbnail_url = '/spinner.gif';
                    }
                }
            });
        },

        previousPage() {
            this.paging_ok = false;

            this.page = this.page-1;
            axios.get(this.api_url + '/api/recent_updates?page=' + this.page).then(response => {
                this.photosets = response.data.records.photosets;
                this.videos    = response.data.records.videos;

                var t  = JSON.stringify(response.data.records.preloads);
                this.preload_thumbs = JSON.parse(t);
                this.preload_covers = JSON.parse(t);

                if (Object.keys(this.videos).length > 0) {
                    for (const id in this.videos) {
                        this.videos[id].cover_url = '/spinner.gif';
                        this.videos[id].cover_thumbnail_url = '/spinner.gif';
                    }
                }
            });
        },
    }
}
</script>
<style>
</style>