<template>
    <form-layout>
        <div class="relative px-4 py-16 sm:px-6 sm:py-24 lg:py-32 lg:px-8">
            <div class="sm:mx-auto sm:w-full sm:max-w-md">
                <img  class="mx-auto h-24 w-auto"  src="@/assets/logo.png"  />
                <p class="mt-2 text-center text-sm text-white">&nbsp;Reset Password Request</p>
            </div>
            <div class="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
                <div class="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
                    <div class="space-y-6">
                        <!-- Email -->
                        <div>
                            <label for="email" class="block text-sm font-medium text-gray-700">Email</label>
                            <div class="mt-1">
                                <input  type="text"  name="email"  id="email"
                                        v-model="formData.email"
                                        class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block
                                            w-full sm:text-sm border-gray-300 rounded-md" 
                                        placeholder="Email Address"
                                        :disabled="form_sent == true" />
                                <span class="font-small text-pink-800">{{ errors['email'] }}</span>
                            </div>
                        </div>


                        <!-- Message -->
                        <div v-if="form_sent == false"
                             class="flex items-center justify-between">
                            <div class="flex items-center">
                                <div class="text-sm">
                                    <div class="font-medium text-grey-500">
                                        Please enter the email address for the account you wish to reset the password. 
                                        An email will be sent to the entered address if it is on our system.
                                    </div>
                                    <div class="mt-4 mb-1 font-medium text-grey-500">
                                        Forgot your email address?<br>
                                        <span class="font-medium text-grey-500">
                                            Use the <a href="/contact" class="text-pink-700 hover:text-pink-900">Contact Form</a> to get in touch.
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <!-- Submit Button -->
                        <div v-if="form_sent == false">
                            <button  type="submit"
                                    @click="submitForm"
                                    class="w-full flex justify-center py-2 px-4 border border-transparent rounded-md
                                        shadow-sm text-sm font-medium text-white bg-pink-700 
                                        hover:bg-pink-900
                                        focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                                Continue
                            </button>
                        </div>
                        <div v-else
                             class="mt-2 font-medium text-grey-500">
                            <b>Email Sent</b><br>
                            An email will be sent to the email address provided, with instructions on how to reset your password.
                            <br>&nbsp;
                            <br>
                            <a href="/"
                               class="w-full flex justify-center py-2 px-4 border border-transparent rounded-md
                                      shadow-sm text-sm font-medium text-white bg-pink-700 
                                      hover:bg-pink-900
                                      focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                                Home
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </form-layout>
</template>

<script>
import { useStore }    from "vuex";
import alertError      from '@/alerts/error.vue'
import axios           from "axios";
import { saveStorage } from "@/saveStorage";
import formLayout      from '@/layouts/formLayout'

export default {
    data() {
        return {
            api_url:   process.env.VUE_APP_API_URL,
            formData: {
                email: ''
            },
            store:     useStore(),
            form_sent: false,
            errors:    [],
        };
    },
    components: {
        alertError,
        formLayout
    },
    methods: {
        submitForm() {
            this.errors = [];
            
            axios.post(this.api_url + '/api/forgot', this.formData)
            .then(response => {
                this.error     = false;
                this.form_sent = true;
            })
            .catch(error => {
                var error_msg     = '';
                var error_details = '';

                if (error.response.data.message) {
                    error_msg = error.response.data.message;	
                }
        
                if (error.response.data.errors) {
                    Object.keys(error.response.data.errors).forEach(key => {
                        error_details = error_details + error.response.data.errors[key].toString() + "<br>";

                        // load api errors into client errors
                        this.errors[key] = error.response.data.errors[key].toString();
                    });
                }

                this.$swal({
                    title:              error_msg,
                    html:               error_details,
                    confirmButtonColor: '#be185d',
                    confirmButtonText:  'Close',
                    icon:               'error',
                    background:         '#3f3f46',
                });
            });
        }
    }
};
</script>

<style>
</style>