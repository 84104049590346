<template>
  <form-layout>
    <div class="relative px-4 py-16 sm:px-6 sm:py-24 lg:py-32 lg:px-8">
          <div class="sm:mx-auto sm:w-full sm:max-w-md">
            <img
                class="mx-auto h-24 w-auto"
                src="@/assets/logo.png"
                alt="Workflow"
            />
            <p class="mt-2 text-center text-sm text-white">
              {{ " " }}

              Please, verify your age to enter.

            </p>
          </div>
          <div class="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
            <div class="bg-zinc-700 py-8 px-4 shadow sm:rounded-lg sm:px-10">
              <div class="space-y-6">
                <!-- Birth Day -->
                <div>
                  <label
                      for="dayOfBirth"
                      class="block text-sm font-medium text-white"
                  >Birth day</label
                  >
                  <div class="mt-1">
                    <select
                        id="dayOfBirth"
                        name="dayOfBirth"
                        v-model="birthDay"
                        class="
                  mt-1
                  block
                  w-full
                  pl-3
                  pr-10
                  py-2
                  text-base
                  border-gray-300
                  focus:outline-none
                  focus:ring-indigo-500
                  focus:border-indigo-500
                  sm:text-sm
                  rounded-md
                "
                    >
                      <option value="Please Select" selected>Please Select</option>
                      <option v-for="day in birth_days" :key="day" :value="day">{{ day }}</option>
                    </select>
                  </div>
                </div>

                <!-- Birth Month -->
                <div>
                  <label
                      for="monthOfBirth"
                      class="block text-sm font-medium text-white"
                  >Birth Month</label
                  >
                  <div class="mt-1">
                    <select
                        id="monthOfBirth"
                        name="monthOfBirth"
                        v-model="birthMonth"
                        class="
                  mt-1
                  block
                  w-full
                  pl-3
                  pr-10
                  py-2
                  text-base
                  border-gray-300
                  focus:outline-none
                  focus:ring-indigo-500
                  focus:border-indigo-500
                  sm:text-sm
                  rounded-md
                "
                    >
                      <option value="Please Select" selected>Please Select</option>
                      <option v-for="month in birth_months" :key="month" :value="month">{{ month }}</option>
                    </select>
                  </div>
                </div>

                <!-- Birth Year -->
                <div>
                  <label
                      for="yearOfBirth"
                      class="block text-sm font-medium text-white"
                  >Birth year</label
                  >
                  <div class="mt-1">
                    <select
                        id="yearOfBirth"
                        name="yearOfBirth"
                        v-model="birthYear"
                        class="
                  mt-1
                  block
                  w-full
                  pl-3
                  pr-10
                  py-2
                  text-base
                  border-gray-300
                  focus:outline-none
                  focus:ring-indigo-500
                  focus:border-indigo-500
                  sm:text-sm
                  rounded-md
                "
                    >
                      <option value="Please Select" selected>Please Select</option>
                      <option v-for="year in years" :key="year">{{year}}</option>
                    </select>
                  </div>
                </div>

                <!-- Message -->
                <div class="flex items-center justify-between">
                  <div class="flex items-center">
                    <div class="text-sm">
                <span class="font-medium text-pink-500">
                  You must be 18 years old to enter this site
                </span>
                    </div>
                  </div>
                </div>
                <!-- Submit Button -->
                <div>
                  <button
                      type="submit"
                      class="
                w-full
                flex
                justify-center
                py-2
                px-4
                border border-transparent
                rounded-md
                shadow-sm
                text-sm
                font-medium
                text-white
                bg-pink-700
                hover:bg-pink-500
                focus:outline-none
                focus:ring-2
                focus:ring-offset-2
                focus:ring-pink-500
              "
                      @click="submit"
                  >
                    Continue
                  </button>
                </div>
              </div>
            </div>
          </div>
    </div>
    <disclaimer-modal :open="open"></disclaimer-modal>
  </form-layout>
</template>

<script>
import axios from "axios";
import DisclaimerModal from '@/modals/disclaimer'
import formLayout from "@/layouts/formLayout";
import { saveStorage } from "@/saveStorage";
export default {
  components: {
    DisclaimerModal,
    formLayout
  },
  data() {
    return {
      birthYear: 'Please Select',
      birthDay: 'Please Select',
      birthMonth: 'Please Select',
      birth_days: [
        1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31
      ],
      birth_months: [
        '01',
        '02',
        '03',
        '04',
        '05',
        '06',
        '07',
        '08',
        '09',
        '10',
        '11',
        '12'
      ],
      years: [],
      open: false,
      api_url: process.env.VUE_APP_API_URL,
    }
  },
  methods: {
    submit() {
      let date = this.birthYear + '-' + this.birthMonth + '-' + this.birthDay.toString().padStart(2, '0')
      let data = {
        'date_of_birth': date
      }
      axios.post(this.api_url + '/api/log_dob_ip', data).then(response => {
        saveStorage('dobCheck', true)
        this.open = true
      }).catch(e => {
        this.$swal({
          title: 'Error!',
          text: 'Error Processing date of birth',
          confirmButtonColor: '#be185d',
          confirmButtonText: 'Close',
          icon: 'error',
          background: '#3f3f46',
        })
      })
      
    },
    generateBirthYears() {
      let date = new Date().getFullYear()
      let max = date - 18
      let min = max - 80
      for (let i = max; i >= min; i--) {
        this.years.push(i)
      }
    },
  },
  mounted() {
    this.generateBirthYears()
  }
};
</script>

<style>
  .swal2-html-container, .swal2-title{
  color: white !important;
}
</style>