<!-- This example requires Tailwind CSS v2.0+ -->
<template>
  <TransitionRoot as="template" :show="open">
    <Dialog as="div" class="relative z-10" @close="open = false">
      <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
        <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
      </TransitionChild>

      <div class="fixed z-10 inset-0 overflow-y-auto">
        <div class="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
          <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200" leave-from="opacity-100 translate-y-0 sm:scale-100" leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
            <DialogPanel class="relative bg-zinc-900 rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-4xl sm:w-full sm:p-6">

                <div class="mt-3 text-center sm:mt-5">
                  <DialogTitle as="h3" class="text-lg leading-6 font-medium text-white">{{videoTitle}}</DialogTitle>
                  <div class="mt-2">
                    <div class="lg:grid lg:items-start lg:gap-x-8">
          <!-- Image gallery -->
          <TabGroup as="div" class="flex flex-col-reverse">
            <!-- Image selector -->
            <div class="mx-auto mt-6 hidden w-full max-w-2xl sm:block lg:max-w-none">
              <TabList class="grid grid-cols-4 gap-6">
                <Tab v-for="image in images" class="relative flex h-24 cursor-pointer items-center justify-center rounded-md bg-white text-sm font-medium uppercase text-gray-900 hover:bg-gray-50 focus:outline-none focus:ring focus:ring-opacity-50 focus:ring-offset-4" v-slot="{ selected }">
                  <span class="absolute inset-0 overflow-hidden rounded-md">
                    <img :src="image.photo_url" alt="" class="h-full w-full object-cover object-center" />
                  </span>
                  <span :class="[selected ? 'ring-indigo-500' : 'ring-transparent', 'pointer-events-none absolute inset-0 rounded-md ring-2 ring-offset-2']" aria-hidden="true" />
                </Tab>
              </TabList>
            </div>

            <TabPanels class="aspect-w-1 aspect-h-1 w-full">
              <TabPanel v-for="image in images" :key="image.thumbnail_url">
                <img :src="image.photo_url" :alt="image.thumbnail_url" class="h-full w-full object-cover object-center sm:rounded-lg" />
              </TabPanel>
            </TabPanels>
          </TabGroup>
                </div>
              </div>
              </div>
              <div class="mt-5 sm:grid-flow-row-dense">
                <a :href="this.download_link" type="button" class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-pink-700 text-base font-medium text-white hover:bg-pink-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pink-900 sm:col-start-2 sm:text-sm mb-3" >Download</a>
                <button type="button" class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-pink-700 text-base font-medium text-white hover:bg-pink-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pink-900 sm:col-start-2 sm:text-sm" @click="open = false">Close</button>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script>
import { Dialog, DialogPanel, DialogTitle, Tab,
  TabGroup,
  TabList,
  TabPanel,
  TabPanels, TransitionChild, TransitionRoot, } from '@headlessui/vue'
import { CheckIcon } from '@heroicons/vue/outline'
import axios from "axios";

export default {
  components: {
    Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot, CheckIcon, Tab, TabList, TabPanel, TabGroup, TabPanels
  },
  props: ['open', 'videoLink', 'videoTitle', 'auth', 'id'],
  data() {
    return {
      api_url: process.env.VUE_APP_API_URL,

      images: {},
      selected: '',
      download_link: ''
    }
  },
  methods: {
    getImageSet() {
      const config = {
         headers: { Authorization: `Bearer ` + this.auth.token.replace(/^"(.+(?="$))"$/, '$1') }
      };

      const bodyParameters = {
         key: "value"
      };
      axios.get(this.api_url + '/api' + this.videoLink, config).then(response => {
        //console.log(response.data)
        this.images = response.data.records
        this.download_link = response.data.photoset.download_url
      })
    },
    downloadImageSet() {
      const config = {
         headers: { Authorization: `Bearer ` + this.auth.token.replace(/^"(.+(?="$))"$/, '$1') }
      };

      const bodyParameters = {
         key: "value"
      };
      axios.get(this.api_url + '/api/photosets/' + this.id + "/download", config).then(response => {
        let url = window.URL.createObjectURL(new Blob([response]));
        const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'images.zip');
            document.body.appendChild(link);
            link.click()
      })
    }
  },
  watch: {
    videoLink() {
      this.getImageSet()
    }
  }
}
</script>