<template>
  <div class="bg-zinc-900">
    <!-- Header -->
    <header>
      <Popover class="relative bg-zinc-900">
        <div
            class="flex justify-between items-center max-w-7xl mx-auto px-4 py-6 sm:px-6 md:justify-start md:space-x-10 lg:px-8">
          <div class="flex justify-start lg:w-0 lg:flex-1">
            <a :href="'/home'">
              <img
                  class="mx-auto h-12 w-auto"
                  src="@/assets/logo.png"
                  alt="Borderland Bound"
              />
            </a>
          </div>
          <div class="-mr-2 -my-2 md:hidden">
            <PopoverButton
                class="bg-zinc-900 rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-pink-700 hover:bg-zinc-900 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-pink-900">
              <span class="sr-only">Open menu</span>
              <MenuIcon class="h-6 w-6" aria-hidden="true"/>
            </PopoverButton>
          </div>
          <PopoverGroup as="nav" class="hidden md:flex space-x-10">


            <a :href="'/about'" class="text-base font-medium text-white hover:text-pink-700"> About </a>
            <a :href="'/latest-updates'" class="text-base font-medium text-white hover:text-pink-700">Latest
              Updates</a>
            <a :href="'/customs'" class="text-base font-medium text-white hover:text-pink-700"> Customs </a>
            <a :href="'/ecommerce/home'" class="text-base font-medium text-white hover:text-pink-700"> Store </a>
            <a :href="'/faq'" class="text-base font-medium text-white hover:text-pink-700"> FAQ </a>
            <a href="/contact" class="text-base font-medium text-white hover:text-pink-700"> Contact </a>
            <Popover class="relative" v-slot="{ open }">
              <PopoverButton
                  :class="['group text-white rounded-md inline-flex items-center text-base font-medium hover:text-pink-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-white']">
                <span>Links</span>
                <ChevronDownIcon
                    :class="[open ? 'text-pink-700' : 'text-white', 'ml-2 h-5 w-5 group-hover:text-gray-500']"
                    aria-hidden="true"/>
              </PopoverButton>

              <transition enter-active-class="transition ease-out duration-200"
                          enter-from-class="opacity-0 translate-y-1" enter-to-class="opacity-100 translate-y-0"
                          leave-active-class="transition ease-in duration-150"
                          leave-from-class="opacity-100 translate-y-0" leave-to-class="opacity-0 translate-y-1">
                <PopoverPanel
                    class="absolute z-10 -ml-4 mt-3 transform w-screen max-w-md lg:max-w-2xl lg:ml-0 lg:left-1/2 lg:-translate-x-1/2">
                  <div class="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden">
                    <div class="relative grid gap-6 bg-white px-5 py-6 sm:gap-8 sm:p-8 lg:grid-cols-2 bg-zinc-900">
                      <a v-for="item in solutions" :key="item.name" :href="item.href" target="_blank"
                         class="-m-3 p-3 flex items-start rounded-lg hover:bg-pink-700">
                        <div
                            class="flex-shrink-0 flex items-center justify-center h-10 w-10 rounded-md bg-gradient-to-r from-pink-700 to-pink-700 text-white sm:h-12 sm:w-12">
                          <component :is="item.icon" class="h-6 w-6" aria-hidden="true"/>
                        </div>
                        <div class="ml-4">
                          <p class="text-base font-medium text-white">
                            {{ item.name }}
                          </p>
                        </div>
                      </a>
                    </div>
                  </div>
                </PopoverPanel>
              </transition>
            </Popover>
          </PopoverGroup>
          <div class="hidden md:flex items-center justify-end md:flex-1 lg:w-0" v-if="!this.loggedUser">
            <a :href="'/member/signin'"
               class="whitespace-nowrap text-base font-medium text-white hover:text-pink-700"> Sign in </a>
            <a href="/member/signup"
               class="ml-8 whitespace-nowrap inline-flex items-center justify-center bg-gradient-to-r from-pink-700 to-pink-700 bg-origin-border px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white hover:from-pink-900 hover:to-pink-900">
              Sign up </a>
          </div>
          <div class="hidden md:flex items-center justify-end md:flex-1 lg:w-0" v-else>
            <a :href="'/member/home'"
               class="whitespace-nowrap text-base font-medium text-white hover:text-pink-700"> View and Download </a>

          </div>
        </div>

        <transition enter-active-class="duration-200 ease-out" enter-from-class="opacity-0 scale-95"
                    enter-to-class="opacity-100 scale-100" leave-active-class="duration-100 ease-in"
                    leave-from-class="opacity-100 scale-100" leave-to-class="opacity-0 scale-95">
          <PopoverPanel focus class="absolute z-30 top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden">
            <div class="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-zinc-900 divide-y-2 divide-gray-50">
              <div class="pt-5 pb-6 px-5">
                <div class="flex items-center justify-between">
                  <div>
                    <img class="h-8 w-auto"
                         src="@/assets/dark-logo.png"
                         alt="Workflow"/>
                  </div>
                  <div class="-mr-2">
                    <PopoverButton
                        class="bg-zinc-900 rounded-md p-2 inline-flex items-center justify-center text-pink-700 hover:text-pink-900 hover:bg-zinc-900 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-pink-700">
                      <span class="sr-only">Close menu</span>
                      <XIcon class="h-6 w-6" aria-hidden="true"/>
                    </PopoverButton>
                  </div>
                </div>
                <div class="mt-6">
                  <nav class="grid grid-cols-1 gap-7">
                    <a v-for="item in solutions" :key="item.name" :href="item.href"
                       class="-m-3 p-3 flex items-center rounded-lg hover:bg-zinc-900">
                      <div
                          class="flex-shrink-0 flex items-center justify-center h-10 w-10 rounded-md bg-pink-700 text-white">
                        <component :is="item.icon" class="h-6 w-6" aria-hidden="true"/>
                      </div>
                      <div class="ml-4 text-base font-medium text-white hover:text-pink-700">
                        {{ item.name }}
                      </div>
                    </a>
                  </nav>
                </div>
              </div>
              <div class="py-6 px-5">
                <div class="grid grid-cols-2 gap-4">
                  <a :href="'/about'" class="text-base font-medium text-white hover:text-pink-700"> About </a>
                  <a :href="'/latest-updates'" class="text-base font-medium text-white hover:text-pink-700">Latest
                    Updates</a>
                  <a :href="'/customs'" class="text-base font-medium text-white hover:text-pink-700"> Customs </a>
                  <a :href="'/ecommerce/home'" class="text-base font-medium text-white hover:text-pink-700"> Store </a>
                  <a :href="'/faq'" class="text-base font-medium text-white hover:text-pink-700"> FAQ </a>
                  <a href="/contact" class="text-base font-medium text-white hover:text-pink-700"> Contact </a>
                </div>
                <div class="mt-6" v-if="!this.loggedUser">
                  <a :href="'/member/signin'"
                       class="whitespace-nowrap text-base font-medium text-white hover:text-pink-700"> Sign in </a>
                    <a href="/member/signup"
                       class="ml-8 whitespace-nowrap inline-flex items-center justify-center bg-gradient-to-r from-pink-700 from-pink-700 bg-origin-border px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white hover:from-pink-900 hover:to-pink-900">
                      Sign up </a>
                </div>
                <div class="mt-6" v-else>
                    <a :href="'/member/home'"
                       class="whitespace-nowrap text-base font-medium text-white hover:text-gray-900"> View and Download </a>

                </div>
              </div>
            </div>
          </PopoverPanel>
        </transition>
      </Popover>
    </header>

    <!-- Main Content -->
    <main>
      <slot></slot>
    </main>

    <!-- footer -->
    <footer class="bg-zinc-900" aria-labelledby="footer-heading">
      <h2 id="footer-heading" class="sr-only">Footer</h2>
      <div class="max-w-7xl mx-auto pt-16 pb-8 px-4 sm:px-6 lg:pt-24 lg:px-8">
        <div class="xl:grid xl:grid-cols-3 xl:gap-8">
          <div class="grid grid-cols-2 gap-8 xl:col-span-2">
            <div class="md:grid md:grid-cols-2 md:gap-8">
              <div>
                <h3 class="text-sm font-semibold text-pink-700 tracking-wider uppercase">Pages</h3>
                <ul role="list" class="mt-4 space-y-4">
                  <li v-for="item in footerNavigation.solutions" :key="item.name">
                    <a :href="item.href"  class="text-base text-white hover:text-pink-900">
                      {{ item.name }}
                    </a>
                  </li>
                </ul>
              </div>
              <div class="mt-12 md:mt-0">
                <h3 class="text-sm font-semibold text-pink-700 tracking-wider uppercase">Support</h3>
                <ul role="list" class="mt-4 space-y-4">
                  <li v-for="item in footerNavigation.support" :key="item.name">
                    <a :href="item.href"  class="text-base text-white hover:text-pink-900">
                      {{ item.name }}
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div class="md:grid md:grid-cols-2 md:gap-8">
              <div class="mt-12 md:mt-0">
                <h3 class="text-sm font-semibold text-pink-700 tracking-wider uppercase">Legal</h3>
                <ul role="list" class="mt-4 space-y-4">
                  <li v-for="item in footerNavigation.legal" :key="item.name">
                    <a :href="item.href" class="text-base text-white hover:text-pink-900">
                      {{ item.name }}
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="mt-12 xl:mt-0"  v-if="!this.loggedUser">
            <h3 class="text-sm font-semibold text-white tracking-wider uppercase">Join Borderland Bound Today</h3>

            <div class="mt-5 rounded-md sm:mt-0 sm:flex-shrink-0">
                <a href="/member/signup"
                        class=" mt-5 w-full flex items-center justify-center bg-gradient-to-r from-pink-700 to-pink-700 bg-origin-border px-4 py-3 border border-transparent text-base font-medium rounded-md shadow-sm text-white hover:from-pink-900 hover:to-pink-900">
                  Join Now
              </a>
              </div>
           
          </div>
        </div>
        <div class="mt-12 border-t border-gray-200 pt-8 md:flex md:items-center md:justify-between lg:mt-16">
          <div class="flex space-x-6 md:order-2">

          </div>
              <p class="mt-8 text-base text-gray-400 md:mt-0 md:order-1">&copy; 2022 Borderland Bound. All images and videos are owned by the copyright holder and any redistribution/reproduction of our copyrighted content is prohibited unless expressly authorised.</p>

          </div>
        <div class="text-center">
            <p class="text-gray-400">PARENTS! USE THESE SITES TO FILTER ADULT CONTENT!</p>
            <p><span v-for="link in footerNavigation.safety"><a :href="link.href" target="_blank" class="text-pink-700 hover:text-pink-900">{{link.name}}</a></span></p>
            <p class="text-gray-400">Designed and Created by <a href="enceladusdigital.com" target="_blank" class="text-pink-700 hover:text-pink-900">Enceladus Software	&trade;</a></p>
        </div>
      </div>
    </footer>
  </div>
</template>
<script>
import {Popover, PopoverButton, PopoverGroup, PopoverPanel} from '@headlessui/vue'
import {ExternalLinkIcon, MenuIcon} from "@heroicons/vue/outline";
import {ChevronDownIcon, ShoppingCartIcon} from '@heroicons/vue/solid'
import {getStorage} from "@/saveStorage";
import axios from "axios";

export default {
  components: {
    Popover, PopoverButton, PopoverGroup, PopoverPanel, ExternalLinkIcon, ChevronDownIcon, MenuIcon, ShoppingCartIcon
  },
  data() {
    return {
      solutions: [
        {
          name: 'Clips 4 Sale',
          description: 'Borderland Bounds premium content all at your fingertips',
          href: 'https://www.clips4sale.com/studio/64171/borderland-bound-',
          icon: ExternalLinkIcon,
        },
        {
          name: 'Many Vids',
          description: 'Bondage Producer ferociously dedicated to tightly binding and gagging all the most beautiful ladies!',
          href: 'https://www.manyvids.com/Profile/1004966652/BorderlandBound/Store/Videos/',
          icon: ExternalLinkIcon,
        },
        {
          name: 'Loyal Fans',
          description: "Subscribe and get behind the scenes footage and much more",
          href: 'https://www.loyalfans.com/borderland',
          icon: ExternalLinkIcon
        },
        {
          name: 'I Want Clips',
          description: "We're the ones who get all the ridiculously hot babes tightly bound, gagged and struggling intensely for your exquisite pleasure.",
          href: 'https://iwantclips.com/store/1034419/Borderland-Bound',
          icon: ExternalLinkIcon,
        },
        {
          name: 'Twitter',
          description: "We're the ones who get all the ridiculously hot babes tightly bound, gagged and struggling intensely for your exquisite pleasure.",
          href: 'https://twitter.com/Borderland7',
          icon: ExternalLinkIcon,
        },
      ],
      footerNavigation: {
        solutions: [
          {name: 'Home', href: '/'},
          {name: 'About', href: '/About'},
          {name: 'Customs', href: '#'},
          {name: 'Latest Updates', href: '/latest-updates'},
        ],
        support: [
          {name: 'Contact us', href: '/contact'},
          {name: 'FAQ', href: '/faq'},
        ],
        legal: [
          {name: 'Privacy', href: '/privacy'},
          {name: 'Terms', href: '/terms'},
          {name: 'USC 2257', href: '/usc'},
          {name: 'Complaints Policy', href: '/complaints '},
        ],
        safety: [
          {name: 'FOSI | ', href: 'https://www.fosi.org/'},
          {name: 'Cyber Patrol | ', href: 'https://www.cyberpatrol.com/'},
          {name: 'Safe Surf | ', href: 'https://www.safesurf.com/'},
          {name: 'Net Nanny | ', href: 'https://www.netnanny.com/'},
          {name: 'Cyber Sitter | ', href: 'https://www.27labs.com/'},
        ],
      },
      auth: {}
    }
  },
  computed: {
    loggedUser() {
      if(getStorage('LoggedUser')) {
        return JSON.parse(getStorage('LoggedUser'))
      } else {
        return false
      }
    },
    shoppingCart() {
      if(getStorage('cart')) {
        return getStorage('cart')
      }
    }
  },
  methods: {},
  created() {
  }

}
</script>
<style></style>