<template>
<AdminLayout>

  <div class="md:flex md:items-center md:justify-between p-5 bg-white rounded-t-lg">
    <div class="flex-1 min-w-0">
      <h2 class="text-2xl leading-7 text-blue-600 sm:text-3xl sm:truncate">All Videos</h2>
    </div>
    <div class="mt-4 flex md:mt-0 md:ml-4">
      <p class="mt-2 pr-2 text-indigo-600 font-bold">Page {{videoPag.page}} of {{videoPag.page_count}}</p>
      <button type="button" class="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-gray-600 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-indigo-500" @click="getLastPage">Previous</button>
      <button type="button" class="ml-3 inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-500 hover:bg-indigo-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-indigo-500" @click="getNextPage">Next</button>
    </div>
  </div>

  <div class="bg-white shadow overflow-hidden sm:rounded-md">
    <ul role="list" class="divide-y divide-gray-200">
      <li v-for="(video, index) in videos" :key="video.id">
          <div class="flex items-center px-4 py-4 sm:px-6">
            <div class="min-w-0 flex-1 flex items-center">
                <div class="flex-shrink-0">
                    <a :href="'/admin/video/edit?id=' + video.id" class="block hover:bg-gray-50">
                        <img class="h-12 w-12" :src="video.cover_thumbnail_url" alt />
                    </a>
                </div>
                <div class="min-w-0 flex-1 px-4 md:grid md:grid-cols-2 md:gap-4">
                    <div>
                        <a :href="'/admin/video/edit?id=' + video.id" class="block hover:bg-gray-50">
                        <p
                            class="text-sm font-medium text-blue-600 truncate"
                        >{{ video.title }}</p>
                        <p class="mt-2 flex items-center text-sm text-gray-500">
                        </p>
                        </a>
                    </div>
                </div>
                <div class="min-w-0 flex-1 px-4 md:grid md:grid-cols-2 md:gap-4">
                    <div class="text-sm  md:block" style="width:140px;">
                        Uploaded: <Datepicker v-model="video_dates[index]" 
                                    @update:modelValue="updateDates(index)"
                                    :enableTimePicker="false"  :clearable="false" 
                                    :format="'yyyy-MM-dd'"
                                    />
                    </div>
                </div>
                <div class="min-w-0 flex-1 px-4 md:grid md:grid-cols-2 md:gap-4">
                    <div class="hidden md:block">
                        <div>
                            <input type=checkbox 
                                    :checked="video.is_hidden == false"
                                    @click="toggle_hidden(video)" /> Show
                        </div>
                    <div>
                    <!-- p class="text-sm text-gray-900">
                      Added on <time :datetime="video.created_at">{{ video.created_at }}</time>
                    </p -->
                    <p v-if="parseFloat(video.price) > 0" class="mt-2 flex items-center text-sm text-gray-500">
                      <CheckCircleIcon
                        class="flex-shrink-0 mr-1.5 h-5 w-5 text-green-400"
                        aria-hidden="true"
                      />
                      Store Video | Price: {{ (parseFloat(video.price) / 100).toFixed(2) }}
                    </p>
                    <p v-else class="mt-2 flex items-center text-sm text-gray-500">
                      <BanIcon
                        class="flex-shrink-0 mr-1.5 h-5 w-5 text-red-400"
                        aria-hidden="true"
                      />
                      Non-Store
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
      </li>
    </ul>
  </div>
</AdminLayout>
</template>

<script>
import {
  CheckCircleIcon,
  ChevronRightIcon,
  MailIcon,
  BanIcon,
  TrashIcon
} from "@heroicons/vue/solid";
import AdminLayout from "@/layouts/adminLayout.vue";
import {getStorage} from "@/saveStorage";
import axios from "axios";
import Datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';

export default {
  components: {
    CheckCircleIcon,
    ChevronRightIcon,
    BanIcon,
    MailIcon,
    AdminLayout,
    TrashIcon,
    Datepicker
  },
  data() {
    return {
      api_url: process.env.VUE_APP_API_URL,
    
      videos: [],
      video_dates: [],
      videoPag: 0,
      token: null,
      search: '',
    };
  },

  created() {
    this.token = getStorage('token');
    this.search = this.$route.query.search;

    this.getAllVideos();
  },
  mounted() {
  },

  methods: {
    updateDates(index) {
        console.log('update dates for', this.videos[index]);

        const config = {
            headers: { Authorization: `Bearer ` + this.token }
        };
        
        axios.post(this.api_url + '/api/admin/videos/' + this.videos[index].id + '/date', {
            'date': this.video_dates[index].toISOString().slice(0, 10) 
        }, config).then(response => {
            console.log(response.data)
        });
    },

    toggle_hidden(media) {
      const config = {
        headers: { Authorization: `Bearer ` + this.token }
      };

      let toggle = 'hide';
      if (media.is_hidden == true) {
        toggle = 'unhide';
      }

      axios.post(this.api_url + '/api/admin/videos/' + media.id + '/' + toggle, {}, config).then(response => {
        //console.log(response.data)
      });
    },

    async getAllVideos() {
      const config = {
        headers: { Authorization: `Bearer ` + this.token }
      };

      const bodyParameters = {
         key: "value"
      };

      var url = this.api_url + '/api/admin/videos';
            url = url + '?q=' + (this.search || '');
            url = url + '&page=1';

      await axios.get(url, config).then(response => {
        this.videos = response.data.records
        this.videoPag = response.data.pagination
        this.search = response.data.search.q

        this.video_dates = [];
        for (var i = 0; i < this.videos.length; i++) {
            this.video_dates[i] = this.videos[i].created_at.slice(0, 10);
        }
      })
    },

    getNextPage() {
      const config = {
        headers: { Authorization: `Bearer ` + this.token }
      };

      const bodyParameters = {
         key: "value"
      };
      
      var page = parseInt(this.videoPag.page) + 1;
      if (page > this.videoPag.page_count) {
          page = this.videoPag.page_count;
      }

      var url = this.api_url + '/api/admin/videos';
            url = url + '?q=' + (this.search || '');
            url = url + '&page=' + page;

      axios.get(url, config).then(response => {
        this.videos = response.data.records
        this.videoPag = response.data.pagination
        this.search = response.data.search.q
      })
    },
    
    getLastPage() {
      const config = {
         headers: { Authorization: `Bearer ` + this.token }
      };

      const bodyParameters = {
         key: "value"
      };

      var page = parseInt(this.videoPag.page) - 1;
      if (page < 1) {
          page = 1;
      }

      var url = this.api_url + '/api/admin/videos';
            url = url + '?q=' + (this.search || '');
            url = url + '&page=' + page;

      axios.get(url, config).then(response => {
        this.videos = response.data.records
        this.videoPag = response.data.pagination
        this.search = response.data.search.q
      })
    },
  },
};
</script>