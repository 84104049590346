<template>
  <members-layout>
    <div>
      <div>
        <img class="h-32 w-full object-cover lg:h-48" src="@/assets/profilebackground.jpg" alt=""/>
      </div>
      <div class="max-w-5xl mx-auto px-4 sm:px-6 lg:px-8">
        <div class="-mt-12 sm:-mt-16 sm:flex sm:items-end sm:space-x-5">
          <div class="flex">
            <img class="h-24 w-24 rounded-full ring-4 ring-white sm:h-32 sm:w-32" src="@/assets/avatar.jpg" alt=""/>
          </div>
          <div class="mt-6 sm:flex-1 sm:min-w-0 sm:flex sm:items-center sm:justify-end sm:space-x-6 sm:pb-1">
            <div class="sm:hidden md:block mt-6 min-w-0 flex-1">
              <h1 class="text-2xl font-bold text-white truncate">
                {{ auth.name }}
              </h1>
              <p class="text-sm text-pink-700">{{ formatted_email }}</p>
            </div>
            <div class="mt-6 flex flex-col justify-stretch space-y-3 sm:flex-row sm:space-y-0 sm:space-x-4">
              <span class="relative z-0 inline-flex shadow-sm rounded-md">
                  <button type="button" 
                          class="       relative inline-flex items-center px-4 py-2 rounded-l-md border border-zinc-900 text-sm font-medium hover:bg-pink-900 hover:text-white" :class="contentNav == 0 ? 'bg-pink-700 text-white' : 'bg-white text-gray-700'" 
                          @click="contentNav = 0"
                  >Videos</button>
                  <button type="button" 
                          class="       relative inline-flex items-center px-4 py-2              border border-zinc-900 text-sm font-medium hover:bg-pink-900 hover:text-white" :class="contentNav == 1 ? 'bg-pink-700 text-white' : 'bg-white text-gray-700'" 
                          @click="contentNav = 1"
                  >Photo Sets</button>
                  <button type="button" 
                          class="       relative inline-flex items-center px-4 py-2 rounded-r-md border border-zinc-900 text-sm font-medium hover:bg-pink-900 hover:text-white" :class="contentNav == 2 ? 'bg-pink-700 text-white' : 'bg-white text-gray-700'" 
                          @click="contentNav = 2"
                  >Purchases</button>
              </span>
              <button type="button" @click="logout"
                      class="inline-flex justify-center px-4 py-2 border border-zinc-900 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-pink-700 hover:bg-pink-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pink-500">
                <UserIcon class="-ml-1 mr-2 h-5 w-5 text-white" aria-hidden="true"/>
                <span class="text-white">Logout</span>
              </button>
            </div>
          </div>
        </div>
        <div class="hidden sm:block md:hidden mt-6 min-w-0 flex-1">
          <h1 class="text-2xl font-bold text-gray-900 truncate">
            {{ auth.name }}
          </h1>
        </div>
      </div>
      <div>
        <members-videos   v-if="contentNav == 0"></members-videos>
        <members-images   v-if="contentNav == 1"></members-images>
        <purchased-videos v-if="contentNav == 2"></purchased-videos>
      </div>
    </div>
  </members-layout>
</template>
<script>
import MembersLayout from "@/layouts/BrochureLayout";
import {MailIcon, PhoneIcon, SearchIcon, VideoCameraIcon, UserIcon} from '@heroicons/vue/solid'
import {getStorage, saveStorage} from "@/saveStorage";
import MembersVideos from "@/reuseable/membersVideos";
import MembersImages from "@/reuseable/membersImages";
import PurchasedVideos from "@/reuseable/purchasedVideos";
export default {
  components: {
    MembersImages,
    MembersVideos,
    PurchasedVideos,
    MembersLayout,
    MailIcon, PhoneIcon, SearchIcon, VideoCameraIcon, UserIcon
  },
  
  computed: {
    sent_formatted() {
      if (auth.email) {
        if (auth.email.substring(auth.email.length - 7) == '@ccbill') {
          return '';
        }
        else {
          return auth.email;
        }
      }
    }
  },
  data() {
    return {
      auth: {},
      contentNav: 0
    }
  },
  methods: {
    logout() {
      saveStorage('auth', false)
      saveStorage('token', false)
      saveStorage('LoggedUser', false)
      saveStorage('cart', [])

      this.$router.push({name: 'homePage'})
    }
  },
  created() {
    this.auth = getStorage('auth')

    this.contentNav = this.auth && this.auth.is_member ? 0 : 2;
    // Check if 'target=purchases' is present in the query string
    const queryTarget = this.$route.query.target;
    if (queryTarget === 'purchases') {
      this.contentNav = 2; // Set 'Purchases' tab as active
    }
  }
}
</script>