<template>
  <BrochureLayout>
    <div class="bg-zinc-900">
      <page_header>
        <template v-slot:title>
          USC 2257 Statement
        </template>
        <template v-slot:tag>
        </template>
      </page_header>
      <div class="max-w-7xl mx-auto overflow-hidden sm:px-6 lg:px-8 mb-3 p-10">

        <p class="text-white mb-2">This web site, the photographic and video depictions and representations of all found therein, are produced in the United Kingdom and published via the United States. </p>

        <p class="text-white mb-2">All models appearing within this web site were at least 18 years of age at the time of production. Proof of age and consent is held on file.</p>

        <p class="text-white mb-2">The documentation pertaining to this can be located at the the manufacturer, owner or licensors place of business:</p>

        <p class="text-white mb-2">Steve Borderland (BorderlandBound.com)</p>

        <p class="text-white mb-2">Any and all queries can be directed to:</p>

        <p class="text-white mb-2"><a class="text-pink-700 hover:text-pink-900" href="mailto:enquiries@borderlandbound.com">enquiries@borderlandbound.com.</a></p>
      </div>
      <!-- Promo Pricing-->
      <membership_stats></membership_stats>
      <pricing-component></pricing-component>
    </div>
  </BrochureLayout>
</template>
<script>
import BrochureLayout from '../layouts/BrochureLayout'
import {StarIcon} from '@heroicons/vue/solid'
import page_header from "@/reuseable/page_header";
import pricingComponent from '@/reuseable/pricing_component.vue'
import membership_stats from "@/reuseable/membership_stats";
export default {
  components: {
    pricingComponent,
    BrochureLayout,
    StarIcon,
    page_header,
    membership_stats
  },
  data() {
    return {}
  },
  methods: {},
  created() {
  }

}
</script>
<style></style>