<template>
<form-layout>
    <div class="relative px-4 py-16 sm:px-6 sm:py-24 lg:py-32 lg:px-8">
        <div class="sm:mx-auto sm:w-full sm:max-w-md">
            <img
                class="mx-auto h-24 w-auto"
                src="@/assets/logo.png"
                alt="Workflow"
            />
            <p class="mt-2 text-center text-sm text-white">
                {{ " " }}
                Member Login
            </p>
        </div>
        <div class="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
            <div class="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
                <div class="space-y-6">
                    <!-- Username -->
                    <div>
                        <label for="username" class="block text-sm font-medium text-gray-700">
                            Username
                        </label>
                        <div class="mt-1">
                            <input
                                type="text"
                                v-model="formData.username"
                                name="username"
                                id="username"
                                class="
                                shadow-sm
                                focus:ring-indigo-500 focus:border-indigo-500
                                block
                                w-full
                                sm:text-sm
                                border-gray-300
                                rounded-md
                                "
                                placeholder="Username"
                            />
                            <span class="font-small text-indigo-800">{{ errors['username'] }}</span>
                        </div>
                    </div>

                    <!-- Password -->
                    <div>
                        <label for="password"
                            class="block text-sm font-medium text-gray-700">
                            Password
                        </label>
                        <div class="mt-1 mb-2">
                            <input
                                type="password"
                                v-model="formData.password"
                                name="password"
                                id="password"
                                class="
                                shadow-sm
                                focus:ring-indigo-500 focus:border-indigo-500
                                block
                                w-full
                                sm:text-sm
                                border-gray-300
                                rounded-md
                                "
                                placeholder="you@example.com"
                            />
                            <span class="font-small text-indigo-800">{{ errors['username'] }}</span>
                        </div>
                        <div>
                            <a class="text-sm font-medium text-pink-700 hover:text-pink-900" href="/member/forgot_password">Forgot password?</a>
                        </div>
                    </div>


                    <!-- Message -->
                    <div class="flex items-center justify-between">
                        <div class="flex items-center">
                            <div class="text-sm">
                                <span class="font-medium text-grey-700">
                                    Please do not share your account information<br><br>
                                    If you have any issues logging into your account please <a href="/contact" class="text-pink-700 hover:text-pink-900">Contact Us</a>
                                </span>
                            </div>
                        </div>
                    </div>

                    <!-- Submit Button -->
                    <div>
                        <button
                            type="submit"
                            @click="loginToAccount"
                            class="
                                w-full
                                flex
                                justify-center
                                py-2
                                px-4
                                border border-transparent
                                rounded-md
                                shadow-sm
                                text-sm
                                font-medium
                                text-white
                                bg-pink-700
                                hover:bg-pink-900
                                focus:outline-none
                                focus:ring-2
                                focus:ring-offset-2
                                focus:ring-indigo-500
                            "
                        >
                            Continue
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</form-layout>
</template>

<script>
import { useStore } from "vuex";
import alertError from '@/alerts/error.vue'
import axios from "axios";
import {saveStorage} from "@/saveStorage";
import formLayout from '@/layouts/formLayout'
export default {
  data() {
    return {
      api_url: process.env.VUE_APP_API_URL,
      formData: {
            username: '',
            password: ''
      },
      store: useStore(),
      errors: [],
    };
  },
  components: {
    alertError,
    formLayout
  },
  methods: {
    loginToAccount() {
      axios.post(this.api_url + '/api/login', this.formData).then(response => {
        this.error = false
        saveStorage('auth', response.data)
        saveStorage('token', response.data.token)
        saveStorage('LoggedUser', true)
        saveStorage('isAdmin', response.data.is_admin)
        saveStorage('username', response.data.name)

        this.$router.push({name: 'memberHome'})
      }).catch(error => {
        var error_msg     = '';
        var error_details = '';
        if (error.response.data.message) {
            error_msg = error.response.data.message;	
        }
        
        if (error.response.data.errors) {
            Object.keys(error.response.data.errors).forEach(key => {
                error_details = error_details + error.response.data.errors[key].toString() + "<br>";

                // load api errors into client errors
                this.errors[key] = error.response.data.errors[key].toString();
            });
        }

        this.$swal({
            title:              error_msg,
            html:               error_details,
            confirmButtonColor: '#be185d',
            confirmButtonText:  'Close',
            icon:               'error',
            background:         '#3f3f46',
        })
      });
    }
  }
};
</script>

<style>
</style>